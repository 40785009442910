import callingApi from '../../services/apiService';
import { apiUrl, getDomainUrl, prodEspanolDomain } from '../../constants/api-urls';
import { updateCurrentView, updatePlansPage, updateSkeleton, updateVFResumeFlow } from '../loop-qual-app/actions';
import { LQ_VIEWS, MethodType, Pages, Flowtype } from '../../constants/common';
import { showLoader, hideLoader } from '../common/loader/actions';
import { transform, FIVG_LQ_RESPONSE } from '../../utils/lq-response-tranformer';
import { AddressQualification as ActionTypes, FiveG, MovesQualification as MVActionTypes } from '../../constants/actionTypes';
import { cradleInvoke } from '../../services/lqService';
import { redirectToPlansPage, getCookie, setToStorage, redirectToMlp } from '../../utils/lqServiceUtil';
import { catchJSException, logMetrics } from '../../services/logService';
import { closeModalWindow, openModalWindow } from '../common/modal/action';
import {
  checkNetworkAvailabilityReTrigger,
  postQualificationUpdate,
  singlefromBulkQualUpdate,
  lqsTagTriggered,
  greyRemove,
} from '../address-qualification/actions';
import { getSeqType } from '../../config';
import { loopQualVzTaglqs } from '../../utils/unifiedVzTag';

const plansvisit = window?.vzwlqwidgetconfig?.sourceInfo === 'plansvisit';

export const checkMap5GAvailability = (
  mapData,
  customerStreetDetails,
  unitInfo,
  addressQualification,
  dispatch,
  cookies,
  loopQualApp,
  fiosDetails,
  moveDetails,
  bulkQualDetails,
) => {
  dispatch(showLoader());
  dispatch(updateCurrentView(LQ_VIEWS.BLANK_LOADER_VIEW));
  let data = null;
  let invAdr = false;
  let url = apiUrl().fiveGQualification;
  const isMovers = loopQualApp.isLoggedIn && loopQualApp.flowType === Flowtype.MOVERS;
  let address2Input = unitInfo
    ? unitInfo.customerUnitDetails && unitInfo.customerUnitDetails.ntasSublocation
      ? unitInfo.customerUnitDetails.ntasSublocation
      : unitInfo.unitInput
      ? unitInfo.unitInput
      : ''
    : '';
  if (address2Input) {
    address2Input = ["I don't live in a unit", 'I can’t find my unit'].includes(address2Input)
      ? ''
      : address2Input
          .replace(/[-_/\\^$*+?,.()|[\]{}]/g, ' ')
          .replace(/^\s+|\s+$/g, '')
          .replace(/\s+/g, ' ')
          .replace(/[^a-zA-Z0-9 ]/g, '');
  }
  if (
    isMovers &&
    moveDetails.moveProfileDetails &&
    moveDetails.moveProfileDetails.accountLevelInfo &&
    moveDetails.moveProfileDetails.accountLevelInfo[0] &&
    moveDetails.moveProfileDetails.accountLevelInfo[0].lineLevelInfo.length > 0
  ) {
    url = apiUrl().fiveGMVQualification;
  }
  const params = new URLSearchParams(window.location.search);
  let unitAddressID = '';
  let unitLocationID = '';
  if (unitInfo && unitInfo.customerUnitDetails) {
    if (unitInfo.customerUnitDetails.ntasAddrId) {
      unitAddressID = unitInfo.customerUnitDetails.ntasAddrId.toString();
    } else if (unitInfo.customerUnitDetails.location_id) {
      unitAddressID = unitInfo.customerUnitDetails.location_id.toString();
    }
    unitLocationID = unitInfo.customerUnitDetails.location_id;
  }
  const cVal = params.get('c') ? params.get('c') : '';
  const vendorOutletIdSession = window.sessionStorage.getItem('vendorOutletId');
  const vendorLocationCodeSession = window.sessionStorage.getItem('vendorLocationCode');
  const vendorId = window.sessionStorage.getItem('vendorid');
  const vendorOutletId = params.get('outletid')
    ? params.get('outletid')
    : fiosDetails && fiosDetails.vendorDetails && fiosDetails.vendorDetails.outletid
    ? fiosDetails.vendorDetails.outletid
    : vendorOutletIdSession || '';
  const vendorLocationCode = params.get('locationCode')
    ? params.get('locationCode')
    : fiosDetails && fiosDetails.vendorDetails && fiosDetails.vendorDetails.locationCode
    ? fiosDetails.vendorDetails.locationCode
    : vendorLocationCodeSession || '';
  const vendor_id =
    cVal || (fiosDetails && fiosDetails.vendorDetails && fiosDetails.vendorDetails.vendorId ? fiosDetails.vendorDetails.vendorId : '') || vendorId;
  let cmp = params.get('cmp') ? params.get('cmp') : params.get('CMP') ? params.get('CMP') : '';
  if (cmp === '' && fiosDetails && fiosDetails.vendorDetails && fiosDetails.vendorDetails.cmpid) {
    cmp = fiosDetails.vendorDetails.cmpid;
  }
  if (
    customerStreetDetails &&
    customerStreetDetails.state &&
    customerStreetDetails.zip &&
    customerStreetDetails.addressLine1 &&
    customerStreetDetails.city &&
    !customerStreetDetails.muni &&
    !customerStreetDetails.dispstr
  ) {
    invAdr = true;
  }
  if (isMovers) {
    data = {
      address1: invAdr ? customerStreetDetails.addressLine1 : customerStreetDetails ? customerStreetDetails.dispstr : '',
      city: invAdr ? customerStreetDetails.city : customerStreetDetails ? customerStreetDetails.muni : '',
      state: customerStreetDetails && customerStreetDetails.state,
      zipcode: customerStreetDetails && customerStreetDetails.zip,
      locusID: customerStreetDetails && customerStreetDetails.locusID,
      address2: address2Input,
      addressId: unitAddressID,
      location_id: unitLocationID,
      latitude: mapData && mapData.latitude,
      longitude: mapData && mapData.longitude,
      floorNumber: mapData && mapData.floorNumber,
      buildingId: mapData && mapData.buildingId,
      scEmail: addressQualification.scEmail,
      scMtn: addressQualification.formattedMtn,
      flowtype: 'LQ2.0',
      captchaChangeNeeded: true,
      loopQual: true,
      'g-recaptcha-response': loopQualApp.gCaptchaResponse,
      includeCband: true,
      cBandOnly: false,
      superBowlPromo: false,
      isLoadTest: !!(loopQualApp.runTimeKey && loopQualApp.runTimeKey.EnableNsaLT && loopQualApp.runTimeKey.EnableNsaLT === 'Y'),
      preOrder: true,
      isRevist: addressQualification.lqsTagTriggered,
      referrer: window.document.referrer,
      vendorId: vendor_id,
      cmp: cmp || '',
      outletId: vendorOutletId,
      locationCode: vendorLocationCode,
      movingLineInfo: moveDetails ? moveDetails.preferredMVService : '',
    };
  } else if (bulkQualDetails && bulkQualDetails.bulkProfileDetails) {
    const addrInfo = addressQualification?.lqResult?.addrInfo;
    data = {
      address1: addrInfo?.addressLine1,
      city: addrInfo?.city,
      state: addrInfo?.state,
      zipcode: addrInfo?.zip,
      locusID: bulkQualDetails.preferredbulkServiceDetail && bulkQualDetails.preferredbulkServiceDetail.subLocationId,
      address2: address2Input,
      addressId: unitAddressID,
      location_id: unitLocationID,
      latitude: mapData && mapData.latitude,
      longitude: mapData && mapData.longitude,
      floorNumber: mapData && mapData.floorNumber,
      buildingId: mapData && mapData.buildingId,
      scEmail: '',
      scMtn: '',
      flowtype: 'LQ2.0',
      captchaChangeNeeded: true,
      loopQual: true,
      'g-recaptcha-response': loopQualApp.gCaptchaResponse,
      includeCband: true,
      cBandOnly: false,
      superBowlPromo: false,
      isLoadTest: !!(loopQualApp.runTimeKey && loopQualApp.runTimeKey.EnableNsaLT && loopQualApp.runTimeKey.EnableNsaLT === 'Y'),
      preOrder: true,
      isRevist: addressQualification.lqsTagTriggered,
      referrer: window.document.referrer,
      vendorId: vendor_id,
      cmp: cmp || '',
      outletId: vendorOutletId,
      locationCode: vendorLocationCode,
      cbandPro: 'Y',
    };
  } else {
    data = {
      address1: invAdr ? customerStreetDetails.addressLine1 : customerStreetDetails ? customerStreetDetails.dispstr : '',
      city: invAdr ? customerStreetDetails.city : customerStreetDetails ? customerStreetDetails.muni : '',
      state: customerStreetDetails && customerStreetDetails.state,
      zipcode: customerStreetDetails && customerStreetDetails.zip,
      locusID: customerStreetDetails && customerStreetDetails.locusID,
      ntasAddrID: customerStreetDetails && customerStreetDetails.ntasAddrID ? customerStreetDetails.ntasAddrID : '',
      address2: address2Input,
      addressId: unitAddressID,
      location_id: unitLocationID,
      latitude: mapData && mapData.latitude,
      longitude: mapData && mapData.longitude,
      floorNumber: mapData && mapData.floorNumber,
      buildingId: mapData && mapData.buildingId,
      scEmail: addressQualification.scEmail,
      scMtn: addressQualification.formattedMtn,
      flowtype: 'LQ2.0',
      captchaChangeNeeded: true,
      loopQual: true,
      'g-recaptcha-response': loopQualApp.gCaptchaResponse,
      includeCband: true,
      cBandOnly: false,
      superBowlPromo: false,
      isLoadTest: !!(loopQualApp.runTimeKey && loopQualApp.runTimeKey.EnableNsaLT && loopQualApp.runTimeKey.EnableNsaLT === 'Y'),
      preOrder: true,
      isRevist: addressQualification.lqsTagTriggered,
      referrer: window.document.referrer,
      vendorId: vendor_id,
      cmp: cmp || '',
      outletId: vendorOutletId,
      locationCode: vendorLocationCode,
      cbandPro: 'Y',
    };
  }
  const location =
    window.location.search &&
    (window.location.search.indexOf('testQualification') > -1 ||
      window.location.search.indexOf('nsa=Y') > -1 ||
      window.location.search.indexOf('NSA=Y') > -1)
      ? 'Y'
      : null;
  let flowName = '';
  if (location && location === 'Y') {
    flowName = 'test|';
  }
  if (invAdr) {
    flowName = `INVADR|${flowName}`;
  }
  if (vendorLocationCode && vendorOutletId) {
    flowName = `Vendor|${vendor_id}|${flowName}`;
    flowName = `OutletID|${vendorOutletId}|${flowName}`;
    setToStorage('UCFlowName', flowName);
  }
  if (loopQualApp?.isComboMFJT) {
    flowName = `JT_MH|${flowName}`;
  }
  if (loopQualApp?.sourceInfo?.toUpperCase() === 'AALCOMBO') {
    flowName = `SEQ_MH_MAQ|${flowName}`;
  }
  if (loopQualApp?.isECComboMFJT) {
    flowName = `EC_JT_MH|${flowName}`;
  }
  const requiredflowType = { flowType: flowName };
  return new Promise((resolve, reject) => {
    callingApi(url, MethodType.POST, data, '', requiredflowType)
      .then((response) => {
        dispatch(hideLoader());
        const lqResponse = transform(response.data, FIVG_LQ_RESPONSE, cookies, loopQualApp, customerStreetDetails, '', invAdr, false, mapData);
        if (lqResponse) {
          dispatch({ type: ActionTypes.QUALIFICATION_DETAILS, value: lqResponse });
          postQualificationUpdate(lqResponse, loopQualApp, addressQualification, moveDetails, dispatch);
        } else {
          dispatch({ type: ActionTypes.QUALIFICATION_DETAILS, value: response.output });
        }
        if (
          lqResponse &&
          loopQualApp &&
          (loopQualApp.sourceInfo === 'digitalCombo' ||
            loopQualApp.sourceInfo === 'external' ||
            loopQualApp.homeLQ ||
            (loopQualApp.lq4 &&
              !loopQualApp.TWS &&
              !loopQualApp.isFccFlow &&
              !loopQualApp.isACPFlow &&
              !response.uberPinEligible &&
              loopQualApp.flowType !== Flowtype.storeLocater &&
              loopQualApp.flowType !== Flowtype.MOVERS &&
              loopQualApp.flowType !== Flowtype.retail &&
              !loopQualApp.isComboMFJT &&
              !loopQualApp.isECComboMFJT &&
              !loopQualApp.newConnection &&
              !lqResponse.uberPinEligible &&
              (lqResponse.fiveGQualified || lqResponse.qualifiedCBand || lqResponse.lteQualified || lqResponse.tgwQualified)))
        ) {
          const lqRes = { lqResult: lqResponse };
          if (loopQualApp.lq4) {
            closeModalWindow(dispatch);
            dispatch(updateSkeleton(true));
          }
          setToStorage('FWA_LQSPO', 'Y');
          lqsTagTriggered(dispatch, true);
          orderNowFiveGNsa(
            lqRes,
            loopQualApp,
            dispatch,
            false,
            moveDetails,
            false,
            '',
            '',
            fiosDetails,
            true,
            false,
            bulkQualDetails.preferredbulkServiceDetail,
            false,
            true,
          );
        } else {
          if (lqResponse && lqResponse.targetView) {
            dispatch(updateCurrentView(lqResponse.targetView));
          }
          if (plansvisit && (lqResponse.targetView === LQ_VIEWS.UN_QUALIFIED_VIEW || lqResponse.targetView === LQ_VIEWS.LBO_VIEW)) {
            dispatch(showLoader());
            setTimeout(() => {
              dispatch(hideLoader());
              redirectToMlp(lqResponse?.fiveGQualified, lqResponse?.qualifiedCBand, lqResponse?.lqResponse, response?.fiosQualified);
            }, 5000);
          } else if (lqResponse && loopQualApp && loopQualApp.isFccFlow) {
            if (lqResponse.fiosQualified) {
              redirectToPlansPage(Pages.inHomeFccBroadbandLabels);
            } else if (!lqResponse.uberPinEligible && (lqResponse.fiveGQualified || lqResponse.qualifiedCBand || lqResponse.lteQualified)) {
              return new Promise((solve) => {
                invokeUpdateCartNoRedirect(lqResponse, loopQualApp, dispatch)
                  .then((res) => {
                    logMetrics('invokeUpdateCartNoRedirect', JSON.stringify(res), 'Error');
                    redirectToPlansPage(Pages.fiveGNSAExpressCartFcc);
                    solve(response);
                  })
                  .catch((error) => {
                    logMetrics('invokeUpdateCartNoRedirect', error.message, 'Error');
                  });
              });
            }
          }
        }
        resolve(lqResponse);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const reloadMap = (dispatch, input) => {
  dispatch({ type: FiveG.RELOAD_MAP, value: input });
};

export const showMap = (dispatch, input) => {
  dispatch({ type: FiveG.SHOW_MAP, value: input });
};

export const mapCompletionStatus = (dispatch, input) => {
  dispatch({ type: FiveG.MAP_COMPLETED, value: input });
};
export const resetFivegDefault = (dispatch) => {
  dispatch({ type: FiveG.RESTOREFIVEG_DEFAULTS });
};
export const aptFlrInputCompleted = (dispatch, completionFlag) => {
  dispatch({ type: FiveG.APT_FLR_INPUT_COMPLETED, value: completionFlag });
};
const intendType = (response, isLoggedIn) => {
  let flowType = '';
  if (response) {
    if (response.lteQualified || response.qualified4GHome || response?.wifiBackupLteQualified) {
      flowType = 'LTE';
    } else if (
      response.fiveGQualified ||
      response.qualified ||
      response.qualifiedCBand ||
      response.tgwQualified ||
      response?.wifiBackupCbandQualified
    ) {
      flowType = '5G';
    }
  }
  return isLoggedIn ? `AAL_${flowType}` : `NSE_${flowType}`;
};
const bundleInstallType = (response) => {
  const bundleType = {
    installType: '',
    depletionType: '',
    transactionType: '',
  };
  const cbandPro = window.sessionStorage.getItem('cbandPro');
  const wifiBackupMLP = window?.vzwlqwidgetconfig?.source === 'WIFIBACKUP';
  const isWifiBackupQualified = response.wifiBackupCbandQualified || response.wifiBackupLteQualified;

  if (
    (wifiBackupMLP && isWifiBackupQualified) ||
    (!wifiBackupMLP &&
      !response.qualifiedCBand &&
      !response.fiveGQualified &&
      !response.lteQualified &&
      !response.fiosQualified &&
      !response.qualified &&
      isWifiBackupQualified)
  ) {
    if (response?.wifiBackupBundle && response?.wifiBackupBundle?.length) {
      bundleType.installType = response.wifiBackupBundle[0].bundleName;
    }
    bundleType.depletionType = 'F';
    bundleType.transactionType = 'WIFIBACKUP';
  } else if (response && response.qualifiedCBand && cbandPro && cbandPro === 'Y') {
    bundleType.installType = '5GHomeCBandSelfSetup';
    bundleType.depletionType = 'F';
  } else if (response && (response.fiveGQualified || response.qualified) && response.bundleDetails) {
    if (response.bundleDetails.length > 1) {
      for (let i = 0; i < response.bundleDetails.length; i++) {
        if (response.bundleDetails[i].type && response.bundleDetails[i].type.toLowerCase() === 's') {
          bundleType.depletionType = 'F';
          bundleType.installType = response.bundleDetails[i].bundleName;
        }
      }
    } else if (response.bundleDetails.length === 1) {
      if (response.bundleDetails[0].type && response.bundleDetails[0].type.toLowerCase() === 's') {
        bundleType.depletionType = 'F';
        bundleType.installType = response.bundleDetails[0].bundleName;
      } else {
        bundleType.installType = response.bundleDetails[0].bundleName;
        bundleType.depletionType = 'O';
      }
    } else if (response && response.qualifiedCBand) {
      bundleType.depletionType = 'F';
    }
  } else if (response && (response.lteQualified || response.qualified4GHome)) {
    bundleType.depletionType = 'F';
  } else if (response && response.qualifiedCBand) {
    bundleType.depletionType = 'F';
  } else if (response && response.tgwQualified && response.bundleDetails?.length > 0) {
    bundleType.depletionType = 'O';
    bundleType.installType = response.bundleDetails[0].bundleName;
  }

  return bundleType;
};
export const fetchEmeriosData = (
  emreiosFetchBodyFromExisting,
  addressQualification,
  dispatch,
  isMovers = '',
  loopQualApp = '',
  newflowType = '',
  bulkProfileDetails,
) => {
  // Emerios method
  let emreiosFetchUrl = apiUrl()?.emriosFetchUrl;
  const languageType = window.location.host.indexOf('espanol') > -1 ? 'S' : 'E';

  const redirectionUrl = loopQualApp?.isLoggedIn
    ? `https://${window.location.host}/sales/home/progressiveplan.html?ec=y&acp=y&intcmp=vf3-plansoverview-continue`
    : `https://${window.location.host}/sales/home/progressiveplan.html?acp=y&intcmp=vf3-plansoverview-continue`;
  const emeriosData = {
    registrationLanguage: languageType,
    businessLine: 'FWA',
    customerType: loopQualApp?.isLoggedIn ? 'E' : 'N',
    fiosSessionId: '',
    redirectUrl: redirectionUrl,
    firstName: addressQualification?.details?.firstName || '',
    middleName: addressQualification?.details?.middleName || '',
    lastName: addressQualification?.details?.lastName || '',
    DateOfBirth: addressQualification?.details?.DateOfBirth || '',
    addressLine1: addressQualification?.lqResult?.addrInfo?.addressLine1 || '',
    addressLine2: addressQualification?.lqResult?.addrInfo?.addressLine2 || '',
    city: addressQualification?.lqResult?.addrInfo?.city || '',
    state: addressQualification?.lqResult?.addrInfo?.state || '',
    zipCode: addressQualification?.lqResult?.addrInfo?.zip || '',
  };
  const isEmreiosFetchBodyFromExistingEmpty = emreiosFetchBodyFromExisting && Object.keys(emreiosFetchBodyFromExisting).length === 0;
  const emreiosFetchBody = isEmreiosFetchBodyFromExistingEmpty ? emeriosData : emreiosFetchBodyFromExisting;
  const lqresponse = addressQualification.lqResult;
  let flowName = '';
  if (lqresponse && lqresponse.qualifiedCBand) {
    flowName = '5G|CBD';
  } else if (lqresponse && (lqresponse.fiveGQualified || lqresponse.qualified)) {
    flowName = '5G|mmWv';
  } else if (lqresponse && (lqresponse.lteQualified || lqresponse.qualified4GHome)) {
    flowName = 'LTE|LTE';
  } else if (lqresponse && lqresponse.tgwQualified) {
    flowName = '5G|TGW';
  } else if (lqresponse && lqresponse?.wifiBackupCbandQualified) {
    flowName = '5G|CBD';
  } else if (lqresponse && lqresponse?.wifiBackupLteQualified) {
    flowName = 'LTE|LTE';
  }
  const vendorLocation =
    window.location.search &&
    window.location.search.indexOf('c') > -1 &&
    window.location.search.indexOf('outletId') > -1 &&
    (window.location.search.indexOf('nsa=y') > -1 || window.location.search.indexOf('NSA=Y') > -1)
      ? 'Y'
      : null;
  const location =
    window.location.search &&
    (window.location.search.indexOf('testQualification') > -1 ||
      window.location.search.indexOf('nsa=y') > -1 ||
      window.location.search.indexOf('NSA=Y') > -1)
      ? 'Y'
      : null;
  if (loopQualApp.isLoggedIn) {
    flowName = `AAL_${flowName}`;
  } else {
    flowName = `NSE_${flowName}`;
  }
  if (location && location === 'Y') {
    flowName = `test|${flowName}`;
  }
  if (lqresponse && lqresponse.preOrderInServiceDate) {
    flowName = `PREORDER|${flowName}`;
  }
  if (vendorLocation && vendorLocation === 'Y') {
    flowName = `Vendor|${flowName}`;
  }
  let requiredflowType = { flowType: flowName };
  if (loopQualApp?.isACPFlow) {
    if (loopQualApp?.isVfEc) {
      requiredflowType = { flowType: `FEA_5G|ACP|AAL` };
    } else {
      requiredflowType = { flowType: `ACP|${flowName}` };
    }
  }

  if (window.location.host.includes('awssit.ebiz.verizon.com')) {
    emreiosFetchUrl = 'https://wwwawssit.ebiz.verizon.com/inhome/fetchEmeriosInfo';
  } else if (window.location.host.includes('nte1aws.ebiz.verizon.com')) {
    emreiosFetchUrl = 'https://wwwnte1aws.ebiz.verizon.com/inhome/fetchEmeriosInfo';
  } else if (window.location.host.includes('nte3aws.ebiz.verizon.com')) {
    emreiosFetchUrl = 'https://wwwnte3aws.ebiz.verizon.com/inhome/fetchEmeriosInfo';
  } else if (window.location.host.includes('98.verizon.com')) {
    emreiosFetchUrl = 'https://www98.verizon.com/inhome/fetchEmeriosInfo';
  } else if (window.location.host.includes('localhost') || (window.location.search && window.location.search.indexOf('testbau=y') > -1)) {
    emreiosFetchUrl = 'https://www.verizon.com/inhome/fetchEmeriosInfo';
  }
  // const acpFlowType = `${requiredflowType}|ACP`;
  const redirectionPlansPage = `https://${window.location.host}/sales/home/progressiveplan.html`;
  if (
    (Object.keys(emreiosFetchBody).length !== 0 &&
      emreiosFetchBody.fiosSessionId !== undefined &&
      emreiosFetchBody.fiosSessionId !== null &&
      emreiosFetchBody.addressLine1 !== '') ||
    newflowType === 'FEA'
  ) {
    if (
      emreiosFetchBody.customerType === 'N' ||
      (emreiosFetchBody.customerType === 'E' && emreiosFetchBody.lastName !== '' && emreiosFetchBody.firstName !== '') ||
      (emreiosFetchBody.customerType === 'E' && newflowType === 'FEA')
    ) {
      callingApi(emreiosFetchUrl, MethodType.POST, emreiosFetchBody, 'application/json', requiredflowType)
        .then((response) => {
          const fetchedUrl = response?.data?.data?.redirectUrl;
          if (fetchedUrl === undefined) {
            let redirectUrl = isMovers && loopQualApp?.isLoggedInUserInfo ? Pages.InHomeGetMoverDetail : Pages.InHomeBuildProduct;
            if (window.location.search && window.location.search.indexOf('skip5g=y&movestart=y') > -1) {
              redirectUrl = '/inhome/movestart?lq2=y';
            }
            if (window.location.search && window.location.search.indexOf('skip5g=y') > -1 && window.location.search.indexOf('acpTestFlow=Y') > -1) {
              redirectUrl += `&acpTestFlow=y`;
            }
            if (emreiosFetchBody.businessLine === 'FWA') {
              redirectUrl = redirectionPlansPage;
            }
            redirectToPlansPage(redirectUrl);
          } else if (
            emreiosFetchBody.customerType === 'N' ||
            (emreiosFetchBody.customerType === 'E' && !addressQualification?.isAcpPresent && !addressQualification?.isVfpPresent) ||
            (bulkProfileDetails?.acpInfo?.isVfExist &&
              bulkProfileDetails?.billingAddress?.state === 'NY' &&
              (!bulkProfileDetails?.acpInfo?.isVfNyExist || bulkProfileDetails?.acpInfo?.isVfNyExist === 'false'))
          ) {
            redirectToPlansPage(fetchedUrl);
          } else if (emreiosFetchBody.customerType === 'N') {
            redirectToPlansPage(Pages.InHomeBuildProduct);
          } else {
            redirectToPlansPage(Pages.inHomeChangeSignin);
          }
        })
        .catch((error) => {
          console.log(error);
          logMetrics('FetchEmeriosError', error.message, 'Error');
          if (emreiosFetchBody.businessLine === 'FWA') {
            redirectToPlansPage(redirectionPlansPage);
          } else if (emreiosFetchBody.customerType === 'N') {
            redirectToPlansPage(Pages.InHomeBuildProduct);
          } else {
            redirectToPlansPage(Pages.inHomeChangeSignin);
          }
        });
    } else if (emreiosFetchBody.customerType === 'E' && emreiosFetchBody.lastName === '') {
      logMetrics('FetchEmeriosError', 'LastNameMissing', 'Error');
      redirectToPlansPage(Pages.inHomeChangeSignin);
    } else if (emreiosFetchBody.customerType === 'E' && emreiosFetchBody.firstName === '') {
      logMetrics('FetchEmeriosError', 'FirstNameMissing', 'Error');
      redirectToPlansPage(Pages.inHomeChangeSignin);
    }
  } else if (Object.keys(emreiosFetchBody).length !== 0 && emreiosFetchBody.addressLine1 === '') {
    logMetrics('FetchEmeriosError', 'AddressMissing', 'Error');
    if (emreiosFetchBody.businessLine === 'FWA') {
      redirectToPlansPage(redirectionPlansPage);
    } else {
      redirectToPlansPage(Pages.InHomeBuildProduct);
    }
  } else {
    logMetrics('FetchEmeriosError', 'VisitIdMissing', 'Error');
    if (emreiosFetchBody.businessLine === 'FWA' || lqresponse.fiveGQualified || lqresponse.qualifiedCBand || lqresponse.lteQualified) {
      redirectToPlansPage(redirectionPlansPage);
    } else {
      redirectToPlansPage(Pages.InHomeBuildProduct);
    }
  }
};

export const orderNowFiveGNsa = (
  addressQualification,
  loopQualApp,
  dispatch,
  fromRevisit = false,
  moveDetails,
  begintoMove = false,
  customerStreetDetails,
  unitLookupDetails,
  fiosDetails,
  isReTrigger = false,
  isfromBulk = false,
  preferredbulkServiceDetail,
  moversReTrigger = false,
  supressLQS = false,
) => {
  const params = new URLSearchParams(window.location.search);
  const lqresponse = addressQualification.lqResult;
  const roleType = getCookie('role');
  const vendorId = window.sessionStorage.getItem('vendorid');
  const roleSession = window.sessionStorage.getItem('vzwRole');
  const vendorOutletIdSession = window.sessionStorage.getItem('vendorOutletId');
  const vendorLocationCodeSession = window.sessionStorage.getItem('vendorLocationCode');
  const cVal = params.get('c') ? params.get('c') : '';
  const vendorOutletId = params.get('outletid')
    ? params.get('outletid')
    : fiosDetails && fiosDetails.vendorDetails && fiosDetails.vendorDetails.outletid
    ? fiosDetails.vendorDetails.outletid
    : vendorOutletIdSession || '';
  const vendorLocationCode = params.get('locationCode')
    ? params.get('locationCode')
    : fiosDetails && fiosDetails.vendorDetails && fiosDetails.vendorDetails.locationCode
    ? fiosDetails.vendorDetails.locationCode
    : vendorLocationCodeSession || '';
  const vendor_id =
    cVal || (fiosDetails && fiosDetails.vendorDetails && fiosDetails.vendorDetails.vendorId ? fiosDetails.vendorDetails.vendorId : '') || vendorId;
  let isMoveOrder = false;
  const moverFlow =
    begintoMove &&
    moveDetails.moveProfileDetails &&
    moveDetails.moveProfileDetails.accountLevelInfo &&
    moveDetails.moveProfileDetails.accountLevelInfo[0] &&
    moveDetails.moveProfileDetails.accountLevelInfo[0].lineLevelInfo.length > 0;
  try {
    dispatch(showLoader());
    const LQCradleTriggered = !!window.sessionStorage.getItem('LQCradleTriggered');
    if (!LQCradleTriggered) {
      cradleInvoke();
    }
    if (loopQualApp.isLoggedIn) {
      coverageLead();
    }
    window.sessionStorage.setItem('fromLq', 'Y');
    let redirectUrl = '';
    if (
      (roleType && (roleType.toLowerCase() === 'mobilesecure' || roleType.toLowerCase() === 'accountmember')) ||
      (roleSession && (roleSession.toLowerCase() === 'mobilesecure' || roleSession.toLowerCase() === 'accountmember'))
    ) {
      dispatch(hideLoader());
      logMetrics('NotOwnerPriorUC', roleType, 'Info');
      redirectUrl = prodEspanolDomain() ? Pages.NotOwnerurl : getDomainUrl() + Pages.NotOwnerurl;
      dispatch(updatePlansPage(redirectUrl));
      redirectToPlansPage(redirectUrl);
    } else {
      const bundleInfo = bundleInstallType(lqresponse);
      const intendInfo = intendType(lqresponse, loopQualApp.isLoggedIn);
      let url = apiUrl().updateCart;
      let flowName = '';
      if (lqresponse && lqresponse.qualifiedCBand) {
        flowName = '5G|CBD';
      } else if (lqresponse && (lqresponse.fiveGQualified || lqresponse.qualified)) {
        flowName = '5G|mmWv';
      } else if (lqresponse && (lqresponse.lteQualified || lqresponse.qualified4GHome)) {
        flowName = 'LTE|LTE';
      } else if (lqresponse && lqresponse.tgwQualified) {
        flowName = '5G|TGW';
      } else if (lqresponse && lqresponse?.wifiBackupCbandQualified) {
        flowName = '5G|CBD';
      } else if (lqresponse && lqresponse?.wifiBackupLteQualified) {
        flowName = 'LTE|LTE';
      }
      const vendorLocation =
        window.location.search &&
        window.location.search.indexOf('c') > -1 &&
        window.location.search.indexOf('outletId') > -1 &&
        (window.location.search.indexOf('nsa=y') > -1 || window.location.search.indexOf('NSA=Y') > -1)
          ? 'Y'
          : null;
      const location =
        window.location.search &&
        (window.location.search.indexOf('testQualification') > -1 ||
          window.location.search.indexOf('nsa=y') > -1 ||
          window.location.search.indexOf('NSA=Y') > -1)
          ? 'Y'
          : null;
      if (loopQualApp.isLoggedIn) {
        flowName = `AAL_${flowName}`;
      } else {
        flowName = `NSE_${flowName}`;
      }
      if (location && location === 'Y') {
        flowName = `test|${flowName}`;
      }
      if (lqresponse && lqresponse.preOrderInServiceDate) {
        flowName = `PREORDER|${flowName}`;
      }
      if (vendorLocation && vendorLocation === 'Y') {
        flowName = `Vendor|${flowName}`;
      }
      if (isReTrigger) {
        flowName = `ReTriggerWhileError|${flowName}`;
        setToStorage('UCFlowName', 'ReTriggerWhileError');
      }
      if (lqresponse && lqresponse.invAdr) {
        flowName = `UCInvAdr|${flowName}`;
        setToStorage('UCFlowName', 'UCInvAdr');
      }
      if (vendorLocationCode && vendorOutletId) {
        flowName = `Vendor|${vendor_id}|${flowName}`;
        flowName = `OutletID|${vendorOutletId}|${flowName}`;
        setToStorage('UCFlowName', flowName);
      }
      const isParser = window.sessionStorage.getItem('SNGLPARSER');
      if (isParser === 'Y') {
        flowName = `SNGLPARSER|${flowName}`;
        setToStorage('UCFlowName', 'SNGLPARSER');
      }
      if (loopQualApp?.isComboMFJT) {
        flowName = `JT_MH|${flowName}`;
        setToStorage('UCFlowName', flowName);
      }
      if (loopQualApp?.sourceInfo?.toUpperCase() === 'AALCOMBO') {
        flowName = `SEQ_MH_MAQ|${flowName}`;
      }
      if (loopQualApp?.isECComboMFJT) {
        flowName = `EC_JT_MH|${flowName}`;
      }
      let data = null;

      if (moverFlow || moversReTrigger) {
        const network = window.sessionStorage.getItem('moversNetworkBandwidth');
        const flowNameSplit = flowName?.split('|');
        const value = loopQualApp.isMvph2 ? `${flowNameSplit?.[0]}|${network}` : flowName;
        flowName = `MOV|${value}`;
        url = apiUrl().updateMVCart;
        isMoveOrder = true;
        data = {
          cartInfo: {
            clientAppName: 'VZW-DOTCOM',
            cartId: '',
            caseId: '',
            accountNumber: '',
            cartCreator: '',
            processingMTN: '',
            callReason: 'FiveGMovers',
            processStep: 'MoveNow',
            processAction: 'initiate',
            intendType: 'MOV_5G',
            bundleInstallType: bundleInfo.installType,
            enableResume: 'true',
            planChangeRequired: lqresponse?.planChangeRequired,
          },
          midnight: loopQualApp && loopQualApp.midnight ? 'Y' : 'N',
          data: {
            lines: [
              {
                depletionType: bundleInfo.depletionType,
              },
            ],
          },
        };
      } else if (loopQualApp.isECComboMFJT && isfromBulk) {
        data = {
          cartInfo: {
            clientAppName: 'VZW-DOTCOM',
            cartId: '',
            caseId: '',
            accountNumber: '',
            cartCreator: '',
            processingMTN: '',
            processStep: 'OrderNow',
            processAction: 'DeviceAndPlan',
            intendType: intendInfo,
            bundleInstallType: bundleInfo.installType,
            enableResume: 'true',
            jointCombo: 'Y',
            addressRecordIdentifier: preferredbulkServiceDetail.recordIdentifier,
            addressInfo: {
              addressLine1: customerStreetDetails && customerStreetDetails.addressLine1,
              addressLine2: customerStreetDetails && customerStreetDetails.addressLine2 ? customerStreetDetails.addressLine2 : '',
              city: customerStreetDetails && customerStreetDetails.muni,
              state: customerStreetDetails && customerStreetDetails.state,
              zipCode: customerStreetDetails && customerStreetDetails.zip,
            },
          },
          midnight: loopQualApp && loopQualApp.midnight ? 'Y' : 'N',
          data: {
            lines: [
              {
                depletionType: bundleInfo.depletionType,
              },
            ],
          },
        };
        flowName = `bulkQualIndicator|${flowName}`;
        setToStorage('UCFlowName', flowName);
        setToStorage('bulkQualIndicator', 'Y');
      } else if (isfromBulk) {
        data = {
          cartInfo: {
            clientAppName: 'VZW-DOTCOM',
            cartId: '',
            caseId: '',
            accountNumber: '',
            cartCreator: '',
            processingMTN: '',
            processStep: 'OrderNow',
            processAction: 'DeviceAndPlan',
            intendType: intendInfo,
            bundleInstallType: bundleInfo.installType,
            enableResume: 'true',
            addressRecordIdentifier: preferredbulkServiceDetail.recordIdentifier,
            addressInfo: {
              addressLine1: customerStreetDetails && customerStreetDetails.addressLine1,
              addressLine2: customerStreetDetails && customerStreetDetails.addressLine2 ? customerStreetDetails.addressLine2 : '',
              city: customerStreetDetails && customerStreetDetails.muni,
              state: customerStreetDetails && customerStreetDetails.state,
              zipCode: customerStreetDetails && customerStreetDetails.zip,
            },
            transactionType: bundleInfo.transactionType,
          },
          midnight: loopQualApp && loopQualApp.midnight ? 'Y' : 'N',
          data: {
            lines: [
              {
                transactionType: bundleInfo.transactionType,
                depletionType: bundleInfo.depletionType,
              },
            ],
          },
        };
        flowName = `bulkQualIndicator|${flowName}`;
        setToStorage('UCFlowName', flowName);
        setToStorage('bulkQualIndicator', 'Y');
      } else if (loopQualApp.sourceInfo === 'digitalCombo') {
        data = {
          cartInfo: {
            clientAppName: 'VZW-DOTCOM',
            cartId: '',
            caseId: '',
            accountNumber: '',
            cartCreator: '',
            processingMTN: '',
            processStep: 'OrderNow',
            processAction: 'DeviceAndPlan',
            intendType: intendInfo,
            isSequentialComboOrder: getSeqType() === 'EC_MOC_FWA' ? 'false' : 'true',
            bundleInstallType: bundleInfo.installType,
            enableResume: loopQualApp.isLoggedIn ? 'true' : 'false',
          },
          midnight: loopQualApp && loopQualApp.midnight ? 'Y' : 'N',
          data: {
            lines: [
              {
                depletionType: bundleInfo.depletionType,
              },
            ],
          },
        };
      } else if (loopQualApp.isComboMFJT && !loopQualApp.isLoggedIn && !loopQualApp.isLoggedInUserInfo) {
        data = {
          cartInfo: {
            clientAppName: 'VZW-DOTCOM',
            cartId: '',
            caseId: '',
            accountNumber: '',
            cartCreator: '',
            processingMTN: '',
            processStep: 'OrderNow',
            processAction: 'DeviceAndPlan',
            intendType: intendInfo,
            bundleInstallType: bundleInfo.installType,
            enableResume: loopQualApp.isLoggedIn ? 'true' : 'false',
            jointCombo: 'Y',
          },
          midnight: loopQualApp && loopQualApp.midnight ? 'Y' : 'N',
          data: {
            lines: [
              {
                depletionType: bundleInfo.depletionType,
              },
            ],
          },
        };
      } else if (loopQualApp.isECComboMFJT && (loopQualApp.isLoggedIn || loopQualApp.isLoggedInUserInfo)) {
        data = {
          cartInfo: {
            clientAppName: 'VZW-DOTCOM',
            cartId: '',
            caseId: '',
            accountNumber: '',
            cartCreator: '',
            processingMTN: '',
            processStep: 'OrderNow',
            processAction: 'DeviceAndPlan',
            intendType: intendInfo,
            bundleInstallType: bundleInfo.installType,
            enableResume: loopQualApp.isLoggedIn ? 'true' : 'false',
            jointCombo: 'Y',
          },
          midnight: loopQualApp && loopQualApp.midnight ? 'Y' : 'N',
          data: {
            lines: [
              {
                depletionType: bundleInfo.depletionType,
              },
            ],
          },
        };
      } else {
        data = {
          cartInfo: {
            clientAppName: 'VZW-DOTCOM',
            cartId: '',
            caseId: '',
            accountNumber: '',
            cartCreator: '',
            processingMTN: '',
            processStep: 'OrderNow',
            processAction: 'DeviceAndPlan',
            intendType: intendInfo,
            bundleInstallType: bundleInfo.installType,
            enableResume: loopQualApp.isLoggedIn ? 'true' : 'false',
            transactionType: bundleInfo.transactionType,
          },
          data: {
            lines: [
              {
                transactionType: bundleInfo.transactionType,
                depletionType: bundleInfo.depletionType,
              },
            ],
          },
          midnight: loopQualApp && loopQualApp.midnight ? 'Y' : 'N',
        };
      }
      if (window.location.host.includes('awssit.ebiz.verizon.com')) {
        url = 'https://wwwawssit.ebiz.verizon.com/inhome/updateCartFWA';
      } else if (window.location.host.includes('nte1aws.ebiz.verizon.com')) {
        url = 'https://wwwnte1aws.ebiz.verizon.com/inhome/updateCartFWA';
      } else if (window.location.host.includes('nte3aws.ebiz.verizon.com')) {
        url = 'https://wwwnte3aws.ebiz.verizon.com/inhome/updateCartFWA';
      } else if (window.location.host.includes('98.verizon.com')) {
        url = 'https://www98.verizon.com/inhome/updateCartFWA';
      }
      const requiredflowType = { flowType: flowName };
      return new Promise((resolve, reject) => {
        callingApi(url, MethodType.POST, data, 'application/json', requiredflowType)
          .then((response) => {
            logMetrics('UpdateCartResponse', JSON.stringify(response), 'Info');
            let pastBalance = '';
            let pastBalAmount = '';
            let orderRestriction = false;
            let line = '';
            dispatch(hideLoader());
            setToStorage('cartResponse', JSON.stringify(response));
            if (response && response.data && response.data.serviceBody && response.data.serviceBody.serviceResponse) {
              if (response.data.serviceBody.serviceResponse.context) {
                setToStorage('caseid', response.data.serviceBody.serviceResponse.context.caseId);
                if (response.data.serviceBody.serviceResponse.context.cartInfo) {
                  setToStorage('cartid', response.data.serviceBody.serviceResponse.context.cartInfo.cartId);
                  if (response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions) {
                    orderRestriction = response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.isPendingMoveOrder === 'true';
                  }
                }
                if (response.data.serviceBody.serviceResponse.context.cartInfo.cartExist) {
                  setToStorage('cartExist', response.data.serviceBody.serviceResponse.context.cartInfo.cartExist);
                  const cookieResponse = response.data.serviceBody.serviceResponse.context.cartInfo.cartExist;
                  const val = `cartExist=${cookieResponse};path=/`;
                  document.cookie = val;
                }
              }
              if (response.data.serviceBody.serviceResponse.context.contextInfo) {
                if (response.data.serviceBody.serviceResponse.context.contextInfo.pastDueBalance) {
                  pastBalance = response.data.serviceBody.serviceResponse.context.contextInfo.pastDueBalance;
                  if (response.data.serviceBody.serviceResponse.context.contextInfo.pastDueBalanceAmount) {
                    pastBalAmount = response.data.serviceBody.serviceResponse.context.contextInfo.pastDueBalanceAmount;
                  }
                  if (
                    response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions &&
                    response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility &&
                    response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility.length > 0
                  ) {
                    if (
                      response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility[0]
                        .reasonAttributes &&
                      response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility[0].reasonAttributes
                        .arPastDueBalanceAmount
                    ) {
                      pastBalAmount =
                        response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility[0].reasonAttributes
                          .arPastDueBalanceAmount;
                    }
                  }
                }
              }
              if (pastBalance === 'true') {
                setToStorage('pastDueBalAmount', pastBalAmount);
                redirectUrl = prodEspanolDomain() ? Pages.ACCFailurl : getDomainUrl() + Pages.ACCFailurl;
                dispatch(updatePlansPage(redirectUrl));
                redirectToPlansPage(redirectUrl);
                resolve(response);
                return;
              }
              if (isMoveOrder) {
                if (
                  isMoveOrder &&
                  response.data.serviceBody.serviceResponse.context &&
                  response.data.serviceBody.serviceResponse.context.contextInfo &&
                  response.data.serviceBody.serviceResponse.context.contextInfo.availablePaths &&
                  response.data.serviceBody.serviceResponse.context.contextInfo.availablePaths.length > 0
                ) {
                  orderRestriction = !!(
                    response.data.serviceBody.serviceResponse.context.contextInfo.availablePaths[0] &&
                    response.data.serviceBody.serviceResponse.context.contextInfo.availablePaths[0].path &&
                    (response.data.serviceBody.serviceResponse.context.contextInfo.availablePaths[0].path === 'PendingMoveOrder' ||
                      response.data.serviceBody.serviceResponse.context.contextInfo.availablePaths[0].path === 'PendingOrder')
                  );
                } else if (
                  response?.data?.serviceBody?.serviceResponse?.context?.cartInfo?.orderRestrictions?.accountLevelChangeEligibility?.[0]
                    ?.inEligibilityReasonCode === 'PENDING_ORDER'
                ) {
                  orderRestriction = true;
                }
                if (orderRestriction) {
                  setToStorage('isMovers', 'true');
                  document.cookie = 'isMovers=true;path=/';
                  dispatch({ type: MVActionTypes.IS_PENDING_MOVE_SERVICE, value: true });
                  dispatch(updateCurrentView(LQ_VIEWS.MULTIPLE_SERVICES_QUALIFIED_MV_VIEW));
                  resolve(response);
                  return;
                }
              }
            }
            if (loopQualApp.isLoggedIn) {
              if (response && response.data && response.data.serviceBody) {
                if (response.data.serviceBody.serviceResponse.context.cartInfo) {
                  if (response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions) {
                    if (
                      response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions &&
                      response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility &&
                      response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility[0]
                    ) {
                      if (
                        loopQualApp.isECComboMFJT &&
                        response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility[0]
                          ?.inEligibilityReasonCode === 'PENDING_ORDER'
                      ) {
                        dispatch({ type: MVActionTypes.IS_PENDING_MOVE_SERVICE, value: true });
                        dispatch(updateCurrentView(LQ_VIEWS.MULTIPLE_SERVICES_QUALIFIED_MV_VIEW));
                        resolve(response);
                        return;
                      }
                      if (response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility[0].reasonCode) {
                        const { reasonCode } =
                          response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility[0];
                        if (reasonCode === 'CASHONLY_IND') {
                          redirectUrl = prodEspanolDomain() ? Pages.ONReasonCodeUrl : getDomainUrl() + Pages.ONReasonCodeUrl;
                          dispatch(updatePlansPage(redirectUrl));
                          redirectToPlansPage(redirectUrl);
                          resolve(response);
                          return;
                        }
                        if (reasonCode === 'FRAUD_INDICATOR_X') {
                          redirectUrl = prodEspanolDomain() ? Pages.ONFraudOrderUrl : getDomainUrl() + Pages.ONFraudOrderUrl;
                          dispatch(updatePlansPage(redirectUrl));
                          redirectToPlansPage(redirectUrl);
                          resolve(response);
                          return;
                        }
                        if (reasonCode === 'FRAUD_INDICATOR_Y') {
                          redirectUrl = prodEspanolDomain() ? Pages.ONFraudOrderYUrl : getDomainUrl() + Pages.ONFraudOrderYUrl;
                          dispatch(updatePlansPage(redirectUrl));
                          redirectToPlansPage(redirectUrl);
                          resolve(response);
                          return;
                        }
                        if (reasonCode === 'COLLECTIONS_IND') {
                          redirectUrl = prodEspanolDomain() ? Pages.collections_INDurl : getDomainUrl() + Pages.collections_INDurl;
                          dispatch(updatePlansPage(redirectUrl));
                          redirectToPlansPage(redirectUrl);
                          resolve(response);
                          return;
                        }
                      }
                    }
                  }
                }
              }
            }
            if (response && response.data && response.data.serviceBody) {
              if (isfromBulk) {
                window.sessionStorage.setItem('bulkPlanVisited', 'Y'); // Session Added for Bulk Revisit Scenario
              }
              if (response.data.serviceBody.serviceResponse?.context?.customerInfo) {
                const { existingCase, vfExistInCart } = response.data.serviceBody.serviceResponse.context.customerInfo;
                const newConnection = window.sessionStorage.getItem('couponToken') ? window.sessionStorage.getItem('couponToken') : null;
                if (existingCase && existingCase === 'true') {
                  if (supressLQS) {
                    window.sessionStorage.removeItem('FWA_LQSPO');
                    loopQualVzTaglqs('lq result - ', 'success', lqresponse.lteQualified || lqresponse.qualified4GHome ? 'lte' : '5g', null, true);
                  }
                  setToStorage('ExistingCase', existingCase);
                  if (
                    !plansvisit &&
                    !loopQualApp.isLoggedIn &&
                    loopQualApp.sourceInfo !== 'digitalCombo' &&
                    loopQualApp.sourceInfo !== 'external' &&
                    loopQualApp.sourceInfo !== 'EC_digitalComboMFJT' &&
                    !loopQualApp.isACPFlow &&
                    !newConnection &&
                    !loopQualApp.isFccFlow &&
                    !loopQualApp.isComboMFJT
                  ) {
                    if (fromRevisit) {
                      openModalWindow('LQAPP', dispatch);
                    } else if (vfExistInCart === true || loopQualApp.isVFResumeFlow) {
                      if (loopQualApp.lq4) {
                        openModalWindow('LQAPP', dispatch);
                        dispatch(updateSkeleton(false));
                      }
                      setToStorage('requiredflowType', requiredflowType.flowType);
                      dispatch(updateVFResumeFlow(true));
                      dispatch(updateCurrentView(LQ_VIEWS.RESUME_SMART_CART_VIEW));
                      return;
                    }
                    if (loopQualApp.lq4) {
                      openModalWindow('LQAPP', dispatch);
                      dispatch(updateSkeleton(false));
                    }
                    dispatch(updateCurrentView(LQ_VIEWS.RESUME_SMART_CART_VIEW));
                  } else {
                    setToStorage('ONResponse', response);
                    let nextStep = '';
                    if (response.data.serviceBody.serviceResponse.context.contextInfo?.processStep) {
                      nextStep = response.data.serviceBody.serviceResponse.context.contextInfo.processStep;
                    }
                    if (isMoveOrder) {
                      setToStorage('isMovers', 'true');
                      document.cookie = 'isMovers=true;path=/';
                      if (nextStep === 'PendingMoveOrder') {
                        dispatch(updateCurrentView(LQ_VIEWS.MULTIPLE_SERVICES_QUALIFIED_MV_VIEW));
                        dispatch({ type: MVActionTypes.IS_PENDING_MOVE_SERVICE, value: true });
                        resolve(response);
                        return;
                      }
                      redirectUrl = Pages.cbandMVurl;
                    } else if (
                      plansvisit ||
                      (loopQualApp &&
                        loopQualApp.sourceInfo &&
                        (loopQualApp.sourceInfo === 'digitalCombo' ||
                          loopQualApp.sourceInfo === 'external' ||
                          loopQualApp.sourceInfo !== 'EC_digitalComboMFJT') &&
                        !loopQualApp.isLoggedIn)
                    ) {
                      redirectUrl = Pages.fiveGNSAExpressCart;
                    } else if (loopQualApp && loopQualApp.isFccFlow) {
                      redirectUrl = Pages.fiveGNSAExpressCartFcc;
                    } else if (loopQualApp && loopQualApp.isECComboMFJT && loopQualApp.isLoggedIn) {
                      window.sessionStorage.setItem('isECJointTransaction', 'true');
                      if (
                        response.data.serviceBody.serviceResponse.context.cartInfo.lines &&
                        response.data.serviceBody.serviceResponse.context.cartInfo.lines.length > 0
                      ) {
                        line =
                          response.data.serviceBody.serviceResponse.context.cartInfo.lines[0] &&
                          response.data.serviceBody.serviceResponse.context.cartInfo.lines[0].mtn
                            ? response.data.serviceBody.serviceResponse.context.cartInfo.lines[0].mtn
                            : '';
                        if (line) {
                          redirectUrl = `${Pages.Progressive}?line=${line}`;
                          redirectToPlansPage(redirectUrl);
                          resolve(response);
                          return;
                        }
                      }
                    } else {
                      redirectUrl = processStepRedirection(nextStep, loopQualApp);
                    }
                    if (window.sessionStorage.getItem('couponToken')) {
                      redirectUrl = `${redirectUrl}?PN=${window.sessionStorage.getItem('couponToken')}`;
                    }
                    dispatch(updatePlansPage(redirectUrl));
                    redirectToPlansPage(redirectUrl);
                    resolve(response);
                    return;
                  }
                } else {
                  setToStorage('ONResponse', response);
                  if (isMoveOrder) {
                    setToStorage('isMovers', 'true');
                    document.cookie = 'isMovers=true;path=/';
                    redirectUrl = Pages.cbandMVurl;
                  } else {
                    redirectUrl = prodEspanolDomain()
                      ? loopQualApp.midnight
                        ? Pages.Progressive
                        : Pages.cbandurl
                      : getDomainUrl() + (loopQualApp.midnight ? Pages.Progressive : Pages.cbandurl);
                  }
                  if (loopQualApp && loopQualApp.isComboMFJT && !loopQualApp.isLoggedIn && !loopQualApp.isLoggedInUserInfo) {
                    window.sessionStorage.setItem('isJointTransaction', 'true');
                    if (
                      response.data.serviceBody.serviceResponse.context.cartInfo.lines &&
                      response.data.serviceBody.serviceResponse.context.cartInfo.lines.length > 0
                    ) {
                      line =
                        response.data.serviceBody.serviceResponse.context.cartInfo.lines[0] &&
                        response.data.serviceBody.serviceResponse.context.cartInfo.lines[0].mtn
                          ? response.data.serviceBody.serviceResponse.context.cartInfo.lines[0].mtn
                          : '';
                      if (line) {
                        redirectUrl = `${loopQualApp.midnight ? Pages.Progressive : Pages.cbandurl}?line=${line}`;
                        redirectToPlansPage(redirectUrl);
                        resolve(response);
                        return;
                      }
                    }
                  }
                  if (loopQualApp && loopQualApp.isECComboMFJT && loopQualApp.isLoggedIn) {
                    window.sessionStorage.setItem('isECJointTransaction', 'true');
                    if (
                      response.data.serviceBody.serviceResponse.context.cartInfo.lines &&
                      response.data.serviceBody.serviceResponse.context.cartInfo.lines.length > 0
                    ) {
                      line =
                        response.data.serviceBody.serviceResponse.context.cartInfo.lines[0] &&
                        response.data.serviceBody.serviceResponse.context.cartInfo.lines[0].mtn
                          ? response.data.serviceBody.serviceResponse.context.cartInfo.lines[0].mtn
                          : '';
                      if (line) {
                        redirectUrl = `${Pages.Progressive}?line=${line}`;
                        redirectToPlansPage(redirectUrl);
                        resolve(response);
                        return;
                      }
                    }
                  }
                  dispatch(updatePlansPage(redirectUrl));
                  redirectToPlansPage(redirectUrl);
                }
              } else if (response.data.serviceBody.errors) {
                setToStorage('ONError', response);
                if (prodEspanolDomain()) {
                  redirectUrl = isMoveOrder ? Pages.ONFailMVurl : Pages.ONFailurl;
                } else {
                  redirectUrl = isMoveOrder ? getDomainUrl() + Pages.ONFailMVurl : getDomainUrl() + Pages.ONFailurl;
                }
                redirectToPlansPage(redirectUrl);
              }
            } else if (response && response.data && response.data.errors) {
              dispatch(hideLoader());
              const errorDataMessage =
                response.data.errors && response.data.errors[0] && response.data.errors[0].message
                  ? response.data.errors[0].message
                  : 'updateCartErrorResponse';
              logMetrics('UpdartCartError', errorDataMessage, 'Info');
              if (!isReTrigger) {
                checkNetworkAvailabilityReTrigger(
                  customerStreetDetails,
                  unitLookupDetails,
                  addressQualification,
                  loopQualApp,
                  dispatch,
                  null,
                  '',
                  fiosDetails,
                  moveDetails,
                  moversReTrigger,
                );
              } else {
                setToStorage('ONError', response);
                if (prodEspanolDomain()) {
                  redirectUrl = isMoveOrder ? Pages.ONFailMVurl : Pages.ONFailurl;
                } else {
                  redirectUrl = isMoveOrder ? getDomainUrl() + Pages.ONFailMVurl : getDomainUrl() + Pages.ONFailurl;
                }
                if (isMoveOrder) {
                  setToStorage('isMovers', 'true');
                  document.cookie = 'isMovers=true;path=/';
                  redirectUrl = Pages.cbandMVurl;
                }
                redirectToPlansPage(redirectUrl);
              }
            } else if (response && response.errors) {
              dispatch(hideLoader());
              logMetrics('UpdartCartResponseError', 'UpdartCartResponseErrorNetwork', 'Info');
              if (!isReTrigger) {
                checkNetworkAvailabilityReTrigger(
                  customerStreetDetails,
                  unitLookupDetails,
                  addressQualification,
                  loopQualApp,
                  dispatch,
                  null,
                  '',
                  fiosDetails,
                  moveDetails,
                  moverFlow,
                );
              } else {
                setToStorage('ONError', response);
                if (prodEspanolDomain()) {
                  redirectUrl = isMoveOrder ? Pages.ONFailMVurl : Pages.ONFailurl;
                } else {
                  redirectUrl = isMoveOrder ? getDomainUrl() + Pages.ONFailMVurl : getDomainUrl() + Pages.ONFailurl;
                }
                if (isMoveOrder) {
                  setToStorage('isMovers', 'true');
                  document.cookie = 'isMovers=true;path=/';
                  redirectUrl = Pages.cbandMVurl;
                }
                redirectToPlansPage(redirectUrl);
              }
            }
            resolve(response);
          })
          .catch((error) => {
            dispatch(hideLoader());
            logMetrics('UpdartCartNetworkError', error.message, 'Error');
            if (!isReTrigger) {
              checkNetworkAvailabilityReTrigger(
                customerStreetDetails,
                unitLookupDetails,
                addressQualification,
                loopQualApp,
                dispatch,
                null,
                '',
                fiosDetails,
                moveDetails,
                moverFlow,
              );
            } else {
              if (prodEspanolDomain()) {
                redirectUrl = isMoveOrder ? Pages.ONFailMVurl : Pages.ONFailurl;
              } else {
                redirectUrl = isMoveOrder ? getDomainUrl() + Pages.ONFailMVurl : getDomainUrl() + Pages.ONFailurl;
              }
              redirectToPlansPage(redirectUrl);
              reject(error);
            }
          });
      });
    }
  } catch (error) {
    dispatch(hideLoader());
    logMetrics('UpdartCartCatchError', error.message, 'Error');
    if (!isReTrigger) {
      checkNetworkAvailabilityReTrigger(
        customerStreetDetails,
        unitLookupDetails,
        addressQualification,
        loopQualApp,
        dispatch,
        null,
        '',
        fiosDetails,
        moveDetails,
        moverFlow,
      );
    } else {
      let redirectUrl = '';
      if (prodEspanolDomain()) {
        redirectUrl = isMoveOrder ? Pages.ONFailMVurl : Pages.ONFailurl;
      } else {
        redirectUrl = isMoveOrder ? getDomainUrl() + Pages.ONFailMVurl : getDomainUrl() + Pages.ONFailurl;
      }
      redirectToPlansPage(redirectUrl);
    }
  }
};

export const invokeUpdateCartNoRedirect = (lqRes, loopQualApp, dispatch, passiveLqsSuppression) => {
  try {
    dispatch(showLoader());
    const LQCradleTriggered = !!window.sessionStorage.getItem('LQCradleTriggered');
    if (!LQCradleTriggered) {
      cradleInvoke();
    }
    const bundleInfo = bundleInstallType(lqRes);
    const intendInfo = intendType(lqRes, loopQualApp.isLoggedIn);
    const url = apiUrl().updateCart;
    let flowName = '';
    if (lqRes && lqRes.qualifiedCBand) {
      flowName = '5G|CBD';
    } else if (lqRes && (lqRes.fiveGQualified || lqRes.qualified)) {
      flowName = '5G|mmWv';
    } else if (lqRes && (lqRes.lteQualified || lqRes.qualified4GHome)) {
      flowName = 'LTE|LTE';
    } else if (lqRes && lqRes.tgwQualified) {
      flowName = '5G|TGW';
    } else if (lqRes && lqRes?.wifiBackupCbandQualified) {
      flowName = '5G|CBD';
    } else if (lqRes && lqRes?.wifiBackupLteQualified) {
      flowName = 'LTE|LTE';
    }
    const vendorLocation =
      window.location.search &&
      window.location.search.indexOf('c') > -1 &&
      window.location.search.indexOf('outletId') > -1 &&
      (window.location.search.indexOf('nsa=y') > -1 || window.location.search.indexOf('NSA=Y') > -1)
        ? 'Y'
        : null;
    const location =
      window.location.search &&
      (window.location.search.indexOf('testQualification') > -1 ||
        window.location.search.indexOf('nsa=y') > -1 ||
        window.location.search.indexOf('NSA=Y') > -1)
        ? 'Y'
        : null;
    if (loopQualApp.isLoggedIn) {
      flowName = `AAL_${flowName}`;
    } else {
      flowName = `NSE_${flowName}`;
    }
    if (location && location === 'Y') {
      flowName = `test|${flowName}`;
    }
    if (lqRes && lqRes.preOrderInServiceDate) {
      flowName = `PREORDER|${flowName}`;
    }
    if (vendorLocation && vendorLocation === 'Y') {
      flowName = `Vendor|${flowName}`;
    }
    if (loopQualApp?.isACPFlow) {
      flowName = `ACP|${flowName}`;
    }
    if (loopQualApp?.isComboMFJT) {
      flowName = `JT_MH|${flowName}`;
    }
    if (loopQualApp?.sourceInfo?.toUpperCase() === 'AALCOMBO') {
      flowName = `SEQ_MH_MAQ|${flowName}`;
      setToStorage('SeqType', 'EC_MAQ_FWA');
    }
    if (loopQualApp?.isECComboMFJT) {
      flowName = `EC_JT_MH|${flowName}`;
    }
    const requiredflowType = { flowType: flowName };
    let data = null;
    if (passiveLqsSuppression) {
      data = {
        cartInfo: {
          clientAppName: 'VZW-DOTCOM',
          cartId: '',
          caseId: '',
          accountNumber: '',
          cartCreator: '',
          processingMTN: '',
          processStep: 'OrderNow',
          processAction: 'DeviceAndPlan',
          intendType: intendInfo,
          bundleInstallType: bundleInfo.installType,
          enableResume: 'true',
          addressRecordIdentifier: lqRes?.recordIdentifier,
          addressInfo: {
            addressLine1: lqRes?.addressLine1,
            addressLine2: lqRes?.addressLine2,
            city: lqRes?.city,
            state: lqRes?.state,
            zipCode: lqRes.zipCode,
          },
          transactionType: bundleInfo.transactionType,
        },
        midnight: loopQualApp && loopQualApp.midnight ? 'Y' : 'N',
        data: {
          lines: [
            {
              transactionType: bundleInfo.transactionType,
              depletionType: bundleInfo.depletionType,
            },
          ],
        },
      };
      flowName = `bulkQualIndicator|${flowName}`;
      setToStorage('UCFlowName', flowName);
      setToStorage('bulkQualIndicator', 'Y');
    } else {
      data = {
        cartInfo: {
          clientAppName: 'VZW-DOTCOM',
          cartId: '',
          caseId: '',
          accountNumber: '',
          cartCreator: '',
          processingMTN: '',
          processStep: 'OrderNow',
          processAction: 'DeviceAndPlan',
          intendType: intendInfo,
          bundleInstallType: bundleInfo.installType,
          enableResume: loopQualApp.isLoggedIn ? 'true' : 'false',
          transactionType: bundleInfo.transactionType,
        },
        midnight: loopQualApp && loopQualApp.midnight ? 'Y' : 'N',
        data: {
          lines: [
            {
              transactionType: bundleInfo.transactionType,
              depletionType: bundleInfo.depletionType,
            },
          ],
        },
      };
    }
    return new Promise((resolve, reject) => {
      callingApi(url, MethodType.POST, data, 'application/json', requiredflowType)
        .then((response) => {
          dispatch(hideLoader());
          setToStorage('cartResponse', JSON.stringify(response));
          if (response && response.data && response.data.serviceBody && response.data.serviceBody.serviceResponse) {
            if (response.data.serviceBody.serviceResponse.context && response.data.serviceBody.serviceResponse.context.cartInfo && (lqRes?.wifiBackupCbandQualified || lqRes?.wifiBackupLteQualified)) {
              setToStorage('cartExist', response.data.serviceBody.serviceResponse.context.cartInfo.cartExist);
              const cookieResponse = response.data.serviceBody.serviceResponse.context.cartInfo.cartExist;
              const val = `cartExist=${cookieResponse};path=/`;
              document.cookie = val;
            } 
            logMetrics('invokeUCAbandonedCart', 'invokeUCAbandonedCartServiceResponse', 'Info');
          } else if (response && response.data && response.data.errors) {
            setToStorage('invokeUCAbandonedCartError', 'Y');
            logMetrics('invokeUCAbandonedCartError', response.data.errors, 'Error');
          } else if (response && response.errors) {
            setToStorage('invokeUCAbandonedCartError', 'Y');
            logMetrics('invokeUCAbandonedCartError', response.errors, 'Error');
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          logMetrics('invokeUCAbandonedCartCatchApi', error.message, 'Error');
        });
    });
  } catch (error) {
    dispatch(hideLoader());
    logMetrics('invokeUpdateCartNoRedirect', error.message, 'Error');
  }
};

const processStepRedirection = (nextStep, loopQualApp) => {
  const planUrl = prodEspanolDomain()
    ? loopQualApp.midnight
      ? Pages.Progressive
      : Pages.cbandurl
    : getDomainUrl() + (loopQualApp.midnight ? Pages.Progressive : Pages.cbandurl);
  switch (nextStep) {
    case 'PlanSelection':
      return planUrl;
    case 'Accessories':
      return prodEspanolDomain() ? Pages.redirectAddons : getDomainUrl() + Pages.redirectAddons;
    case 'ShoppingCart':
      return prodEspanolDomain() ? Pages.redirectCart : getDomainUrl() + Pages.redirectCart;
    case 'InstallType':
      return prodEspanolDomain() ? Pages.redirectSetup : getDomainUrl() + Pages.redirectSetup;
    case 'ExpressCheckOut':
      return prodEspanolDomain() ? Pages.redirectExpressCheckout : getDomainUrl() + Pages.redirectExpressCheckout;
    default:
      return planUrl;
  }
};

const coverageLead = () => {
  try {
    const url = apiUrl().getNotify;
    const data = {
      type: 'coverageLead',
    };
    const location =
      window.location.search &&
      (window.location.search.indexOf('testQualification') > -1 ||
        window.location.search.indexOf('nsa=y') > -1 ||
        window.location.search.indexOf('NSA=Y') > -1)
        ? 'Y'
        : null;
    let flowName;
    if (location && location === 'Y') {
      flowName = 'test|';
    }
    const requiredflowType = { flowType: flowName };
    return new Promise((resolve, reject) => {
      callingApi(url, MethodType.POST, data, 'application/json', requiredflowType)
        .then((response) => {
          if (response && response.data && response.data.serviceBody) {
            setToStorage('coverageLead', response);
          }
          resolve(response);
        })
        .catch((error) => {
          logMetrics('coverageLead', error.message, 'Error');
          setToStorage('coverageLeaderror', error);
          reject(error);
        });
    });
  } catch (error) {
    catchJSException('LOOPQUAL2.0', 'coverageLead', error);
    logMetrics('coverageLead', error.message, 'Error');
  }
};
export const invokeUCAbandonedCart = (
  addressQualification,
  loopQualApp,
  dispatch,
  isAcp = false,
  emreiosFetchBody = {},
  moveDetails = null,
  customerStreetDetails = null,
  unitLookupDetails = null,
  fiosDetails = null,
  preferredbulkServiceDetail = null,
  bulkProfileDetails = null,
  newOrderTrigger = false,
) => {
  let lqresponse;
  if (
    emreiosFetchBody.customerType === 'E' &&
    !window.sessionStorage.getItem('check5GTriggered') &&
    !window.sessionStorage.getItem('fetchAuthDetails')
  ) {
    const lqResult = singlefromBulkQualUpdate(dispatch, preferredbulkServiceDetail, loopQualApp, fiosDetails);
    lqresponse = lqResult;
  } else {
    lqresponse = addressQualification.lqResult;
  }
  try {
    dispatch(showLoader());
    const LQCradleTriggered = !!window.sessionStorage.getItem('LQCradleTriggered');
    if (!LQCradleTriggered) {
      cradleInvoke();
    }
    const bundleInfo = bundleInstallType(lqresponse);
    const intendInfo = intendType(lqresponse, loopQualApp.isLoggedIn);
    const url = apiUrl().updateCart;
    let flowName = '';
    if (lqresponse && lqresponse.qualifiedCBand) {
      flowName = '5G|CBD';
    } else if (lqresponse && (lqresponse.fiveGQualified || lqresponse.qualified)) {
      flowName = '5G|mmWv';
    } else if (lqresponse && (lqresponse.lteQualified || lqresponse.qualified4GHome)) {
      flowName = 'LTE|LTE';
    } else if (lqresponse && lqresponse.tgwQualified) {
      flowName = '5G|TGW';
    } else if (lqresponse && lqresponse?.wifiBackupCbandQualified) {
      flowName = '5G|CBD';
    } else if (lqresponse && lqresponse?.wifiBackupLteQualified) {
      flowName = 'LTE|LTE';
    }
    const vendorLocation =
      window.location.search &&
      window.location.search.indexOf('c') > -1 &&
      window.location.search.indexOf('outletId') > -1 &&
      (window.location.search.indexOf('nsa=y') > -1 || window.location.search.indexOf('NSA=Y') > -1)
        ? 'Y'
        : null;
    const location =
      window.location.search &&
      (window.location.search.indexOf('testQualification') > -1 ||
        window.location.search.indexOf('nsa=y') > -1 ||
        window.location.search.indexOf('NSA=Y') > -1)
        ? 'Y'
        : null;
    if (loopQualApp.isLoggedIn) {
      flowName = `AAL_${flowName}`;
    } else {
      flowName = `NSE_${flowName}`;
    }
    if (location && location === 'Y') {
      flowName = `test|${flowName}`;
    }
    if (lqresponse && lqresponse.preOrderInServiceDate) {
      flowName = `PREORDER|${flowName}`;
    }
    if (vendorLocation && vendorLocation === 'Y') {
      flowName = `Vendor|${flowName}`;
    }
    if (loopQualApp?.isComboMFJT) {
      flowName = `JT_MH|${flowName}`;
    }
    if (loopQualApp?.sourceInfo?.toUpperCase() === 'AALCOMBO') {
      flowName = `SEQ_MH_MAQ|${flowName}`;
    }
    if (loopQualApp?.isECComboMFJT) {
      flowName = `EC_JT_MH|${flowName}`;
    }
    let requiredflowType = { flowType: flowName };
    if (loopQualApp?.isACPFlow) {
      requiredflowType = { flowType: `ACP|${flowName}` };
    }
    let data = {};
    if (emreiosFetchBody.customerType === 'E') {
      data = {
        cartInfo: {
          clientAppName: 'VZW-DOTCOM',
          cartId: '',
          caseId: '',
          accountNumber: '',
          cartCreator: '',
          processingMTN: '',
          processStep: 'OrderNow',
          processAction: 'DeviceAndPlan',
          intendType: intendInfo,
          bundleInstallType: bundleInfo.installType,
          enableResume: loopQualApp.isLoggedIn ? 'true' : 'false',
          addressRecordIdentifier: preferredbulkServiceDetail?.recordIdentifier,
          addressInfo: {
            addressLine1: customerStreetDetails?.dispstr ? customerStreetDetails.dispstr : customerStreetDetails?.addressLine1,
            addressLine2: customerStreetDetails && customerStreetDetails.addressLine2 ? customerStreetDetails.addressLine2 : '',
            city: customerStreetDetails && customerStreetDetails.muni,
            state: customerStreetDetails && customerStreetDetails.state,
            zipCode: customerStreetDetails && customerStreetDetails.zip,
          },
        },
        midnight: loopQualApp && loopQualApp.midnight ? 'Y' : 'N',
        data: {
          lines: [
            {
              depletionType: bundleInfo.depletionType,
            },
          ],
        },
      };
    } else {
      data = {
        cartInfo: {
          clientAppName: 'VZW-DOTCOM',
          cartId: '',
          caseId: '',
          accountNumber: '',
          cartCreator: '',
          processingMTN: '',
          processStep: 'OrderNow',
          processAction: 'DeviceAndPlan',
          intendType: intendInfo,
          bundleInstallType: bundleInfo.installType,
          enableResume: loopQualApp.isLoggedIn ? 'true' : 'false',
        },
        midnight: loopQualApp && loopQualApp.midnight ? 'Y' : 'N',
        data: {
          lines: [
            {
              depletionType: bundleInfo.depletionType,
            },
          ],
        },
      };
    }
    // if (isAcp) data.cartInfo.action = 'Restart';
    return new Promise((resolve) => {
      callingApi(url, MethodType.POST, data, 'application/json', requiredflowType)
        .then((response) => {
          dispatch(hideLoader());
          setToStorage('cartResponse', JSON.stringify(response));
          if (isAcp) {
            let redirectUrl;
            let pastBalance;
            let pastBalAmount;
            // let emriosUrl = 'https://public.uat.vz.emerios.com/en/#!/personalInformation?token=a611280f-ca71-4aa7-a1c7-dd3842bbfbc6';
            let emriosUrl = '';
            let emreiosFetchUrl = apiUrl()?.emriosFetchUrl;
            if (window.location.host.includes('awssit.ebiz.verizon.com')) {
              emreiosFetchUrl = 'https://wwwawssit.ebiz.verizon.com/inhome/fetchEmeriosInfo';
            } else if (window.location.host.includes('nte1aws.ebiz.verizon.com')) {
              emreiosFetchUrl = 'https://wwwnte1aws.ebiz.verizon.com/inhome/fetchEmeriosInfo';
            } else if (window.location.host.includes('nte3aws.ebiz.verizon.com')) {
              emreiosFetchUrl = 'https://wwwnte3aws.ebiz.verizon.com/inhome/fetchEmeriosInfo';
            } else if (window.location.host.includes('98.verizon.com')) {
              emreiosFetchUrl = 'https://www98.verizon.com/inhome/fetchEmeriosInfo';
            } else if (window.location.host.includes('localhost') || (window.location.search && window.location.search.indexOf('testbau=y') > -1)) {
              emreiosFetchUrl = 'https://www.verizon.com/inhome/fetchEmeriosInfo';
            }
            if (response && response.data && response.data.serviceBody && response.data.serviceBody.serviceResponse) {
              if (response.data.serviceBody.serviceResponse.context && response.data.serviceBody.serviceResponse.context.cartInfo) {
                setToStorage('cartExist', response.data.serviceBody.serviceResponse.context.cartInfo.cartExist);
                const cookieResponse = response.data.serviceBody.serviceResponse.context.cartInfo.cartExist;
                const val = `cartExist=${cookieResponse};path=/`;
                document.cookie = val;
              }
              if (
                response.data.serviceBody.serviceResponse.context &&
                response.data.serviceBody.serviceResponse.context.cartInfo &&
                response.data.serviceBody.serviceResponse.context.cartInfo.cartId
              ) {
                setToStorage('cartid', response.data.serviceBody.serviceResponse.context.cartInfo.cartId);
              }
              if (response.data.serviceBody.serviceResponse.context && response.data.serviceBody.serviceResponse.context.caseId) {
                setToStorage('caseid', response.data.serviceBody.serviceResponse.context.caseId);
              }
              if (
                loopQualApp.isECComboMFJT &&
                response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility[0]
                  ?.inEligibilityReasonCode === 'PENDING_ORDER'
              ) {
                dispatch({ type: MVActionTypes.IS_PENDING_MOVE_SERVICE, value: true });
                dispatch(updateCurrentView(LQ_VIEWS.MULTIPLE_SERVICES_QUALIFIED_MV_VIEW));
                resolve(response);
                return;
              }
              if (response.data.serviceBody.serviceResponse.context?.cartInfo?.orderRestrictions?.accountLevelChangeEligibility[0]?.reasonCode) {
                const { reasonCode } = response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility[0];
                if (reasonCode === 'CASHONLY_IND') {
                  redirectUrl = prodEspanolDomain() ? Pages.ONReasonCodeUrl : getDomainUrl() + Pages.ONReasonCodeUrl;
                  dispatch(updatePlansPage(redirectUrl));
                  redirectToPlansPage(redirectUrl);
                  resolve(response);
                  return;
                }
                if (reasonCode === 'FRAUD_INDICATOR_X') {
                  redirectUrl = prodEspanolDomain() ? Pages.ONFraudOrderUrl : getDomainUrl() + Pages.ONFraudOrderUrl;
                  dispatch(updatePlansPage(redirectUrl));
                  redirectToPlansPage(redirectUrl);
                  resolve(response);
                  return;
                }
                if (reasonCode === 'FRAUD_INDICATOR_Y') {
                  redirectUrl = prodEspanolDomain() ? Pages.ONFraudOrderYUrl : getDomainUrl() + Pages.ONFraudOrderYUrl;
                  dispatch(updatePlansPage(redirectUrl));
                  redirectToPlansPage(redirectUrl);
                  resolve(response);
                  return;
                }
                if (reasonCode === 'COLLECTIONS_IND') {
                  redirectUrl = prodEspanolDomain() ? Pages.collections_INDurl : getDomainUrl() + Pages.collections_INDurl;
                  dispatch(updatePlansPage(redirectUrl));
                  redirectToPlansPage(redirectUrl);
                  resolve(response);
                  return;
                }
              }
              if (response.data.serviceBody?.serviceResponse?.context?.contextInfo) {
                if (response.data.serviceBody.serviceResponse.context.contextInfo.pastDueBalance) {
                  pastBalance = response.data.serviceBody.serviceResponse.context.contextInfo.pastDueBalance;
                  if (response.data.serviceBody.serviceResponse.context.contextInfo.pastDueBalanceAmount) {
                    pastBalAmount = response.data.serviceBody.serviceResponse.context.contextInfo.pastDueBalanceAmount;
                  }
                  if (
                    response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions &&
                    response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility &&
                    response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility.length > 0
                  ) {
                    if (
                      response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility[0]
                        .reasonAttributes &&
                      response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility[0].reasonAttributes
                        .arPastDueBalanceAmount
                    ) {
                      pastBalAmount =
                        response.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility[0].reasonAttributes
                          .arPastDueBalanceAmount;
                    }
                  }
                }
              }
              if (pastBalance === 'true') {
                setToStorage('pastDueBalAmount', pastBalAmount);
                redirectUrl = prodEspanolDomain() ? Pages.ACCFailurl : getDomainUrl() + Pages.ACCFailurl;
                dispatch(updatePlansPage(redirectUrl));
                redirectToPlansPage(redirectUrl);
                resolve(response);
                return;
              }
              const { existingCase, vfExistInCart } = response.data.serviceBody.serviceResponse.context.customerInfo;
              if (existingCase === 'true' && (vfExistInCart === true || loopQualApp.isVFResumeFlow) && !newOrderTrigger) {
                setToStorage('requiredflowType', requiredflowType.flowType);
                dispatch(updateVFResumeFlow(true));
                dispatch(updateCurrentView(LQ_VIEWS.RESUME_SMART_CART_VIEW));
                dispatch({ type: ActionTypes.EMERIOS_BODY, value: emreiosFetchBody });
                return;
              }
            }

            // emreiosFetchBody.businessLine = qualifiedSerice
            // fiosVisitId !== '' ? emreiosFetchBody.fiosSessionId = fiosVisitId : emreiosFetchBody.fiosSessionId = ''
            // redirection done here from the fetched url from emrios
            const nonACPFlow = emreiosFetchBody?.redirectUrl?.split('?acp=y')[0];
            const missingAddressDetails = true;
            if (missingAddressDetails && emreiosFetchBody.customerType === 'E') {
              if (window.sessionStorage.getItem('fetchAuthDetails')) {
                emreiosFetchBody = {
                  ...emreiosFetchBody,
                  addressLine1: customerStreetDetails?.addressLine1,
                  addressLine2: customerStreetDetails?.addressLine2,
                  city: customerStreetDetails?.city,
                  state: customerStreetDetails?.state,
                  zipCode: customerStreetDetails?.zip,
                  nonAALFlag: false,
                };
              } else {
                emreiosFetchBody = {
                  ...emreiosFetchBody,
                  addressLine1: lqresponse?.addrInfo?.addressLine1,
                  addressLine2: lqresponse?.addrInfo?.addressLine2,
                  city: lqresponse?.addrInfo?.city,
                  state: lqresponse?.addrInfo?.state,
                  zipCode: lqresponse?.addrInfo?.zip,
                  nonAALFlag: false,
                };
              }
            }
            if (
              (!addressQualification?.isAcpPresent || addressQualification?.isAcpPresent === 'false') &&
              (!addressQualification?.isVfpPresent ||
                addressQualification?.isVfpPresent === 'false' ||
                (bulkProfileDetails?.acpInfo?.isVfExist &&
                  bulkProfileDetails?.billingAddress?.state === 'NY' &&
                  (!bulkProfileDetails?.acpInfo?.isVfNyExist || bulkProfileDetails?.acpInfo?.isVfNyExist === 'false')))
            ) {
              dispatch(showLoader());
              callingApi(emreiosFetchUrl, MethodType.POST, emreiosFetchBody, 'application/json', requiredflowType)
                .then((capturedResponse) => {
                  emriosUrl = capturedResponse?.data?.data?.redirectUrl;

                  if (emriosUrl === undefined) {
                    orderNowFiveGNsa(
                      addressQualification,
                      loopQualApp,
                      dispatch,
                      false,
                      moveDetails,
                      false,
                      customerStreetDetails,
                      unitLookupDetails,
                      fiosDetails,
                      false,
                      false,
                    );
                  }

                  if (
                    emriosUrl !== undefined &&
                    (emreiosFetchBody.customerType === 'N' ||
                      (emreiosFetchBody.customerType === 'E' &&
                        (!addressQualification?.isAcpPresent || addressQualification?.isAcpPresent === 'false') &&
                        (!addressQualification?.isVfpPresent || addressQualification?.isVfpPresent === 'false')) ||
                      (bulkProfileDetails?.acpInfo?.isVfExist &&
                        bulkProfileDetails?.billingAddress?.state === 'NY' &&
                        (!bulkProfileDetails?.acpInfo?.isVfNyExist || bulkProfileDetails?.acpInfo?.isVfNyExist === 'false')))
                  ) {
                    redirectToPlansPage(emriosUrl);
                    dispatch(hideLoader());
                  } else {
                    redirectToPlansPage(nonACPFlow);
                    dispatch(hideLoader());
                  }
                })
                .catch((error) => {
                  console.log(error);
                  logMetrics('FetchEmeriosError', error.message, 'Error');
                  redirectToPlansPage(nonACPFlow);
                  dispatch(hideLoader());
                });
            } else {
              redirectToPlansPage(emreiosFetchBody.redirectUrl);
              dispatch(hideLoader());
            }
          } else if (response && response.data && response.data.serviceBody && response.data.serviceBody.serviceResponse) {
            logMetrics('invokeUCAbandonedCart', 'invokeUCAbandonedCartServiceResponse', 'Info');
            window.vzAbandonedCartModal.open('', '/sales/home/expresscart.html?expresscart=true&resumecart=true&abandoned=true');
          } else if (response && response.data && response.data.errors) {
            logMetrics('invokeUCAbandonedCartError', response.data.errors, 'Error');
          } else if (response && response.errors) {
            logMetrics('invokeUCAbandonedCartError', response.errors, 'Error');
          }
          resolve(response);
        })
        .catch((error) => {
          logMetrics('invokeUCAbandonedCartCatchApi', error.message, 'Error');
        });
    });
  } catch (error) {
    dispatch(hideLoader());
    logMetrics('invokeUCAbandonedCartCatch', error.message, 'Error');
  }
};

export const onUberMapContinue = async (mapData, propsData) => {
  logMetrics('Here Map', 'Continue Clicked', 'Info');
  await checkMap5GAvailability(
    mapData,
    propsData.customerStreetDetails,
    propsData.unitLookupDetails,
    propsData.addressQualification,
    propsData.dispatch,
    propsData.cookies,
    propsData.loopQualApp,
    propsData.fiosDetails,
    propsData.moveDetails,
    propsData.bulkQualDetails,
  );
  mapCompletionStatus(propsData.dispatch, true);
  showMap(propsData.dispatch, false);
};

export const onReloadMap = (propsData) => {
  reloadMap(propsData.dispatch, false);
};

export const fwaRedirectionOnSuccess = (
  resolve,
  cartResponse,
  dispatch,
  loopQualApp,
  response,
  autoGeoQualLink,
  passiveLqsSuppression,
  skipRedirection,
) => {
  try {
    if (autoGeoQualLink) {
      dispatch(updateSkeleton(false));
    }
    setToStorage('cartResponse', JSON.stringify(cartResponse));
    let flowName = '';
    if (loopQualApp?.isACPFlow) {
      flowName = `ACP|${flowName}`;
    }
    const requiredflowType = { flowType: flowName };
    let pastBalance = '';
    let pastBalAmount = '';
    let redirectUrl = '';
    if (!cartResponse?.data?.serviceBody?.serviceResponse && cartResponse?.data?.serviceHeader) {
      if (cartResponse?.data?.serviceHeader?.statusMsg.includes('Unable to Determine') && cartResponse?.data?.serviceHeader?.statusCode === '99') {
        dispatch(updateCurrentView(LQ_VIEWS.LBO_VIEW));
        logMetrics('fwaRedirectionOnSuccess error', cartResponse?.data?.serviceHeader?.statusMsg, 'Error');
      }
    } else if (cartResponse?.data?.serviceBody?.serviceResponse) {
      if (cartResponse?.data?.serviceBody?.serviceResponse?.context) {
        setToStorage('caseid', cartResponse.data.serviceBody.serviceResponse.context.caseId);
        if (cartResponse.data.serviceBody.serviceResponse.context?.cartInfo) {
          setToStorage('cartid', cartResponse.data.serviceBody.serviceResponse.context.cartInfo.cartId);
        }
        if (cartResponse.data.serviceBody.serviceResponse.context?.cartInfo?.cartExist) {
          setToStorage('cartExist', cartResponse.data.serviceBody.serviceResponse.context.cartInfo.cartExist);
          const cookieResponse = cartResponse.data.serviceBody.serviceResponse.context.cartInfo.cartExist;
          const val = `cartExist=${cookieResponse};path=/`;
          document.cookie = val;
        }
      }
      lqsTagTriggered(dispatch, true);
      if (cartResponse.data.serviceBody.serviceResponse.context.contextInfo) {
        if (cartResponse.data.serviceBody.serviceResponse.context.contextInfo.pastDueBalance) {
          pastBalance = cartResponse.data.serviceBody.serviceResponse.context.contextInfo.pastDueBalance;
          if (cartResponse.data.serviceBody.serviceResponse.context.contextInfo.pastDueBalanceAmount) {
            pastBalAmount = cartResponse.data.serviceBody.serviceResponse.context.contextInfo.pastDueBalanceAmount;
          }
          if (
            cartResponse.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions &&
            cartResponse.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility &&
            cartResponse.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility.length > 0
          ) {
            if (
              cartResponse.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility[0].reasonAttributes &&
              cartResponse.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility[0].reasonAttributes
                .arPastDueBalanceAmount
            ) {
              pastBalAmount =
                cartResponse.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility[0].reasonAttributes
                  .arPastDueBalanceAmount;
            }
          }
        }
      }
      if (pastBalance === 'true') {
        setToStorage('pastDueBalAmount', pastBalAmount);
        redirectUrl = prodEspanolDomain() ? Pages.ACCFailurl : getDomainUrl() + Pages.ACCFailurl;
        dispatch(updatePlansPage(redirectUrl));
        if (loopQualApp.lq4) {
          closeModalWindow(dispatch);
        }
        redirectToPlansPage(redirectUrl);
        resolve(cartResponse);
        return;
      }
      if (loopQualApp.isLoggedIn) {
        if (cartResponse && cartResponse.data && cartResponse.data.serviceBody) {
          if (cartResponse.data.serviceBody.serviceResponse.context.cartInfo) {
            if (cartResponse.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions) {
              if (
                cartResponse.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions &&
                cartResponse.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility &&
                cartResponse.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility[0]
              ) {
                if (
                  loopQualApp.isECComboMFJT &&
                  cartResponse.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility[0]
                    ?.inEligibilityReasonCode === 'PENDING_ORDER'
                ) {
                  dispatch({ type: MVActionTypes.IS_PENDING_MOVE_SERVICE, value: true });
                  dispatch(updateCurrentView(LQ_VIEWS.MULTIPLE_SERVICES_QUALIFIED_MV_VIEW));
                  resolve(response);
                  return;
                }
                if (cartResponse.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility[0].reasonCode) {
                  const { reasonCode } =
                    cartResponse.data.serviceBody.serviceResponse.context.cartInfo.orderRestrictions.accountLevelChangeEligibility[0];
                  if (reasonCode === 'CASHONLY_IND') {
                    redirectUrl = prodEspanolDomain() ? Pages.ONReasonCodeUrl : getDomainUrl() + Pages.ONReasonCodeUrl;
                    dispatch(updatePlansPage(redirectUrl));
                    if (loopQualApp.lq4) {
                      closeModalWindow(dispatch);
                    }
                    redirectToPlansPage(redirectUrl);
                    resolve(response);
                    return;
                  }
                  if (reasonCode === 'FRAUD_INDICATOR_X') {
                    redirectUrl = prodEspanolDomain() ? Pages.ONFraudOrderUrl : getDomainUrl() + Pages.ONFraudOrderUrl;
                    dispatch(updatePlansPage(redirectUrl));
                    if (loopQualApp.lq4) {
                      closeModalWindow(dispatch);
                    }
                    redirectToPlansPage(redirectUrl);
                    resolve(response);
                    return;
                  }
                  if (reasonCode === 'FRAUD_INDICATOR_Y') {
                    redirectUrl = prodEspanolDomain() ? Pages.ONFraudOrderYUrl : getDomainUrl() + Pages.ONFraudOrderYUrl;
                    dispatch(updatePlansPage(redirectUrl));
                    if (loopQualApp.lq4) {
                      closeModalWindow(dispatch);
                    }
                    redirectToPlansPage(redirectUrl);
                    resolve(response);
                    return;
                  }
                  if (reasonCode === 'COLLECTIONS_IND') {
                    redirectUrl = prodEspanolDomain() ? Pages.collections_INDurl : getDomainUrl() + Pages.collections_INDurl;
                    dispatch(updatePlansPage(redirectUrl));
                    if (loopQualApp.lq4) {
                      closeModalWindow(dispatch);
                    }
                    redirectToPlansPage(redirectUrl);
                    resolve(response);
                    return;
                  }
                }
              }
            }
          }
        }
      }
      const existingCase = cartResponse?.data?.serviceBody?.serviceResponse?.context?.customerInfo?.existingCase;
      const vfExistInCart = cartResponse?.data?.serviceBody?.serviceResponse?.context?.customerInfo?.vfExistInCart;
      if (existingCase && existingCase === 'true') {
        setToStorage('ExistingCase', existingCase);
        if (!passiveLqsSuppression) {
          loopQualVzTaglqs('lq result - ', 'success', response.targetView === LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW ? '5g' : 'lte', null, true);
        }
        window.sessionStorage.removeItem('activeLqaCalled');
        dispatch(hideLoader());
        if (plansvisit) {
          // cancelling smartcart for plansvitid LQS scenario
          const redirectionPlansPage = Pages.fiveGNSAExpressCart;
          dispatch(updatePlansPage(redirectionPlansPage));
          redirectToPlansPage(redirectionPlansPage);
        } else {
          if (autoGeoQualLink) {
            openModalWindow('LQAPP', dispatch);
          }
          if (vfExistInCart === true || loopQualApp.isVFResumeFlow) {
            if (loopQualApp.lq4) {
              openModalWindow('LQAPP', dispatch);
              dispatch(updateSkeleton(false));
            }
            setToStorage('requiredflowType', requiredflowType.flowType);
            dispatch(updateVFResumeFlow(true));
            dispatch(updateCurrentView(LQ_VIEWS.RESUME_SMART_CART_VIEW));
            return;
          }
          dispatch(updateCurrentView(LQ_VIEWS.RESUME_SMART_CART_VIEW));
        }
      } else {
        setToStorage('ONResponse', cartResponse);
        setToStorage('FWA_LQSPO', 'Y');
        if (loopQualApp?.homeLQ) {
          setToStorage('HomeLQ', true);
        }
        const redirectionPlansPage = `https://${window.location.host}/sales/home/progressiveplan.html`;
        if (loopQualApp.lq4) {
          closeModalWindow(dispatch);
        }
        dispatch(hideLoader());
        dispatch(updatePlansPage(redirectionPlansPage));
        if (!skipRedirection) {
          redirectToPlansPage(redirectionPlansPage);
        } else {
          dispatch(updateCurrentView(response.targetView));
          if (window.renderProgressivemodal) {
            window.renderProgressivemodal('progressive_modal', (unmount) => {
              window.progressiveModuleUnmount = unmount;
              greyRemove();
            });
          }
        }
      }
      resolve(cartResponse);
    }
  } catch (error) {
    logMetrics('UpdartCartCatchError', error.message, 'Error');
  }
};
