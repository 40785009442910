const configDefaults = {
  targetElementId: 'lq-widget-root',
  lqApi: 'LOCUS_API',
  platform: 'ALL',
  scTag: 'wireline',
  tws: '',
  crossSell: '',
  flowType: '',
  seqType: '',
  fiosInquire: '',
  autoQualOrderingRedirect: '',
  renderSettings: {
    mode: 'modal',
    externalTrigger: ['.checkAvailabilityBtn'],
    miniLq: {
      enabled: true,
      footer: '',
      layout: {
        default: {
          topPosition: '69px',
          zIndex: '900',
        },
        mq_lg: {
          breakPoint: '1020px',
          topPosition: '120px',
          zIndex: '899',
        },
      },
    },
    homeLQ: {
      lqTitle: {
        title: 'Verizon Home Internet',
        subTitle: 'Find out whether Fios Home Internet, 5G Home Internet or LTE Home Internet is available in your area.',
        blankTitle: "Let's see what's available.",
      },
      font: {
        family: 'Verizon-NHG-eDS',
      },
      theme: {
        color: '#FFFFFF',
        surface: 'dark',
        btnPrimaryTextColor: '#EE0000',
        skeletonColor: '#bab4b49e',
      },
      banner: {
        height: '460px',
        color: '#EE0000',
        image: {
          preLQView: {
            desktop: 'https://ss7.vzw.com/is/image/VerizonWireless/streamlined-mlp-hero-d?&fmt=png-alpha&scl=1',
            tablet: 'https://ss7.vzw.com/is/image/VerizonWireless/streamlined-mlp-hero-t?&fmt=png-alpha&scl=1',
            mobile: 'https://ss7.vzw.com/is/image/VerizonWireless/streamlined-mlp-hero-m?&fmt=png-alpha&scl=1',
          },
        },
      },
    },
  },
};

function getConfig() {
  return window.vzwlqwidgetconfig || configDefaults;
}

export const getLQPlatform = () => getConfig().platform || configDefaults.platform;

export const getTwsFlow = () => getConfig().tws || configDefaults.tws;

export const getCrossSell = () => getConfig().crossSell || configDefaults.crossSell;

export const getRenderSettings = () => getConfig().renderSettings || configDefaults.renderSettings;

export const getFlowType = () => getConfig().flowType || configDefaults.flowType;

export const getSeqType = () => getConfig().seqType || configDefaults.seqType;

export const getTwsStickyLQ = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const istwsStickyLa = urlSearchParams.get('twsStickyLq');
  return istwsStickyLa || getConfig().twsStickyLq || configDefaults.twsStickyLq;
};

export const getSourceInfo = () => getConfig().sourceInfo || configDefaults.sourceInfo || getConfig().source || configDefaults.source;

export const getFooter = () =>
  (getConfig().renderSettings && getConfig().renderSettings.miniLq && getConfig().renderSettings.miniLq.footer) ||
  (configDefaults.renderSettings && configDefaults.renderSettings.miniLq && configDefaults.renderSettings.miniLq.footer);

export const getFiosInquire = () => getConfig().fiosInquire || configDefaults.fiosInquire;

export const getHomeLQSettings = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const isSMLP_QP = urlSearchParams.get('smlpLQ') === 'Y';
  return isSMLP_QP ? configDefaults?.renderSettings?.homeLQ : getRenderSettings()?.homeLQ;
};

export const isMVP2 = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const isMVP = urlSearchParams.get('mvp-smlp') === 'Y';
  return isMVP;
};

export default getConfig();
