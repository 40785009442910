export const capitalize = (str) => {
  try {
    return str.replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase());
  } catch (e) {
    return '';
  }
};

export const capitalizeString = (sentence) =>
  sentence
    ?.split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export const makePlural = (text, ignoreText) => {
  if (text.includes(ignoreText)) return text;
  return `${text}s`;
};

export const makeTWSText = (twsText = '') => {
  if (twsText.includes('milvet')) {
    return capitalize(twsText.replace('milvet', 'military'));
  }
  return makePlural(capitalize(twsText.replace('medical_new', 'nurse').replace('responder', 'first responder')));
};
