import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalTitle, ModalBody } from '@vds/modals';
import { ButtonIcon } from '@vds/button-icons';
import styled from 'styled-components';
import IconClose from '@vds/icons/close';
import { closeModalWindow, existingCustomerModal } from './action';
import { dataTrack, dataTrackwithDetail } from '../../../utils/vzdl';
import { getDomainUrl, prodEspanolDomain } from '../../../constants/api-urls';
import { Pages, Platforms, Flowtype, LQ_VIEWS } from '../../../constants/common';
import { redirectToPlansPage, setToStorage, isMYVZWAPP } from '../../../utils/lqServiceUtil';
import '../../../assets/css/common.css';
import { Spacing } from '../../home-lq/styles';
import Loader from '../loader/Loader';
import { updateCurrentView } from '../../loop-qual-app/actions';
import { setPreferredService } from '../../move-lq/action';
import { MovesQualification as MVActionTypes } from '../../../constants/actionTypes';
import { resetSessionView } from '../SessionTimeout/action';
// import { resetSessionView } from '../SessionTimeout/action';

const CloseIcon = styled.div`
  position: absolute;
  top: 12px;
  @media (max-width: 767px) {
    top: 0px;
  }
  right: 12px;
  cursor: pointer;
  background-color: #ffffff;
  border: none;
  z-index: 10;
  :hover * {
    stroke-width: 2;
  }
`;
const ModalTitleThreeDotO = styled.div`
  ${(props) =>
    `font-size: 2rem;
  line-height: 2.25rem;
  font-weight: 300;
  font-family: Verizon-NHG-eDS;
  padding: 0px 0px 0px ${props.homeLQ ? 0 : 4}px;
  ${
    props.homeLQ
      ? `
  @media screen and (max-width: 767px) {
  padding-right: 44px;
  }
  `
      : ``
  }
  `}
`;

class modalOverlay extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.isEscapeKey = false;
  }

  componentDidMount() {
    window.addEventListener('keydown', (e) => this.onKeyDown(e));
    window.addEventListener('popstate', this.handlePopState);
    const body = document.querySelector('body');
    if (body.classList.contains('hide-focus-outline')) {
      setTimeout(() => {
        const modalContainer = document.querySelector('#lqw-modal-container');
        const input = modalContainer && modalContainer.querySelector('input');
        const closeButton = document.querySelector('.closeButton');
        if (input) {
          input.scrollTop = 0;
        }
        if (closeButton) {
          closeButton.focus();
        }
      }, 300);
    }
  }

  componentDidUpdate() {
    if (document.getElementById('modal-close-btn')) {
      document.getElementById('modal-close-btn').setAttribute('aria-label', 'Press enter or escape key to close the modal');
    }
    if (window.vzlqw) {
      window.vzlqw.isvzlqwModalOpened = true;
      if (document.getElementById('modalTitleHeading')) {
        document.getElementById('modalTitleHeading').setAttribute('aria-label', `${this.props.modalTitle}`);
      }
      if (window.chatWindowInBackGround) {
        window.chatWindowInBackGround(true);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('popstate', this.handlePopState);
  }

  onCloseModal = (e) => {
    e.preventDefault();
    this.closeModal();
    if (this.props.modalOverlay.existingCustomerModal) {
      existingCustomerModal(this.props.dispatch, false);
    }

    setTimeout(() => {
      const a = document.querySelector('.checkAvailabilityBtn');
      if (a) {
        a.focus();
      }
    }, 100);
  };

  onKeyDown = (e) => {
    const highlightedItem = document.getElementById('selectedOption');
    this.isEscapeKey = e.keyCode === 27;
    if (this.isEscapeKey && this.props.hideCloseBtn && this.props.modalOverlay.isModalOpened) {
      this.closeModal();
      return;
    }
    if (!highlightedItem) {
      if (
        e.keyCode === 27 &&
        this.props.modalOverlay.isModalOpened &&
        !(
          window.location.pathname.includes('inhome/checkavailability') ||
          window.location.pathname.includes('inhome/qualification') ||
          window.location.pathname.includes('inhome/moves/checkavailability') ||
          window.location.pathname.includes('inhome/moves/qualification') ||
          (isMYVZWAPP() && this.props.loopQualApp.sourceInfo?.toUpperCase() === 'CPC')
        )
      ) {
        this.closeModal();
      } else if (e.keyCode === 40 || e.keyCode === 38) {
        //
      }
    }
  };

  handlePopState = () => {
    if (
      this.props.modalOverlay.isModalOpened &&
      !(
        this.isEscapeKey &&
        (window.location.pathname.includes('inhome/checkavailability') ||
          window.location.pathname.includes('inhome/qualification') ||
          window.location.pathname.includes('inhome/moves/checkavailability') ||
          window.location.pathname.includes('inhome/moves/qualification') ||
          this.props.hideCloseBtn ||
          (isMYVZWAPP() && this.props.loopQualApp.sourceInfo?.toUpperCase() === 'CPC'))
      )
    ) {
      this.closeModal();
      this.isEscapeKey = false;
    }
    this.isEscapeKey = false;
  };

  /* onCloseKeyDown = (e) => {
    const body = document.querySelector('body');
    if (e.charCode === 13 && !body.classList.contains('hide-focus-outline')) {
      this.closeModal();
    }
  }; */

  closeModal() {
    if (this.props.sessionTimeout?.loggedInExpiredView) {
      this.props.dispatch(resetSessionView());
    }
    if (this.props.modalOverlay.existingCustomerModal) {
      existingCustomerModal(this.props.dispatch, false);
    }
    if (
      (this.props.loopQualApp.flowType === Flowtype.MOVERS || this.props?.loopQualApp?.flowType === 'MOVERS') &&
      this.props?.moveDetails?.moveProfileDetails?.accountLevelInfo?.[0]?.lineLevelInfo?.length > 1
    ) {
      this.props.dispatch(updateCurrentView(LQ_VIEWS.MULTIPLE_SERVICES_QUALIFIED_MV_VIEW));
      this.props.dispatch(setPreferredService(null));
      this.props.dispatch({ type: MVActionTypes.IS_DELAYED_BILLING, value: false });
      this.props.dispatch({ type: MVActionTypes.IS_MTN_SUSPENDED, value: false });
      this.props.dispatch({ type: MVActionTypes.IS_PENDING_SERVICE_ORDER, value: false });
      this.props.dispatch({ type: MVActionTypes.IS_PENDING_MOVE_SERVICE, value: false });
      this.props.dispatch({ type: MVActionTypes.IS_BALANCE_DUE, value: false });
      window.sessionStorage.removeItem('networkBandwidthType');
    }
    if (
      this.props.loopQualApp.isACPFlow ||
      this.props.loopQualApp.isComboMFJT ||
      this.props.loopQualApp.isECComboMFJT ||
      this.props.loopQualApp.storeLocater ||
      this.props.loopQualApp.sourceInfo?.toUpperCase() === 'CPC' ||
      this.props.loopQualApp.TWS ||
      this.props.loopQualApp.isResponsiveLQ ||
      this.props.loopQualApp.homeLQ
    ) {
      if (!(isMYVZWAPP() && this.props.loopQualApp.sourceInfo?.toUpperCase() === 'CPC')) {
        closeModalWindow(this.props.dispatch);
      }
      return true;
    }
    const redirectUrl = prodEspanolDomain() ? '' : getDomainUrl();
    const pathName = window.location.pathname && window.location.pathname.toLowerCase();
    if (this.props.loopQualApp.flowType !== Flowtype.MOVERS) {
      if (
        this.props.addressQualification?.lqResult?.fiosQualified &&
        pathName !== Pages.redirectToFios &&
        this.props.loopQualApp?.lqPlatform !== Platforms.FIOS
      ) {
        if (redirectUrl && redirectUrl === 'https://www98.verizon.com') {
          redirectToPlansPage(`https://vzwqa3.verizonwireless.com${Pages.redirectToFios}`, '_blank');
        } else {
          redirectToPlansPage(redirectUrl + Pages.redirectToFios, '_blank');
        }
      } else if (
        (this.props.addressQualification.lqResult.qualifiedCBand || this.props.addressQualification.lqResult.fiveGQualified) &&
        pathName !== Pages.redirectToFiveGHome &&
        !this.props.addressQualification?.lqResult?.fiosQualified
      ) {
        if (redirectUrl && redirectUrl === 'https://www98.verizon.com') {
          redirectToPlansPage(`https://vzwqa3.verizonwireless.com${Pages.redirectToFiveGHome}`, '_blank');
        } else {
          redirectToPlansPage(redirectUrl + Pages.redirectToFiveGHome, '_blank');
        }
      } else if (this.props.addressQualification.lqResult.lteQualified && pathName !== Pages.redirectToLte) {
        if (redirectUrl && redirectUrl === 'https://www98.verizon.com') {
          redirectToPlansPage(`https://vzwqa3.verizonwireless.com${Pages.redirectToLte}`, '_blank');
        } else {
          redirectToPlansPage(redirectUrl + Pages.redirectToLte, '_blank');
        }
      } else {
        const cpcGetStart = document.getElementById('addHomeInternetID');
        if (cpcGetStart) {
          setTimeout(() => cpcGetStart.focus(), 10);
        }
        const learnVzdlObj = window.sessionStorage.getItem('learnVzdlObj');
        if (learnVzdlObj) {
          window.vzdl = JSON.parse(learnVzdlObj);
          setToStorage('learnVzdlObj', '');
        }
      }
    }
    closeModalWindow(this.props.dispatch);
  }

  // eslint-disable-next-line no-shadow
  renderCloseButton = ({ modalTitle, modalOverlay, loopQualApp }) => (
    <>
      {loopQualApp?.homeLQ && this.props?.loaderView?.loaderStatus && <Loader absoluteLoader />}
      <CloseIcon>
        <ButtonIcon
          size="large"
          ariaLabel={`close! ${modalTitle || modalOverlay.modalTitle || ''}`}
          className="closeButton"
          onClick={this.onCloseModal}
          tabIndex={0}
          data-track={
            this.props.loopQualApp.isComboEmailflow
              ? dataTrackwithDetail('link', 'close sign', 'lets confirm mobile+home discount')
              : dataTrack('link', 'close- widget')
          }
          renderIcon={(props) =>
            ((!window.location.href.includes('cpcCheckAvailability') && !window.location.pathname.includes('inhome/') && !this.props.hideCloseBtn) ||
              window?.vzwlqwidgetconfig?.sourceInfo === 'plansvisit') && <IconClose {...props} />
          }
        />
      </CloseIcon>
    </>
  );

  render() {
    const open = this.props.modalOverlay.isModalOpened;
    const template = this.props.modalBody || '';
    const modalTitle = this.props.modalTitle || this.props.modalOverlay.modalTitle || '';
    const isVfEmailFlow = modalTitle.indexOf('Verizon Forward discount.') > -1;
    const plansvisit = window?.vzwlqwidgetconfig?.sourceInfo === 'plansvisit';
    const homeLQ = this.props.loopQualApp?.homeLQ;
    const modalProps = homeLQ ? { maxHeight: homeLQ?.modal?.height } : {};
    const modalContent = (
      <div tabIndex="-1">
        <Modal
          id="lq20"
          className={`lq20 modalHeight ${homeLQ ? 'homeLQModalOverlay' : 'lQModalOverlay'}`}
          contentContainer="lqw-modal-container"
          opened={open}
          closeButton={
            (this.props.loopQualApp.sourceInfo !== 'digitalCombo' &&
              !window.location.href.includes('cpcCheckAvailability') &&
              !window.location.pathname.includes('inhome/') &&
              !this.props.hideCloseBtn) ||
            plansvisit
              ? this.renderCloseButton(this.props)
              : null
          }
          disableOutsideClick
          modalId={this.props.modalOverlay.modalId}
          ariaLabel="modal"
          customScroll
          {...modalProps}
        >
          <ModalTitle primitive="h2">
            <ModalTitleThreeDotO homeLQ={homeLQ}>
              <div id="modalTitleHeading" aria-label={modalTitle} className="modalTitleHeading" style={{ fontWeight: isVfEmailFlow && '300' }}>
                {modalTitle}
              </div>
            </ModalTitleThreeDotO>
          </ModalTitle>
          {this.props.addressView}
          <ModalBody>
            {homeLQ && this.props.addressView ? (
              <Spacing mobilePadding={`${homeLQ?.spacing?.xxLarge} 0px 0px 0px`} padding={`${homeLQ?.spacing?.xxLarge} 0px 0px 0px`}>
                {template}
              </Spacing>
            ) : (
              template
            )}
          </ModalBody>
        </Modal>
      </div>
    );
    return (
      <div
        role="dialog"
        aria-modal="true"
        id="modal-container"
        tabIndex="-1"
        aria-hidden={modalContent ? 'false' : 'true'}
        style={{ display: modalContent ? 'block' : 'none' }}
      >
        {modalContent}
      </div>
    );
  }
}
export default modalOverlay;
modalOverlay.propTypes = {
  modalOverlay: PropTypes.string,
  modalBody: PropTypes.string,
  dispatch: PropTypes.string,
  modalTitle: PropTypes.string,
  loopQualApp: PropTypes.string,
  addressQualification: PropTypes.object,
  hideCloseBtn: PropTypes.any,
  cookies: PropTypes.string,
  addressView: PropTypes.any,
  loaderView: PropTypes.any,
  moveDetails: PropTypes.any,
  sessionTimeout: PropTypes.any,
};
