const sitProxy_1 = {
  getStreetAddrLocus2: 'https://oa-stg.verizon.com/locus-address-api/address/typeahead-address?streetterm=',
  getStreetAddrLocus: 'https://api-stg.verizon.com/locus-typeahead/address/typeahead-address?streetterm=',
  locusapikey: 'YT2N6GkuOjvISXmvQUeSW5XA1MA990VF',
  getPartialStreetAddr: 'https://atomserviceseastsit.ebiz.verizon.com/v05g/sit/addresslookup/addresses/street?streetterm=',
  getStreetAddrNTAS: 'https://oa-qa.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/street?streetterm=',
  getLocusUnits: 'https://api-stg.verizon.com/locus-typeahead/address/typeahead-loc-unit',
  getNTASUnits: 'https://api-qa.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/units',
  getStreetWithLocusID: 'https://oa-stg.verizon.com/locus-address-api/address/by-location-ids',
  getStreetWithGeo: 'https://oa-stg.verizon.com/locus-address-api/address/by-lat-long',
  locusIdApiKey: 'EydXjW5gV2dfAGbjvUhSo61zX9hGJZQA',
  generateToken: 'https://wwwawssit.ebiz.verizon.com/inhome/generatetoken',
  setBrowserInfo: 'https://wwwawssit.ebiz.verizon.com/inhome/setbrowserinfo',
  getServerVisitIdAndVisitorId: 'https://wwwawssit.ebiz.verizon.com/inhome/getServerVisitIdAndVisitorId',
  generatevisitId: 'https://wwwawssit.ebiz.verizon.com/inhome/generatevisitid',
  generatevisitorid: 'https://wwwawssit.ebiz.verizon.com/inhome/generatevisitorid',
  generatevisitidforvendor: 'https://wwwawssit.ebiz.verizon.com/inhome/generatevisitidforvendor',
  getvendorvisitdetails: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/sessionmanager/getvendorvisitdetails',
  jsNotify: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/common/logger/logmsg',
  fiosQualification: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/addressqualification/address/qualification?',
  signInNew:
    'https://securesit1.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%253a%252f%252fsso-np.ebiz.verizon.com%252fconsumer%252fmyverizon%252frouter',
  signIn:
    'https://sit-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://wwwawssit.ebiz.verizon.com&remainInOverlay=N&mode=i&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3a%2f%2fsit-ssoauth.verizon.com%2fsso%2fProcessLoginServlet%3floginType%3dconsumer%26target%3dhttps%253a%252f%252fsso-np.ebiz.verizon.com%252fconsumer%252fmyverizon%252frouter',
  MyVzSigninInhomeAF:
    'https://ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://www.verizon.com&remainInOverlay=N&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter%3Ftarget%3Dhttps%253A%252F%252Fwww.verizon.com%252finhome%252fchange%252fsignin%253fflowtype%253daf',
  MyVzSigninInhomeAFNew:
    'https://secure.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter%3Ftarget%3Dhttps%253A%252F%252Fwww.verizon.com%252finhome%252fchange%252fsignin%253fflowtype%253daf',
  fiosExistingService: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/addresslookup/addresses/existingservice',
  fiosProcessSaveCart: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/smartcart/order/processsmartcart',
  fiosSendSmsOrEmail: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/common/notification/sendsmsoremail',
  fiosSmartCartCancel: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/smartcart/order/smartcartcancel',
  kafkaErrorAppLogs: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/common/kafka/atom_applogs',
  kafkaPageLogs: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/common/kafka/atom_pagelogs',
  getFiosQualification: 'https://wwwawssit.ebiz.verizon.com/foryoursmallbiz/smb/api/Qualify/GetFiosQualification',
  vendorFlow: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/sessionmanager/vendorflowvalidate',
  vendorFlowToken: 'https://wwwawssit.ebiz.verizon.com/inhome/validatevendortoken',
  runtimeKey: 'https://wwwawssit.ebiz.verizon.com/personal/services/getaccordionmapping.runtimekeys.json',
  validateCouponToken: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/careflowservice/careflow/validatecoupontoken',
  ssoMHAuthiniticate: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/vzwoffereligibility/eligibility/validate',
  verification: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/vzwoffereligibility/eligibility/verification',
  checkPreLink: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/vzwoffereligibility/eligibility/checkprelink?prelink=add',
  websocketIGBackendUrl: 'wss://qa3amkafka-app1.ebiz.verizon.com:6131/ig/acss/echo',
  pollingIGBackendUrl: 'https://accessmgrqa2.verizonwireless.com/accessmanager/public/adaptiveauth/2fa/polling',
  jsNotifyAsDynamic: 'https://www.verizon.com/soe/digital/prospect/peripheralsservice/jsNotifyAsDynamic',
  fetchFiosProfile: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/accountqualificationservice/account/getAddressDetails',
  emriosFetchUrl: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/vfw/fetchemeriosinfo',
  signIn_auth: 'https://securesit1.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=',
  futureNotification: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/addresslookup/addresses/futurenotification',
};

const sitApigee_1 = {
  getStreetAddrLocus2: 'https://oa-stg.verizon.com/locus-address-api/address/typeahead-address?streetterm=',
  getStreetAddrLocus: 'https://api-stg.verizon.com/locus-typeahead/address/typeahead-address?streetterm=',
  // "getStreetAddrLocus": "https://oa-uat.ebiz.verizon.com/locus-address-api/address/typeahead-address?streetterm=",
  locusapikey: 'YT2N6GkuOjvISXmvQUeSW5XA1MA990VF', // "MJlq7xMNa3mzS6jcMUb3YuhPxeSLQcWl",
  getPartialStreetAddr: 'https://atomserviceseastsit.ebiz.verizon.com/v05g/sit/addresslookup/addresses/street?streetterm=',
  getStreetAddrNTAS: 'https://api-qa.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/street?streetterm=',
  getLocusUnits: 'https://api-stg.verizon.com/locus-typeahead/address/typeahead-loc-unit',
  getNTASUnits: 'https://api-qa.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/units',
  getStreetWithLocusID: 'https://oa-stg.verizon.com/locus-address-api/address/by-location-ids',
  getStreetWithGeo: 'https://api-qa.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/digital/fetchlocusinfo',
  locusIdApiKey: 'EydXjW5gV2dfAGbjvUhSo61zX9hGJZQA',
  generateToken: 'https://wwwawssit.ebiz.verizon.com/inhome/generatetoken',
  setBrowserInfo: 'https://wwwawssit.ebiz.verizon.com/inhome/setbrowserinfo',
  getServerVisitIdAndVisitorId: 'https://wwwawssit.ebiz.verizon.com/inhome/getServerVisitIdAndVisitorId',
  generatevisitId: 'https://wwwawssit.ebiz.verizon.com/inhome/generatevisitid',
  generatevisitorid: 'https://wwwawssit.ebiz.verizon.com/inhome/generatevisitorid',
  generatevisitidforvendor: 'https://wwwawssit.ebiz.verizon.com/inhome/generatevisitidforvendor',
  getvendorvisitdetails: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/sessionmanager/getvendorvisitdetails',
  jsNotify: 'https://api-qa.ebiz.verizon.com/atomapi/v1/common/logger/logmsg',
  fiosQualification: 'https://api-qa.ebiz.verizon.com/atomapi/v1/addressqualification/address/fiosqualification',
  signInNew:
    'https://securesit1.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%253a%252f%252fsso-np.ebiz.verizon.com%252fconsumer%252fmyverizon%252frouter',
  signIn:
    'https://sit-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://wwwawssit.ebiz.verizon.com&remainInOverlay=N&mode=i&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3a%2f%2fsit-ssoauth.verizon.com%2fsso%2fProcessLoginServlet%3floginType%3dconsumer%26target%3dhttps%253a%252f%252fsso-np.ebiz.verizon.com%252fconsumer%252fmyverizon%252frouter',
  MyVzSigninInhomeAF:
    'https://ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://www.verizon.com&remainInOverlay=N&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter%3Ftarget%3Dhttps%253A%252F%252Fwww.verizon.com%252finhome%252fchange%252fsignin%253fflowtype%253daf',
  MyVzSigninInhomeAFNew:
    'https://secure.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter%3Ftarget%3Dhttps%253A%252F%252Fwww.verizon.com%252finhome%252fchange%252fsignin%253fflowtype%253daf',
  fiosExistingService: 'https://api-qa.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/existingservice',
  fiosProcessSaveCart: 'https://api-qa.ebiz.verizon.com/atomapi/v1/smartcart/order/processsmartcart',
  fiosSendSmsOrEmail: 'https://api-qa.ebiz.verizon.com/atomapi/v1/common/notification/sendsmsoremail',
  fiosSmartCartCancel: 'https://api-qa.ebiz.verizon.com/atomapi/v1/smartcart/order/smartcartcancel',
  kafkaErrorAppLogs: 'https://api-qa.ebiz.verizon.com/atomapi/v1/common/kafka/atom_applogs',
  kafkaPageLogs: 'https://api-qa.ebiz.verizon.com/atomapi/v1/common/kafka/atom_pagelogs',
  getFiosQualification: 'https://wwwawssit.ebiz.verizon.com/foryoursmallbiz/smb/api/Qualify/GetFiosQualification',
  vendorFlow: 'https://api-qa.ebiz.verizon.com/atomapi/v1/sessionmanager/vendorflowvalidate',
  vendorFlowToken: 'https://wwwawssit.ebiz.verizon.com/inhome/validatevendortoken',
  runtimeKey: 'https://wwwawssit.ebiz.verizon.com/personal/services/getaccordionmapping.runtimekeys.json',
  validateCouponToken: 'https://api-qa.ebiz.verizon.com/atomapi/v1/careflowservice/careflow/validatecoupontoken',
  ssoMHAuthiniticate: 'https://api-qa.ebiz.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/validate',
  verification: 'https://api-qa.ebiz.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/verification',
  checkPreLink: 'https://api-qa.ebiz.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/checkprelink?prelink=add',
  websocketIGBackendUrl: 'wss://qa3amkafka-app1.ebiz.verizon.com:6131/ig/acss/echo',
  pollingIGBackendUrl: 'https://accessmgrqa2.verizonwireless.com/accessmanager/public/adaptiveauth/2fa/polling',
  jsNotifyAsDynamic: 'https://www.verizon.com/soe/digital/prospect/peripheralsservice/jsNotifyAsDynamic',
  emriosFetchUrl: 'https://api-qa.ebiz.verizon.com/atomapi/v1/vfw/fetchemeriosinfo',
  fetchFiosProfile: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accountqualificationservice/account/getAddressDetails',
  signIn_auth: 'https://securesit1.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=',
  futureNotification: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/addresslookup/addresses/futurenotification',
};
const sitProxy_SIT = {
  getStreetAddrLocus2: 'https://oa-stg.verizon.com/locus-address-api/address/typeahead-address?streetterm=',
  getStreetAddrLocus: 'https://api-uat.verizon.com/locus-typeahead/address/typeahead-address?streetterm=',
  locusapikey: 'MJlq7xMNa3mzS6jcMUb3YuhPxeSLQcWl', // 'YT2N6GkuOjvISXmvQUeSW5XA1MA990VF',
  getPartialStreetAddr: 'https://atomserviceseastsit.ebiz.verizon.com/v05g/sit/addresslookup/addresses/street?streetterm=',
  getStreetAddrNTAS: 'https://oa-qa.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/street?streetterm=',
  futureNotification: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/addresslookup/addresses/futurenotification',
  getLocusUnits: 'https://api-uat.verizon.com/locus-typeahead/address/typeahead-loc-unit',
  getNTASUnits: 'https://api-qa.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/units',
  getStreetWithLocusID: 'https://oa-stg.verizon.com/locus-address-api/address/by-location-ids',
  getStreetWithGeo: 'https://api-qa.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/digital/fetchlocusinfo',
  locusIdApiKey: 'EydXjW5gV2dfAGbjvUhSo61zX9hGJZQA',
  generateToken: 'https://wwwawssit.ebiz.verizon.com/inhome/generatetoken',
  setBrowserInfo: 'https://wwwawssit.ebiz.verizon.com/inhome/setbrowserinfo',
  getServerVisitIdAndVisitorId: 'https://wwwawssit.ebiz.verizon.com/inhome/getServerVisitIdAndVisitorId',
  generatevisitId: 'https://wwwawssit.ebiz.verizon.com/inhome/generatevisitid',
  generatevisitorid: 'https://wwwawssit.ebiz.verizon.com/inhome/generatevisitorid',
  generatevisitidforvendor: 'https://wwwawssit.ebiz.verizon.com/inhome/generatevisitidforvendor',
  getvendorvisitdetails: 'https://api-qa.ebiz.verizon.com/atomapi/v1/sessionmanager/getvendorvisitdetails',
  jsNotify: 'https://api-qa.ebiz.verizon.com/atomapi/v1/common/logger/logmsg',
  fiosQualification: 'https://api-qa.ebiz.verizon.com/atomapi/v1/addressqualification/address/fiosqualification',
  signInNew:
    'https://securesit1.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%253a%252f%252fsso-np.ebiz.verizon.com%252fconsumer%252fmyverizon%252frouter',
  signIn:
    'https://sit-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://wwwawssit.ebiz.verizon.com&remainInOverlay=N&mode=i&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3a%2f%2fsit-ssoauth.verizon.com%2fsso%2fProcessLoginServlet%3floginType%3dconsumer%26target%3dhttps%253a%252f%252fsso-np.ebiz.verizon.com%252fconsumer%252fmyverizon%252frouter',
  MyVzSigninInhomeAF:
    'https://ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://www.verizon.com&remainInOverlay=N&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter%3Ftarget%3Dhttps%253A%252F%252Fwww.verizon.com%252finhome%252fchange%252fsignin%253fflowtype%253daf',
  MyVzSigninInhomeAFNew:
    'https://secure.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter%3Ftarget%3Dhttps%253A%252F%252Fwww.verizon.com%252finhome%252fchange%252fsignin%253fflowtype%253daf',
  fiosExistingService: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/addresslookup/addresses/existingservice',
  fiosProcessSaveCart: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/smartcart/order/processsmartcart',
  fiosSendSmsOrEmail: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/common/notification/sendsmsoremail',
  fiosSmartCartCancel: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/smartcart/order/smartcartcancel',
  kafkaErrorAppLogs: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/common/kafka/atom_applogs',
  kafkaPageLogs: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/common/kafka/atom_pagelogs',
  getFiosQualification: 'https://wwwawssit.ebiz.verizon.com/foryoursmallbiz/smb/api/Qualify/GetFiosQualification',
  vendorFlow: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/sessionmanager/vendorflowvalidate',
  vendorFlowToken: 'https://wwwawssit.ebiz.verizon.com/inhome/validatevendortoken',
  runtimeKey: 'https://wwwawssit.ebiz.verizon.com/personal/services/getaccordionmapping.runtimekeys.json',
  validateCouponToken: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/careflowservice/careflow/validatecoupontoken',
  ssoMHAuthiniticate: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/vzwoffereligibility/eligibility/validate',
  verification: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/vzwoffereligibility/eligibility/verification',
  checkPreLink: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/vzwoffereligibility/eligibility/checkprelink?prelink=add',
  websocketIGBackendUrl: 'wss://qa3amkafka-app1.ebiz.verizon.com:6131/ig/acss/echo',
  pollingIGBackendUrl: 'https://accessmgrqa2.verizonwireless.com/accessmanager/public/adaptiveauth/2fa/polling',
  jsNotifyAsDynamic: 'https://www.verizon.com/soe/digital/prospect/peripheralsservice/jsNotifyAsDynamic',
  emriosFetchUrl: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/vfw/fetchemeriosinfo',
  fetchFiosProfile: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accountqualificationservice/account/getAddressDetails',
  signIn_auth: 'https://securesit1.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=',
};

const sitApigee_SIT = {
  getStreetAddrLocus2: 'https://oa-stg.verizon.com/locus-address-api/address/typeahead-address?streetterm=',
  getStreetAddrLocus: 'https://api-uat.verizon.com/locus-typeahead/address/typeahead-address?streetterm=',
  // "getStreetAddrLocus": "https://oa-uat.ebiz.verizon.com/locus-address-api/address/typeahead-address?streetterm=",
  locusapikey: 'MJlq7xMNa3mzS6jcMUb3YuhPxeSLQcWl', // 'YT2N6GkuOjvISXmvQUeSW5XA1MA990VF', // "YT2N6GkuOjvISXmvQUeSW5XA1MA990VF",// "MJlq7xMNa3mzS6jcMUb3YuhPxeSLQcWl",
  getPartialStreetAddr: 'https://atomserviceseastsit.ebiz.verizon.com/v05g/sit/addresslookup/addresses/street?streetterm=',
  getStreetAddrNTAS: 'https://api-qa.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/street?streetterm=',
  getLocusUnits: 'https://api-uat.verizon.com/locus-typeahead/address/typeahead-loc-unit',
  getNTASUnits: 'https://api-qa.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/units',
  getStreetWithLocusID: 'https://oa-stg.verizon.com/locus-address-api/address/by-location-ids',
  getStreetWithGeo: 'https://api-qa.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/digital/fetchlocusinfo',
  locusIdApiKey: 'EydXjW5gV2dfAGbjvUhSo61zX9hGJZQA',
  generateToken: 'https://wwwawssit.ebiz.verizon.com/inhome/generatetoken',
  setBrowserInfo: 'https://wwwawssit.ebiz.verizon.com/inhome/setbrowserinfo',
  getServerVisitIdAndVisitorId: 'https://wwwawssit.ebiz.verizon.com/inhome/getServerVisitIdAndVisitorId',
  generatevisitId: 'https://wwwawssit.ebiz.verizon.com/inhome/generatevisitid',
  generatevisitorid: 'https://wwwawssit.ebiz.verizon.com/inhome/generatevisitorid',
  generatevisitidforvendor: 'https://wwwawssit.ebiz.verizon.com/inhome/generatevisitidforvendor',
  getvendorvisitdetails: 'https://api-qa.ebiz.verizon.com/atomapi/v1/sessionmanager/getvendorvisitdetails',
  jsNotify: 'https://api-qa.ebiz.verizon.com/atomapi/v1/common/logger/logmsg',
  fiosQualification: 'https://api-qa.ebiz.verizon.com/atomapi/v1/addressqualification/address/fiosqualification',
  signInNew:
    'https://securesit1.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%253a%252f%252fsso-np.ebiz.verizon.com%252fconsumer%252fmyverizon%252frouter',
  signIn:
    'https://sit-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://wwwawssit.ebiz.verizon.com&remainInOverlay=N&mode=i&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3a%2f%2fsit-ssoauth.verizon.com%2fsso%2fProcessLoginServlet%3floginType%3dconsumer%26target%3dhttps%253a%252f%252fsso-np.ebiz.verizon.com%252fconsumer%252fmyverizon%252frouter',
  MyVzSigninInhomeAF:
    'https://ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://www.verizon.com&remainInOverlay=N&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter%3Ftarget%3Dhttps%253A%252F%252Fwww.verizon.com%252finhome%252fchange%252fsignin%253fflowtype%253daf',
  MyVzSigninInhomeAFNew:
    'https://secure.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter%3Ftarget%3Dhttps%253A%252F%252Fwww.verizon.com%252finhome%252fchange%252fsignin%253fflowtype%253daf',
  fiosExistingService: 'https://api-qa.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/existingservice',
  futureNotification: 'https://api-qa.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/futurenotification',
  fiosProcessSaveCart: 'https://api-qa.ebiz.verizon.com/atomapi/v1/smartcart/order/processsmartcart',
  fiosSendSmsOrEmail: 'https://api-qa.ebiz.verizon.com/atomapi/v1/common/notification/sendsmsoremail',
  fiosSmartCartCancel: 'https://api-qa.ebiz.verizon.com/atomapi/v1/smartcart/order/smartcartcancel',
  kafkaErrorAppLogs: 'https://api-qa.ebiz.verizon.com/atomapi/v1/common/kafka/atom_applogs',
  kafkaPageLogs: 'https://api-qa.ebiz.verizon.com/atomapi/v1/common/kafka/atom_pagelogs',
  getFiosQualification: 'https://wwwawssit.ebiz.verizon.com/foryoursmallbiz/smb/api/Qualify/GetFiosQualification',
  vendorFlow: 'https://api-qa.ebiz.verizon.com/atomapi/v1/sessionmanager/vendorflowvalidate',
  vendorFlowToken: 'https://wwwawssit.ebiz.verizon.com/inhome/validatevendortoken',
  runtimeKey: 'https://wwwawssit.ebiz.verizon.com/personal/services/getaccordionmapping.runtimekeys.json',
  validateCouponToken: 'https://api-qa.ebiz.verizon.com/atomapi/v1/careflowservice/careflow/validatecoupontoken',
  ssoMHAuthiniticate: 'https://api-qa.ebiz.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/validate',
  verification: 'https://api-qa.ebiz.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/verification',
  checkPreLink: 'https://api-qa.ebiz.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/checkprelink?prelink=add',
  websocketIGBackendUrl: 'wss://qa3amkafka-app1.ebiz.verizon.com:6131/ig/acss/echo',
  pollingIGBackendUrl: 'https://accessmgrqa2.verizonwireless.com/accessmanager/public/adaptiveauth/2fa/polling',
  jsNotifyAsDynamic: 'https://www.verizon.com/soe/digital/prospect/peripheralsservice/jsNotifyAsDynamic',
  emriosFetchUrl: 'https://api-qa.ebiz.verizon.com/atomapi/v1/vfw/fetchemeriosinfo',
  fetchFiosProfile: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accountqualificationservice/account/getAddressDetails',
  signIn_auth: 'https://securesit1.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=',
};
const nte1Apigee = {
  getStreetAddrLocus2: 'https://oa-stg.verizon.com/locus-address-api/address/typeahead-address?streetterm=',
  getStreetAddrLocus: 'https://oa-qa.ebiz.verizon.com/locus-address-api/address/typeahead-address?streetterm=',
  // getStreetAddrLocus: 'https://api-stg.verizon.com/locus-typeahead/address/typeahead-address?streetterm=',
  // "getStreetAddrLocus": "https://oa-uat.ebiz.verizon.com/locus-address-api/address/typeahead-address?streetterm=",
  locusapikey: 'MvaCGJaWUOM1zkdFJietJiYyq15SJGdF', // 'YT2N6GkuOjvISXmvQUeSW5XA1MA990VF',// "MJlq7xMNa3mzS6jcMUb3YuhPxeSLQcWl",
  getPartialStreetAddr: 'https://atomserviceseastsit.ebiz.verizon.com/v05g/sit/addresslookup/addresses/street?streetterm=',
  getStreetAddrNTAS: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/street?streetterm=',
  getLocusUnits: 'https://oa-qa.ebiz.verizon.com/locus-typeahead/address/typeahead-loc-unit',
  getNTASUnits: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/units',
  getStreetWithLocusID: 'https://oa-dev2.ebiz.verizon.com/locus-address-api/address/by-location-ids',
  getStreetWithGeo: 'https://oa-dev2.ebiz.verizon.com/locus-address-api/address/by-lat-long',
  locusIdApiKey: 'yfAZa5Aw7HP6RZz9VPoGHMfEG9gDZUgx',
  generateToken: 'https://wwwnte1aws.ebiz.verizon.com/inhome/generatetoken',
  setBrowserInfo: 'https://wwwnte1aws.ebiz.verizon.com/inhome/setbrowserinfo',
  getServerVisitIdAndVisitorId: 'https://wwwnte1aws.ebiz.verizon.com/inhome/getServerVisitIdAndVisitorId',
  generatevisitId: 'https://wwwnte1aws.ebiz.verizon.com/inhome/generatevisitid',
  generatevisitorid: 'https://wwwnte1aws.ebiz.verizon.com/inhome/generatevisitorid',
  generatevisitidforvendor: 'https://wwwnte1aws.ebiz.verizon.com/inhome/generatevisitidforvendor',
  getvendorvisitdetails: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/sessionmanager/getvendorvisitdetails',
  jsNotify: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/common/logger/logmsg',
  fiosQualification: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/addressqualification/address/fiosqualification',
  MyVzSigninInhomeAFNew:
    'https://ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://www.verizon.com&remainInOverlay=N&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter%3Ftarget%3Dhttps%253A%252F%252Fwww.verizon.com%252finhome%252fchange%252fsignin%253fflowtype%253daf',
  signInNew:
    'https://securesit1.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%253a%252f%252fsso-np.ebiz.verizon.com%252fconsumer%252fmyverizon%252frouter',
  signIn:
    'https://sit-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://wwwawssit.ebiz.verizon.com&remainInOverlay=N&mode=i&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3a%2f%2fsit-ssoauth.verizon.com%2fsso%2fProcessLoginServlet%3floginType%3dconsumer%26target%3dhttps%253a%252f%252fsso-np.ebiz.verizon.com%252fconsumer%252fmyverizon%252frouter',
  fiosExistingService: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/existingservice',
  fiosProcessSaveCart: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/smartcart/order/processsmartcart',
  fiosSendSmsOrEmail: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/common/notification/sendsmsoremail',
  fiosSmartCartCancel: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/smartcart/order/smartcartcancel',
  kafkaErrorAppLogs: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/common/kafka/atom_applogs',
  kafkaPageLogs: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/common/kafka/atom_pagelogs',
  getFiosQualification: 'https://wwwawssit.ebiz.verizon.com/foryoursmallbiz/smb/api/Qualify/GetFiosQualification',
  vendorFlow: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/sessionmanager/vendorflowvalidate',
  vendorFlowToken: 'https://wwwnte1aws.ebiz.verizon.com/inhome/validatevendortoken',
  runtimeKey: 'https://wwwnte1aws.ebiz.verizon.com/personal/services/getaccordionmapping.runtimekeys.json',
  validateCouponToken: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/careflowservice/careflow/validatecoupontoken',
  ssoMHAuthiniticate: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/validate',
  verification: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/verification',
  checkPreLink: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/checkprelink?prelink=add',
  websocketIGBackendUrl: 'wss://qa3amkafka-app1.ebiz.verizon.com:6131/ig/acss/echo',
  pollingIGBackendUrl: 'https://securesit1.verizon.com/accessmanager/public/adaptiveauth/2fa/polling',
  jsNotifyAsDynamic: 'https://www.verizon.com/soe/digital/prospect/peripheralsservice/jsNotifyAsDynamic',
  emriosFetchUrl: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/vfw/fetchemeriosinfo',
  signIn_auth: 'https://securesit1.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=',
  fetchFiosProfile: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accountqualificationservice/account/getAddressDetails',
  futureNotification: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/futurenotification',
  vzwOfferEligibility: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/vzwoffereligibility',
};
const nte3Apigee = {
  getStreetAddrLocus2: 'https://oa-stg.verizon.com/locus-address-api/address/typeahead-address?streetterm=',
  getStreetAddrLocus: 'https://api-stg.verizon.com/locus-typeahead/address/typeahead-address?streetterm=',
  // "getStreetAddrLocus": "https://oa-uat.ebiz.verizon.com/locus-address-api/address/typeahead-address?streetterm=",
  locusapikey: 'YT2N6GkuOjvISXmvQUeSW5XA1MA990VF', // "MJlq7xMNa3mzS6jcMUb3YuhPxeSLQcWl",
  getPartialStreetAddr: 'https://atomserviceseastsit.ebiz.verizon.com/v05g/sit/addresslookup/addresses/street?streetterm=',
  getStreetAddrNTAS: 'https://oa-uat.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/street?streetterm=',
  getLocusUnits: 'https://api-stg.verizon.com/locus-typeahead/address/typeahead-loc-unit',
  getNTASUnits: 'https://oa-uat.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/units',
  getStreetWithLocusID: 'https://oa-uat.ebiz.verizon.com/locus-address-api/address/by-location-ids',
  getStreetWithGeo: 'https://oa-uat.ebiz.verizon.com/locus-address-api/address/by-lat-long',
  locusIdApiKey: 'ejbweMnjlNVL8FnlAMr0F3sJ6RsHAFSv',
  generateToken: 'https://wwwnte3aws.ebiz.verizon.com/inhome/generatetoken',
  setBrowserInfo: 'https://wwwnte3aws.ebiz.verizon.com/inhome/setbrowserinfo',
  getServerVisitIdAndVisitorId: 'https://wwwnte3aws.ebiz.verizon.com/inhome/getServerVisitIdAndVisitorId',
  generatevisitId: 'https://wwwnte3aws.ebiz.verizon.com/inhome/generatevisitid',
  generatevisitorid: 'https://wwwnte3aws.ebiz.verizon.com/inhome/generatevisitorid',
  generatevisitidforvendor: 'https://wwwnte3aws.ebiz.verizon.com/inhome/generatevisitidforvendor',
  getvendorvisitdetails: 'https://oa-uat.ebiz.verizon.com/atomapi/v1/sessionmanager/getvendorvisitdetails',
  jsNotify: 'https://oa-uat.ebiz.verizon.com/atomapi/v1/common/logger/logmsg',
  fiosQualification: 'https://oa-uat.ebiz.verizon.com/atomapi/v1/addressqualification/address/fiosqualification',
  MyVzSigninInhomeAFNew:
    'https://secure.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter%3Ftarget%3Dhttps%253A%252F%252Fwww.verizon.com%252finhome%252fchange%252fsignin%253fflowtype%253daf',
  signInNew:
    'https://securesit1.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%253a%252f%252fsso-np.ebiz.verizon.com%252fconsumer%252fmyverizon%252frouter',
  signIn:
    'https://sit-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://wwwawssit.ebiz.verizon.com&remainInOverlay=N&mode=i&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3a%2f%2fsit-ssoauth.verizon.com%2fsso%2fProcessLoginServlet%3floginType%3dconsumer%26target%3dhttps%253a%252f%252fsso-np.ebiz.verizon.com%252fconsumer%252fmyverizon%252frouter',
  fiosExistingService: 'https://oa-uat.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/existingservice',
  fiosProcessSaveCart: 'https://oa-uat.ebiz.verizon.com/atomapi/v1/smartcart/order/processsmartcart',
  fiosSendSmsOrEmail: 'https://oa-uat.ebiz.verizon.com/atomapi/v1/common/notification/sendsmsoremail',
  fiosSmartCartCancel: 'https://oa-uat.ebiz.verizon.com/atomapi/v1/smartcart/order/smartcartcancel',
  kafkaErrorAppLogs: 'https://oa-uat.ebiz.verizon.com/atomapi/v1/common/kafka/atom_applogs',
  kafkaPageLogs: 'https://oa-uat.ebiz.verizon.com/atomapi/v1/common/kafka/atom_pagelogs',
  getFiosQualification: 'https://wwwawssit.ebiz.verizon.com/foryoursmallbiz/smb/api/Qualify/GetFiosQualification',
  vendorFlow: 'https://oa-uat.ebiz.verizon.com/atomapi/v1/sessionmanager/vendorflowvalidate',
  vendorFlowToken: 'https://wwwnte3aws.ebiz.verizon.com/inhome/validatevendortoken',
  runtimeKey: 'https://sales-bau-sit2-publisher.ebiz.verizon.com/personal/services/getaccordionmapping.runtimekeys.json',
  validateCouponToken: 'https://oa-uat.ebiz.verizon.com/atomapi/v1/careflowservice/careflow/validatecoupontoken',
  ssoMHAuthiniticate: 'https://oa-uat.ebiz.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/validate',
  verification: 'https://oa-uat.ebiz.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/verification',
  checkPreLink: 'https://oa-uat.ebiz.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/checkprelink?prelink=add',
  websocketIGBackendUrl: 'wss://qa3amkafka-app1.ebiz.verizon.com:6131/ig/acss/echo',
  pollingIGBackendUrl: 'https://accessmgrqa2.verizonwireless.com/accessmanager/public/adaptiveauth/2fa/polling',
  emriosFetchUrl: 'https://oa-uat.ebiz.verizon.com/atomapi/v1/vfw/fetchemeriosinfo',
  fetchFiosProfile: 'https://oa-uat.ebiz.verizon.com/atomapi/v1/accountqualificationservice/account/getAddressDetails',
  futureNotification: 'https://oa-uat.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/futurenotification',
};
const ssoNP3Apigee = {
  getStreetAddrLocus2: 'https://oa-stg.verizon.com/locus-address-api/address/typeahead-address?streetterm=',
  getStreetAddrLocus: 'https://oa-qa.ebiz.verizon.com/locus-address-api/address/typeahead-address?streetterm=',
  // getStreetAddrLocus: 'https://api-stg.verizon.com/locus-typeahead/address/typeahead-address?streetterm=',
  // "getStreetAddrLocus": "https://oa-uat.ebiz.verizon.com/locus-address-api/address/typeahead-address?streetterm=",
  locusapikey: 'MvaCGJaWUOM1zkdFJietJiYyq15SJGdF', // 'YT2N6GkuOjvISXmvQUeSW5XA1MA990VF',// "MJlq7xMNa3mzS6jcMUb3YuhPxeSLQcWl",
  getPartialStreetAddr: 'https://atomserviceseastsit.ebiz.verizon.com/v05g/sit/addresslookup/addresses/street?streetterm=',
  getStreetAddrNTAS: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/street?streetterm=',
  getLocusUnits: 'https://oa-qa.ebiz.verizon.com/locus-typeahead/address/typeahead-loc-unit',
  getNTASUnits: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/units',
  getStreetWithLocusID: 'https://oa-dev2.ebiz.verizon.com/locus-address-api/address/by-location-ids',
  getStreetWithGeo: 'https://oa-dev2.ebiz.verizon.com/locus-address-api/address/by-lat-long',
  locusIdApiKey: 'yfAZa5Aw7HP6RZz9VPoGHMfEG9gDZUgx',
  generateToken: 'https://sso-np3.ebiz.verizon.com/inhome/generatetoken',
  setBrowserInfo: 'https://sso-np3.ebiz.verizon.com/inhome/setbrowserinfo',
  getServerVisitIdAndVisitorId: 'https://sso-np3.ebiz.verizon.com/inhome/getServerVisitIdAndVisitorId',
  generatevisitId: 'https://sso-np3.ebiz.verizon.com/inhome/generatevisitid',
  generatevisitorid: 'https://sso-np3.ebiz.verizon.com/inhome/generatevisitorid',
  generatevisitidforvendor: 'https://sso-np3.ebiz.verizon.com/inhome/generatevisitidforvendor',
  getvendorvisitdetails: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/sessionmanager/getvendorvisitdetails',
  jsNotify: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/common/logger/logmsg',
  fiosQualification: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/addressqualification/address/fiosqualification',
  MyVzSigninInhomeAFNew:
    'https://ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://www.verizon.com&remainInOverlay=N&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter%3Ftarget%3Dhttps%253A%252F%252Fwww.verizon.com%252finhome%252fchange%252fsignin%253fflowtype%253daf',
  signInNew:
    'https://securesit1.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%253a%252f%252fsso-np.ebiz.verizon.com%252fconsumer%252fmyverizon%252frouter',
  signIn:
    'https://sit-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://wwwawssit.ebiz.verizon.com&remainInOverlay=N&mode=i&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3a%2f%2fsit-ssoauth.verizon.com%2fsso%2fProcessLoginServlet%3floginType%3dconsumer%26target%3dhttps%253a%252f%252fsso-np.ebiz.verizon.com%252fconsumer%252fmyverizon%252frouter',
  fiosExistingService: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/existingservice',
  fiosProcessSaveCart: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/smartcart/order/processsmartcart',
  fiosSendSmsOrEmail: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/common/notification/sendsmsoremail',
  fiosSmartCartCancel: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/smartcart/order/smartcartcancel',
  kafkaErrorAppLogs: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/common/kafka/atom_applogs',
  kafkaPageLogs: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/common/kafka/atom_pagelogs',
  getFiosQualification: 'https://wwwawssit.ebiz.verizon.com/foryoursmallbiz/smb/api/Qualify/GetFiosQualification',
  vendorFlow: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/sessionmanager/vendorflowvalidate',
  vendorFlowToken: 'https://sso-np3.ebiz.verizon.com/inhome/validatevendortoken',
  runtimeKey: 'https://wwwnte1aws.ebiz.verizon.com/personal/services/getaccordionmapping.runtimekeys.json',
  validateCouponToken: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/careflowservice/careflow/validatecoupontoken',
  ssoMHAuthiniticate: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/validate',
  verification: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/verification',
  checkPreLink: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/checkprelink?prelink=add',
  websocketIGBackendUrl: 'wss://qa3amkafka-app1.ebiz.verizon.com:6131/ig/acss/echo',
  pollingIGBackendUrl: 'https://securesit2.verizon.com/accessmanager/public/adaptiveauth/2fa/polling',
  jsNotifyAsDynamic: 'https://www.verizon.com/soe/digital/prospect/peripheralsservice/jsNotifyAsDynamic',
  emriosFetchUrl: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/vfw/fetchemeriosinfo',
  signIn_auth: 'https://securesit1.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=',
  fetchFiosProfile: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accountqualificationservice/account/getAddressDetails',
  futureNotification: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/addresslookup/addresses/futurenotification',
  vzwOfferEligibility: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/vzwoffereligibility',
};
const stageApigee = {
  getStreetAddrLocus2: 'https://oa-qa.ebiz.verizon.com/gwyv-locus-es-address-lookup/addresses/street?streetterm=',
  getStreetAddrLocus: 'https://oa-stg.verizon.com/locus-address-api/address/typeahead-address?streetterm=',
  locusapikey: 'YT2N6GkuOjvISXmvQUeSW5XA1MA990VF',
  getPartialStreetAddr: 'https://api-uat.verizon.com/atomapi/v1/addresslookup/addresses/street?streetterm=',
  getStreetAddrNTAS: 'https://api-uat.verizon.com/atomapi/v1/addresslookup/addresses/street?streetterm=',
  getLocusUnits: 'https://oa-stg.verizon.com/locus-address-api/address/typeahead-loc-unit',
  getNTASUnits: 'https://api-uat.verizon.com/atomapi/v1/addresslookup/addresses/units',
  getStreetWithLocusID: 'https://oa-stg.verizon.com/locus-address-api/address/by-location-ids',
  getStreetWithGeo: 'https://api-uat.verizon.com/atomapi/v1/addresslookup/addresses/digital/fetchlocusinfo',
  locusIdApiKey: 'EydXjW5gV2dfAGbjvUhSo61zX9hGJZQA',
  generateToken: 'https://www98.verizon.com/inhome/generatetoken',
  setBrowserInfo: 'https://www98.verizon.com/inhome/setbrowserinfo',
  getServerVisitIdAndVisitorId: 'https://www98.verizon.com/inhome/getServerVisitIdAndVisitorId',
  generatevisitId: 'https://www98.verizon.com/inhome/generatevisitid',
  generatevisitorid: 'https://www98.verizon.com/inhome/generatevisitorid',
  generatevisitidforvendor: 'https://www98.verizon.com/inhome/generatevisitidforvendor',
  getvendorvisitdetails: 'https://api-uat.verizon.com/atomapi/v1/sessionmanager/getvendorvisitdetails',
  jsNotify: 'https://api-uat.verizon.com/atomapi/v1/common/logger/logmsg',
  fiosQualification: 'https://api-uat.verizon.com/atomapi/v1/addressqualification/address/fiosqualification',
  signInNew:
    'https://secure.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww98.verizon.com%2Fconsumer%2Fmyverizon%2Frouter',
  signIn:
    'https://pprd-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://www98.verizon.com&remainInOverlay=N&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww98.verizon.com%2Fconsumer%2Fmyverizon%2Frouter',
  MyVzSigninInhomeAF:
    'https://pprd-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://www98.verizon.com&remainInOverlay=N&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww98.verizon.com%2Fconsumer%2Fmyverizon%2Frouter%3Ftarget%3dhttps%253a%252f%252fwww98.verizon.com%252finhome%252fchange%252fsignin%253fflowtype%253daf',
  MyVzSigninInhomeAFNew:
    'https://pprd-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://www98.verizon.com&remainInOverlay=N&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww98.verizon.com%2Fconsumer%2Fmyverizon%2Frouter%3Ftarget%3dhttps%253a%252f%252fwww98.verizon.com%252finhome%252fchange%252fsignin%253fflowtype%253daf',
  signIn_auth: 'https://securepp.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=',
  fiosExistingService: 'https://api-uat.verizon.com/atomapi/v1/addresslookup/addresses/existingservice',
  fiosProcessSaveCart: 'https://api-uat.verizon.com/atomapi/v1/smartcart/order/processsmartcart',
  fiosSendSmsOrEmail: 'https://api-uat.verizon.com/atomapi/v1/common/notification/sendsmsoremail',
  fiosSmartCartCancel: 'https://api-uat.verizon.com/atomapi/v1/smartcart/order/smartcartcancel',
  kafkaErrorAppLogs: 'https://api-uat.verizon.com/atomapi/v1/common/kafka/atom_applogs',
  kafkaPageLogs: 'https://api-uat.verizon.com/atomapi/v1/common/kafka/atom_pagelogs',
  getFiosQualification: 'https://oa-stg.verizon.com/v06g-gbcom-api/Qualify/GetFiosQualification',
  vendorFlow: 'https://api-uat.verizon.com/atomapi/v1/sessionmanager/vendorflowvalidate',
  vendorFlowToken: 'https://www98.verizon.com/inhome/inhome/validatevendortoken',
  runtimeKey: 'https://www98.verizon.com/personal/services/getaccordionmapping.runtimekeys.json',
  validateCouponToken: 'https://api-uat.verizon.com/atomapi/v1/careflowservice/careflow/validatecoupontoken',
  ssoMHAuthiniticate: 'https://api-uat.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/validate',
  verification: 'https://api-uat.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/verification',
  checkPreLink: 'https://api-uat.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/checkprelink?prelink=add',
  websocketIGBackendUrl: 'wss://qa3amkafka-app1.ebiz.verizon.com:6131/ig/acss/echo',
  pollingIGBackendUrl: 'https://securesit1.verizon.com/accessmanager/public/adaptiveauth/2fa/polling',
  jsNotifyAsDynamic: 'https://www.verizon.com/soe/digital/prospect/peripheralsservice/jsNotifyAsDynamic',
  emriosFetchUrl: 'https://api-uat.verizon.com/atomapi/v1/vfw/fetchemeriosinfo',
  fetchFiosProfile: 'https://api-uat.verizon.com/atomapi/v1/accountqualificationservice/account/getAddressDetails',
  futureNotification: 'https://api-uat.verizon.com/atomapi/v1/addresslookup/addresses/futurenotification',
  vzwOfferEligibility: 'https://api-uat.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/vzwoffereligibility',
};
const preProdApigee = {
  getStreetAddrLocus2: 'https://oa-qa.ebiz.verizon.com/gwyv-locus-es-address-lookup/addresses/street?streetterm=',
  getStreetAddrLocus: 'https://api-stg.verizon.com/locus-typeahead/address/typeahead-address?streetterm=',
  locusapikey: 'YT2N6GkuOjvISXmvQUeSW5XA1MA990VF',
  getPartialStreetAddr: 'https://api-stg.verizon.com/atomapi/v1/addresslookup/addresses/street?streetterm=',
  getStreetAddrNTAS: 'https://api-stg.verizon.com/atomapi/v1/addresslookup/addresses/street?streetterm=',
  getLocusUnits: 'https://api-stg.verizon.com/locus-typeahead/address/typeahead-loc-unit',
  getNTASUnits: 'https://api-stg.verizon.com/atomapi/v1/addresslookup/addresses/units',
  getStreetWithLocusID: 'https://oa-stg.verizon.com/locus-address-api/address/by-location-ids',
  getStreetWithGeo: 'https://api-stg.verizon.com/atomapi/v1/addresslookup/addresses/digital/fetchlocusinfo',
  locusIdApiKey: 'EydXjW5gV2dfAGbjvUhSo61zX9hGJZQA',
  generateToken: 'https://preprod-east-gz.vpc.verizon.com/inhome/generatetoken',
  setBrowserInfo: 'https://preprod-east-gz.vpc.verizon.com/inhome/setbrowserinfo',
  getServerVisitIdAndVisitorId: 'https://preprod-east-gz.vpc.verizon.com/inhome/getServerVisitIdAndVisitorId',
  generatevisitId: 'https://preprod-east-gz.vpc.verizon.com/inhome/generatevisitid',
  generatevisitorid: 'https://preprod-east-gz.vpc.verizon.com/inhome/generatevisitorid',
  generatevisitidforvendor: 'https://preprod-east-gz.vpc.verizon.com/inhome/generatevisitidforvendor',
  getvendorvisitdetails: 'https://api-stg.verizon.com/atomapi/v1/sessionmanager/getvendorvisitdetails',
  jsNotify: 'https://api-stg.verizon.com/atomapi/v1/common/logger/logmsg',
  fiosQualification: 'https://api-stg.verizon.com/atomapi/v1/addressqualification/address/fiosqualification',
  signInNew:
    'https://secure.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww98.verizon.com%2Fconsumer%2Fmyverizon%2Frouter',
  signIn:
    'https://pprd-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://www98.verizon.com&remainInOverlay=N&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww98.verizon.com%2Fconsumer%2Fmyverizon%2Frouter',
  MyVzSigninInhomeAF:
    'https://ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://www.verizon.com&remainInOverlay=N&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter%3Ftarget%3Dhttps%253A%252F%252Fwww.verizon.com%252finhome%252fchange%252fsignin%253fflowtype%253daf',
  MyVzSigninInhomeAFNew:
    'https://secure.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter%3Ftarget%3Dhttps%253A%252F%252Fwww.verizon.com%252finhome%252fchange%252fsignin%253fflowtype%253daf',
  fiosExistingService: 'https://api-stg.verizon.com/atomapi/v1/addresslookup/addresses/existingservice',
  fiosProcessSaveCart: 'https://api-stg.verizon.com/atomapi/v1/smartcart/order/processsmartcart',
  fiosSendSmsOrEmail: 'https://api-stg.verizon.com/atomapi/v1/common/notification/sendsmsoremail',
  fiosSmartCartCancel: 'https://api-stg.verizon.com/atomapi/v1/smartcart/order/smartcartcancel',
  kafkaErrorAppLogs: 'https://api-stg.verizon.com/atomapi/v1/common/kafka/atom_applogs',
  kafkaPageLogs: 'https://api-stg.verizon.com/atomapi/v1/common/kafka/atom_pagelogs',
  getFiosQualification: 'https://oa-stg.verizon.com/v06g-gbcom-api/Qualify/GetFiosQualification',
  vendorFlow: 'https://api-stg.verizon.com/atomapi/v1/sessionmanager/vendorflowvalidate',
  vendorFlowToken: 'https://preprod-east-gz.vpc.verizon.com/inhome/inhome/validatevendortoken',
  runtimeKey: 'https://preprod-east-gz.vpc.verizon.com/inhome/getruntimekeys',
  validateCouponToken: 'https://api-stg.verizon.com/atomapi/v1/careflowservice/careflow/validatecoupontoken',
  ssoMHAuthiniticate: 'https://api-stg.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/validate',
  verification: 'https://api-stg.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/verification',
  checkPreLink: 'https://api-stg.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/checkprelink?prelink=add',
  websocketIGBackendUrl: 'wss://qa3amkafka-app1.ebiz.verizon.com:6131/ig/acss/echo',
  pollingIGBackendUrl: 'https://login.verizonwireless.com/accessmanager/public/adaptiveauth/2fa/polling',
  jsNotifyAsDynamic: 'https://www.verizon.com/soe/digital/prospect/peripheralsservice/jsNotifyAsDynamic',
  emriosFetchUrl: 'https://api-stg.verizon.com/atomapi/v1/vfw/fetchemeriosinfo',
  fetchFiosProfile: 'https://api-stg.verizon.com/atomapi/v1/accountqualificationservice/account/getAddressDetails',
  futureNotification: 'https://api-stg.verizon.com/atomapi/v1/addresslookup/addresses/futurenotification',
};

const prodApigee = {
  // "getStreetAddrLocus2": "https://api.verizon.com/locus-address-api/address/typeahead-address?streetterm=",
  getStreetAddrLocus2: 'https://api.verizon.com/locus-typeahead/address/typeahead-address?streetterm=',
  getStreetAddrLocus: 'https://api.verizon.com/locus-typeahead/address/typeahead-address?streetterm=',
  locusapikey: '5vbCkJw5g5e3LNaY8cgcnPpyQGPrhjIw',
  getPartialStreetAddr: 'https://api.verizon.com/atomapi/v1/addresslookup/addresses/street?streetterm=',
  getStreetAddrNTAS: 'https://api.verizon.com/atomapi/v1/addresslookup/addresses/street?streetterm=',
  getLocusUnits: 'https://api.verizon.com/locus-typeahead/address/typeahead-loc-unit',
  getNTASUnits: 'https://api.verizon.com/atomapi/v1/addresslookup/addresses/units',
  futureNotification: 'https://api.verizon.com/atomapi/v1/addresslookup/addresses/futurenotification',
  getStreetWithLocusID: 'https://oa.verizon.com/locus-address-api/address/by-location-ids',
  getStreetWithGeo: 'https://www.verizon.com/soe/digital/prospect/qualificationservice/5g/fetchLocusInfo',
  locusIdApiKey: 'HPfw3Adud7wRtcjEqYIMy6kLKqPSgKRa',
  generateToken: 'https://www.verizon.com/inhome/generatetoken',
  setBrowserInfo: 'https://www.verizon.com/inhome/setbrowserinfo',
  getServerVisitIdAndVisitorId: 'https://www.verizon.com/inhome/getServerVisitIdAndVisitorId',
  generatevisitId: 'https://www.verizon.com/inhome/generatevisitid',
  generatevisitorid: 'https://www.verizon.com/inhome/generatevisitorid',
  generatevisitidforvendor: 'https://www.verizon.com/inhome/generatevisitidforvendor',
  getvendorvisitdetails: 'https://api.verizon.com/atomapi/v1/sessionmanager/getvendorvisitdetails',
  jsNotify: 'https://api.verizon.com/atomapi/v1/common/logger/logmsg',
  fiosQualification: 'https://api.verizon.com/atomapi/v1/addressqualification/address/fiosqualification',
  signInNew:
    'https://secure.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter',
  signIn:
    'https://ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://www.verizon.com&remainInOverlay=N&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter',
  signIn_WirelessNew:
    'https://secure.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https://www.verizon.com/vfw/auth/shop?expressAddToCart=true',
  MyVzSigninInhomeAFNew:
    'https://secure.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter%3Ftarget%3Dhttps%253A%252F%252Fwww.verizon.com%252finhome%252fchange%252fsignin%253fflowtype%253daf',
  MyVzSigninInhomeAF:
    'https://ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://www.verizon.com&remainInOverlay=N&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter%3Ftarget%3Dhttps%253A%252F%252Fwww.verizon.com%252finhome%252fchange%252fsignin%253fflowtype%253daf',
  signIn_WirelessNSANew:
    'https://secure.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https://www.verizon.com/sales/home/expresscart.html?expresscart=true',
  signIn_WirelessNSAMVNew:
    'https://secure.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https://www.verizon.com/home/internet?type=moving&auth=Y',
  fiosExistingService: 'https://api.verizon.com/atomapi/v1/addresslookup/addresses/existingservice',
  fiosProcessSaveCart: 'https://api.verizon.com/atomapi/v1/smartcart/order/processsmartcart',
  fiosSendSmsOrEmail: 'https://api.verizon.com/atomapi/v1/common/notification/sendsmsoremail',
  fiosSmartCartCancel: 'https://api.verizon.com/atomapi/v1/smartcart/order/smartcartcancel',
  kafkaErrorAppLogs: 'https://api.verizon.com/atomapi/v1/common/kafka/atom_applogs',
  kafkaPageLogs: 'https://api.verizon.com/atomapi/v1/common/kafka/atom_pagelogs',
  getFiosQualification: 'https://www.verizon.com/foryoursmallbiz/smb/api/Qualify/GetFiosQualification',
  vendorFlow: 'https://api.verizon.com/atomapi/v1/sessionmanager/vendorflowvalidate',
  vendorFlowToken: 'https://www.verizon.com/inhome/inhome/validatevendortoken',
  runtimeKey: 'https://www.verizon.com/personal/services/getaccordionmapping.runtimekeys.json',
  validateCouponToken: 'https://api.verizon.com/atomapi/v1/careflowservice/careflow/validatecoupontoken',
  ssoMHAuthiniticate: 'https://api.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/validate',
  verification: 'https://api.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/verification',
  checkPreLink: 'https://api.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/checkprelink?prelink=add',
  websocketIGBackendUrl: 'wss://amservices.vzwcorp.com/ig/acss/echo',
  pollingIGBackendUrl: 'https://login.verizonwireless.com/accessmanager/public/adaptiveauth/2fa/polling',
  jsNotifyAsDynamic: 'https://www.verizon.com/soe/digital/prospect/peripheralsservice/jsNotifyAsDynamic',
  emriosFetchUrl: 'https://api.verizon.com/atomapi/v1/vfw/fetchemeriosinfo',
  signIn_auth: 'https://secure.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=',
  fetchFiosProfile: 'https://api.verizon.com/atomapi/v1/accountqualificationservice/account/getAddressDetails',
  signInConnection:
    'https://secure.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https://www.verizon.com/inhome/checkavailability?PN=',
  vzwOfferEligibility: 'https://api.verizon.com/atomapi/v1/vzwoffereligibilityservice/eligibility/vzwoffereligibility',
};

const nsaProspectApi = {
  cradleRequest: '/soe/digital/prospect/omnidlservice/nse/pegaRequest',
  fetchAuthDetails: '/soe/digital/auth/qualificationservice/vfw/lqInit',
  fetchComboInit: '/soe/digital/prospect/qualificationservice/5g/comboInit',
  fetchEmailComboInit: '/soe/digital/prospect/qualificationservice/nse/comboInit',
  fetchAuthDetailsMV: '/soe/digital/auth/qualificationservice/5g/movers/fetchAccountServiceDetails',
  fetchAuthDetailsBulk: '/soe/digital/auth/qualificationservice/vfw/checkBulkAddressQualification',
  fiveGQualification: '/soe/digital/prospect/qualificationservice/nse/check5GAvailability',
  fiveGMVQualification: '/soe/digital/auth/qualificationservice/5g/movers/check5GMoveAvailability',
  updateMVCart: '/soe/digital/auth/cartservice/cart/5g/movers/updateCart',
  getNotify: '/soe/digital/prospect/qualificationservice/nse/pushMessage',
  getSMSConsent: '/soe/digital/prospect/consentservice/consent/nse/getSMSConsent',
  addSMSConsent: '/soe/digital/prospect/consentservice/consent/nse/addSMSConsent',
  recaptchaDetails: '/soe/digital/prospect/qualificationservice/nse/5g/CaptchaForAddressQualification',
  throttle: '/soe/digital/prospect/cartservice/cart/getNSAThrottleList',
  checkPDM: '/soe/digital/prospect/personalizationrecommendationsservice/pdm/nse',
  checkNSAPDC: '/soe/digital/prospect/qualificationservice/nse/pdc',
  updateCart: '/soe/digital/prospect/cartservice/cart/nse/5g/updateCart',
  jsNotifyAsDynamic: '/soe/digital/prospect/peripheralsservice/jsNotifyAsDynamic',
  validateEmail: '/soe/digital/prospect/fraudservice/validate/nse/emailValidate',
  verifyEmail: '/soe/digital/prospect/fraudservice/validate/nse/5g/verifyEmail',
  emriosFetchUrl: '/soe/digital/prospect/qualificationservice/vfw/fetchEmeriosInfo',
  getStreetWithGeo: '/soe/digital/prospect/qualificationservice/5g/fetchLocusInfo',
  getStreetWithLocusID: '/soe/digital/prospect/qualificationservice/nse/getByLocationIds',
  vfenrollmentcheck: '/soe/digital/prospect/plansservice/nse/validateVFEnrollment',
};

const nsaAuthApi = {
  cradleRequest: '/soe/digital/auth/omnidlservice/nse/pegaRequest',
  fetchAuthDetails: '/soe/digital/auth/qualificationservice/vfw/lqInit',
  fetchComboInit: '/soe/digital/auth/qualificationservice/5g/comboInit',
  fetchAuthDetailsMV: '/soe/digital/auth/qualificationservice/5g/movers/fetchAccountServiceDetails',
  fetchAuthDetailsBulk: '/soe/digital/auth/qualificationservice/vfw/checkBulkAddressQualification',
  fiveGQualification: '/soe/digital/auth/qualificationservice/vfw/check5GAvailability',
  fiveGMVQualification: '/soe/digital/auth/qualificationservice/5g/movers/check5GMoveAvailability',
  getNotify: '/soe/digital/auth/qualificationservice/vfw/pushMessage',
  getSMSConsent: '/soe/digital/auth/consentservice/consent/getSMSConsent',
  addSMSConsent: '/soe/digital/auth/consentservice/consent/addSMSConsent',
  recaptchaDetails: '/soe/digital/prospect/qualificationservice/nse/5g/CaptchaForAddressQualification',
  checkPDM: '/soe/digital/prospect/personalizationrecommendationsservice/pdm',
  checkNSAPDC: '/soe/digital/prospect/qualificationservice/nse/pdc',
  updateCart: '/soe/digital/auth/cartservice/cart/5g/updateCart',
  updateMVCart: '/soe/digital/auth/cartservice/cart/5g/movers/updateCart',
  jsNotifyAsDynamic: '/soe/digital/auth/peripheralsservice/jsNotifyAsDynamic',
  validateEmail: '/soe/digital/auth/fraudservice/validate/nse/5g/emailValidate',
  verifyEmail: '/soe/digital/auth/fraudservice/validate/5g/verifyEmail',
  emriosFetchUrl: '/soe/digital/auth/qualificationservice/vfw/fetchEmeriosInfo',
  getStreetWithGeo: '/soe/digital/auth/qualificationservice/5g/fetchLocusInfo',
  vfdcarturl: '/soe/digital/auth/cartservice/cart/5g/initiateFeature-cart',
  getStreetWithLocusID: '/soe/digital/auth/qualificationservice/getByLocationIds',
  vfenrollmentcheck: '/soe/digital/auth/plansservice/validateVFEnrollment',
};

const proxyURL = {
  STAGE: [stageApigee, stageApigee],
  PREPROD: [preProdApigee, preProdApigee],
  SIT: [sitApigee_SIT, sitProxy_SIT],
  NTE1: [nte1Apigee, nte1Apigee],
  NTE3: [nte3Apigee, nte3Apigee],
  SSONP3: [ssoNP3Apigee, ssoNP3Apigee],
  LOCAL: [sitApigee_1, sitProxy_1],
  PROD: [prodApigee, prodApigee],
  WIRELESS_SIT_1: [sitApigee_1, sitProxy_1],
  WIRELESS_SIT_2: [sitApigee_1, sitProxy_1],
  WIRELESS_SIT_3: [sitApigee_1, sitProxy_1],
  WIRELESS_SIT_4: [sitApigee_1, sitProxy_1],
  WIRELESS_SIT_7: [sitApigee_1, sitProxy_1],
  WIRELESS_SIT_5: [sitApigee_1, sitProxy_1],
  WIRELESS_SIT_50: [sitApigee_1, sitProxy_1],
  WIRELESS_SIT_6: [sitApigee_1, sitProxy_1],
  WIRELESS_SIT_10: [sitApigee_1, sitProxy_1],
  WIRELESS_NSSIT_5: [sitApigee_1, sitProxy_1],
  WIRELESS_NSSIT_6: [sitApigee_1, sitProxy_1],
  WIRELESS_NSSIT_EBIZ_5: [sitApigee_1, sitProxy_1],
  WIRELESS_ECOM_STAGE: [stageApigee, stageApigee],
};

const ATOM_CONST = {
  'www.verizon.com': 'PROD',
  'espanol.verizon.com': 'PROD',
  'www98.verizon.com': 'STAGE',
  'preprod-east-gz.vpc.verizon.com': 'PREPROD',
  'wwwnte1aws.ebiz.verizon.com': 'NTE1',
  'wwwnte3aws.ebiz.verizon.com': 'NTE3',
  'wwwawssit.ebiz.verizon.com': 'SIT',
  'sso-np3.ebiz.verizon.com': 'SSONP3',
  'localhost:3003': 'LOCAL',
  'localhost:3000': 'PROD',
  'vzwqa2.verizonwireless.com': 'WIRELESS_SIT_2',
  'vzwqa8.verizonwireless.com': 'WIRELESS_SIT_2',
  'vzwqa1.verizonwireless.com': 'WIRELESS_SIT_1',
  'vzwqa3.verizonwireless.com': 'WIRELESS_SIT_3',
  'vzwqa4.verizonwireless.com': 'WIRELESS_SIT_4',
  'vzwqa7.verizonwireless.com': 'WIRELESS_SIT_7',
  'vzwqa5.verizonwireless.com': 'WIRELESS_SIT_5',
  'wwwnssit5.verizon.com': 'WIRELESS_NSSIT_5',
  'wwwnssit6.verizon.com': 'WIRELESS_NSSIT_6',
  'wwwnssit5.ebiz.verizon.com': 'WIRELESS_NSSIT_EBIZ_5',
  'vzwqa30.verizonwireless.com': 'WIRELESS_SIT_30',
  'vzwqa40.verizonwireless.com': 'WIRELESS_SIT_40',
  'vzwqa50.verizonwireless.com': 'WIRELESS_SIT_50',
  'vzwqa65.verizonwireless.com': 'WIRELESS_SIT_65',
  'vzwqa6.verizonwireless.com': 'WIRELESS_SIT_6',
  'vzwqa10.verizonwireless.com': 'WIRELESS_SIT_10',
  'ecommstage.verizon.com': 'WIRELESS_ECOM_STAGE',
  // clearlink domains - temp
  'vznspecials.clear.dev.aws.clearlink.com': 'PROD',
  'vznspecials.clear.staging.aws.clearlink.com': 'PROD',
  'vznspecials.clear.hotfix.aws.clearlink.com': 'PROD',
  'verizonspecials.com': 'PROD',
  'verizonb2b.clear.dev.aws.clearlink.com': 'PROD',
  'verizonb2b.clear.staging.aws.clearlink.com': 'PROD',
  'verizonb2b.clear.hotfix.aws.clearlink.com': 'PROD',
  'go.verizon.com': 'PROD',
};

const envir = () => ATOM_CONST[window.location.host];

export const getHereKey = () => {
  let key = 'ly9pWHjq6vF0cYs22NykDei60ZezcPnmEMPrcnLLOVs';
  const envi = envir();
  switch (envi) {
    case 'PROD':
      key = 'EX_HK9rXSGal4YG42PfvOYdAcCkqz_rnPFM1T9QyDXg';
      break;
    default:
      key = 'ly9pWHjq6vF0cYs22NykDei60ZezcPnmEMPrcnLLOVs';
  }
  return key;
};

export const isProd = () => {
  const envi = envir();
  switch (envi) {
    case 'PROD':
      return true;
    default:
      return false;
  }
};

export const prodEspanolDomain = () => {
  if (window.location.origin === 'https://espanol.verizon.com') {
    return true;
  }
  return false;
};
export const getDomainUrl = () => {
  let envDomain = '';
  const envi = envir();
  switch (envi) {
    case 'PROD':
      envDomain = 'https://www.verizon.com';
      break;
    case 'STAGE':
      envDomain = 'https://www98.verizon.com';
      break;
    case 'PREPROD':
      envDomain = 'https://www98.verizon.com';
      break;
    case 'SIT':
      envDomain = 'https://www98.verizon.com';
      break;
    case 'NTE1':
      envDomain = 'https://www98.verizon.com';
      break;
    case 'NTE3':
      envDomain = 'https://www98.verizon.com';
      break;
    case 'SSONP3':
      envDomain = 'https://www98.verizon.com';
      break;
    case 'LOCAL':
      envDomain = 'http://localhost:3003';
      break;
    case 'WIRELESS_SIT_2':
      envDomain = 'https://vzwqa2.verizonwireless.com';
      break;
    case 'WIRELESS_SIT_1':
      envDomain = 'https://vzwqa1.verizonwireless.com';
      break;
    case 'WIRELESS_SIT_3':
      envDomain = 'https://vzwqa3.verizonwireless.com';
      break;
    case 'WIRELESS_SIT_4':
      envDomain = 'https://vzwqa4.verizonwireless.com';
      break;
    case 'WIRELESS_SIT_7':
      envDomain = 'https://vzwqa7.verizonwireless.com';
      break;
    case 'WIRELESS_SIT_5':
      envDomain = 'https://vzwqa5.verizonwireless.com';
      break;
    case 'WIRELESS_SIT_30':
      envDomain = 'https://vzwqa30.verizonwireless.com';
      break;
    case 'WIRELESS_SIT_40':
      envDomain = 'https://vzwqa40.verizonwireless.com';
      break;
    case 'WIRELESS_SIT_50':
      envDomain = 'https://vzwqa50.verizonwireless.com';
      break;
    case 'WIRELESS_SIT_65':
      envDomain = 'https://vzwqa65.verizonwireless.com';
      break;
    case 'WIRELESS_SIT_6':
      envDomain = 'https://vzwqa6.verizonwireless.com';
      break;
    case 'WIRELESS_SIT_10':
      envDomain = 'https://vzwqa10.verizonwireless.com';
      break;
    case 'WIRELESS_NSSIT_5':
      envDomain = 'https://wwwnssit5.verizon.com';
      break;
    case 'WIRELESS_NSSIT_6':
      envDomain = 'https://wwwnssit6.verizon.com';
      break;
    case 'WIRELESS_NSSIT_EBIZ_5':
      envDomain = 'https://wwwnssit5.ebiz.verizon.com';
      break;
    case 'WIRELESS_ECOM_STAGE':
      envDomain = 'https://ecommstage.verizon.com';
      break;
    default:
      envDomain = 'https://www.verizon.com';
  }
  return envDomain;
};

export const getNSAUrl = (env, flowConfig) => {
  const nsaEnvApi = {};
  let envDomain = '';
  if (env) {
    switch (env) {
      case 'PROD':
        envDomain = 'www.verizon.com';
        break;
      case 'LOCAL':
        envDomain = 'localhost:4001';
        break;
      case 'PREPROD':
        envDomain = 'www98.verizon.com';
        break;
      case 'STAGE':
        envDomain = 'vzwqa3.sdc.vzwcorp.com'; // testing purpose
        break;
      case 'SIT':
        envDomain = 'vzwqa3.sdc.vzwcorp.com'; // testing purpose
        break;
      case 'NTE1':
        envDomain = 'vzwqa3.sdc.vzwcorp.com'; // testing purpose
        break;
      case 'NTE3':
        envDomain = 'vzwqa3.sdc.vzwcorp.com'; // testing purpose
        break;
      case 'SSONP3':
        envDomain = 'vzwqa3.sdc.vzwcorp.com'; // testing purpose
        break;
      case 'WIRELESS_SIT_2':
        envDomain = 'vzwqa2.verizonwireless.com';
        break;
      case 'WIRELESS_SIT_1':
        envDomain = 'vzwqa1.verizonwireless.com';
        break;
      case 'WIRELESS_SIT_3':
        envDomain = 'vzwqa3.verizonwireless.com';
        break;
      case 'WIRELESS_SIT_4':
        envDomain = 'vzwqa4.verizonwireless.com';
        break;
      case 'WIRELESS_SIT_7':
        envDomain = 'vzwqa7.verizonwireless.com';
        break;
      case 'WIRELESS_SIT_5':
        envDomain = 'vzwqa5.verizonwireless.com';
        break;
      case 'WIRELESS_SIT_30':
        envDomain = 'vzwqa30.verizonwireless.com';
        break;
      case 'WIRELESS_SIT_40':
        envDomain = 'vzwqa40.verizonwireless.com';
        break;
      case 'WIRELESS_SIT_50':
        envDomain = 'vzwqa50.verizonwireless.com';
        break;
      case 'WIRELESS_SIT_65':
        envDomain = 'vzwqa65.verizonwireless.com';
        break;
      case 'WIRELESS_SIT_6':
        envDomain = 'vzwqa6.verizonwireless.com';
        break;
      case 'WIRELESS_SIT_10':
        envDomain = 'vzwqa10.verizonwireless.com';
        break;
      case 'WIRELESS_NSSIT_5':
        envDomain = 'wwwnssit5.verizon.com';
        break;
      case 'WIRELESS_NSSIT_6':
        envDomain = 'wwwnssit6.verizon.com';
        break;
      case 'WIRELESS_NSSIT_EBIZ_5':
        envDomain = 'wwwnssit5.ebiz.verizon.com';
        break;
      case 'WIRELESS_ECOM_STAGE':
        envDomain = 'ecommstage.verizon.com';
        break;
      default:
        envDomain = 'www.verizon.com';
    }
    if (flowConfig && flowConfig.isLoggedIn) {
      Object.keys(nsaAuthApi).forEach((key) => (nsaEnvApi[key] = `http${env === 'LOCAL' ? '' : 's'}://${envDomain}${nsaAuthApi[key]}`));
    } else {
      Object.keys(nsaProspectApi).forEach((key) => (nsaEnvApi[key] = `http${env === 'LOCAL' ? '' : 's'}://${envDomain}${nsaProspectApi[key]}`));
    }
  }
  return nsaEnvApi;
};

const getEnvUrlObj = (flowConfig) => {
  const currentEnv = envir();
  const isProxyURL = currentEnv === 'LOCAL';
  let envConfigURL = null;
  const urlEnv = proxyURL[currentEnv] || proxyURL.LOCAL;
  envConfigURL = urlEnv[+isProxyURL];
  if (envConfigURL && envConfigURL.createVisitId && envConfigURL.createVisitId.indexOf('/') === 0) {
    // IE 11 window.location.origin Issue fix
    let { origin } = window.location;
    if (!origin) {
      origin = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
    }
    envConfigURL.createVisitId = origin + envConfigURL.createVisitId;
    envConfigURL.tokenGeneration = origin + envConfigURL.tokenGeneration;
  }
  const envNsaUrl = getNSAUrl(currentEnv, flowConfig);
  return { ...envConfigURL, ...envNsaUrl };
};
let apiURLConfig = null;
export const apiUrl = () => {
  if (!apiURLConfig) apiURLConfig = getEnvUrlObj();
  return apiURLConfig;
};
export const setEnvUrlObj = (flowConfig) => {
  apiURLConfig = getEnvUrlObj(flowConfig);
};
