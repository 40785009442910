import { LQ_VIEWS, CommonLQ, Flowtype } from '../constants/common';
import { setToStorage } from './lqServiceUtil';
import { openViewVzTag, loopQualVzTaglqError, sendDataVzTagErrorMessage } from './unifiedVzTag';
import { isProd } from '../constants/api-urls';
import { logMetrics, logToKibanna } from '../services/logService';
import { getFullAddress, allowTGW } from '../components/common/commonUtils';
import { setBrowserInfo, setVendorCookie } from '../components/order-fios/actions';

export const FIOS_LQ_RESPONSE = 'FIOS_LQ_RESPONSE';
export const FIVG_LQ_RESPONSE = 'FIVEG_LQ_RESPONSE';

export const lqResponseObj = {
  addressNotFound: false,
  fiosQualified: false,
  fiveGQualified: false,
  lteQualified: false,
  hsiQualified: false,
  qualifiedCBand: false,
  installType: 'SELF',
  hashedLocusId: '',
  hashedNtasAddrID: '',
  hashedLocationId: '',
  hoaFlag: 'N',
  isFCP: false,
  launchType: 'FCL',
  mvStopOrder: false,
  isPendingCart: false,
  isExpiredCart: false,
  uberPinEligible: false,
  verifyE911Address: false,
  lqAddress: '',
  notEligible: false,
  suggestedAddress: null,
  bundleDetails: null,
  addrInfo: null,
  fiosMHEligible: false,
  fiosPresaleEligible: false,
  fiosMHEligibleSourceInfo: false,
  fiosMHEligibleType: '',
  fiosExtremeHappy: false,
  fiosPresaleDays: 0,
  noSuggestedAddress: false,
  parsedEligible: false,
  aqCaseId: '',
  eventCorrelationId: '',
  fipsCode: '',
  ltehomeSku: '',
  networkBandwidthType: '',
  streetName: '',
  streetNumber: '',
  streetType: '',
  customerInfo: '',
  processStepOut: '',
  planChangeRequired: false,
  vendorLocationCode: '',
  vendorOutletId: '',
  preOrder5GFlow: false,
  visitor_id: '',
  moveQualified: false,
  oneVzIdCustomer: false,
  invAdr: false,
  addressLine2: '',
  preOrderInServiceDate: false,
  mvInvAdr: false,
  mapData: null,
  existingFWA: false,
  existingMobility: false,
  acpInfo: null,
  existingFios: false,
  skipMultipleAddress: false,
  isNYCZip: 'N',
  tanglewoodQualified: false,
  inValidAddress: false,
  apiFailure: false,
  wifiBackupCbandQualified: false,
  wifiBackupLteQualified: false,
  wifiBackupBundle: null,
};
export const transform = (
  response,
  type,
  cookies,
  loopQualApp,
  addressInfo,
  streetInput,
  invAdr = false,
  bulkRes = false,
  mapData = null,
  addressQualification = false,
) => {
  switch (type) {
    case FIOS_LQ_RESPONSE:
      return transformFIOSResponse(response, cookies, loopQualApp, addressQualification);
    case FIVG_LQ_RESPONSE:
      return transform5GResponse(response, cookies, loopQualApp, addressInfo, streetInput, invAdr, bulkRes, mapData, addressQualification);
    default:
      break;
  }
};
export const transformFIOSResponse = (response, cookies, loopQualApp) => {
  let lqResObj = {};
  const { fiosqualified, fiveG, qualified4GHome } = response.data.qualification;
  const fiveGQualified = fiveG;
  const lteQualified = qualified4GHome;
  const fiosQualified = fiosqualified;
  setToStorage('LQResponse', JSON.stringify(response));
  const cookieResponse = JSON.stringify(response);
  const val = `LQResponse=${cookieResponse};path=/`;
  document.cookie = val;
  let addressLine1 = '';
  const addressLine2 = '';
  let city = '';
  let state = '';
  let zip = '';
  let addressID = '';
  let muni = city;
  let dispstr = addressLine1;
  let ntasAddrID = '';
  let lqAddress = '';

  if (!fiveGQualified && !lteQualified && !fiosQualified) {
    lqResObj.targetView = loopQualApp.currentView === LQ_VIEWS.UN_QUALIFIED_VIEW ? LQ_VIEWS.ADDRESS_QUALIFICATION : LQ_VIEWS.UN_QUALIFIED_VIEW;
    lqResObj.qualified = false;
    return lqResObj;
  }
  const fiosQualification = response?.data?.qualificationDetails?.data;
  const visit_id = response?.data?.qualification?.visitId;
  const visitor_id = response?.data?.qualification?.visitorId;
  if (visit_id && visitor_id) {
    window.localStorage.setItem('visit_id', visit_id);
    window.localStorage.setItem('visitor_id', visitor_id);
    setToStorage('visit_id', visit_id);
    setToStorage('visitor_id', visitor_id);
    setBrowserInfo(visit_id, visitor_id);
    if (cookies) {
      cookies.set('visit_id', visit_id, { path: '/', domain: 'verizon.com' });
    }
    if (!isProd() && cookies) {
      cookies.set('visitor_id', decodeURIComponent(visitor_id), { path: '/', domain: 'verizon.com' });
    }
    setVendorCookie(visit_id, visitor_id);
    const expirationTime = Date.now() + 86400000;
    window.localStorage.setItem('expirationTime', expirationTime);
    setToStorage('expirationTime', expirationTime);
    setToStorage('skip5g', window.location.search.indexOf('skip5g=y') > -1);
  }

  if (fiosQualified) {
    if (response?.data?.postValues?.config?.addressInfo) {
      addressID = response?.data?.postValues.config.addressInfo.addressid
        ? response?.data?.postValues.config.addressInfo.addressid
        : response?.data?.postValues.config.addressInfo.addressId
        ? response?.data?.postValues.config.addressInfo.addressId
        : '';
    }
    if (
      addressID &&
      response.data &&
      response.data.postValues &&
      response.data.postValues.config.addressInfo.addressLine1 &&
      response.data.postValues.config.addressInfo.city &&
      response.data.postValues.config.addressInfo.city !== '0' &&
      response.data.postValues.config.addressInfo.state &&
      response.data.postValues.config.addressInfo.state !== '0' &&
      response.data.postValues.config.addressInfo.zipCode &&
      response.data.postValues.config.addressInfo.zipCode !== '0' &&
      fiosQualified
    ) {
      addressLine1 = response.data.postValues.config.addressInfo.addressLine1 ? response.data.postValues.config.addressInfo.addressLine1 : '';
      city = response.data.postValues.config.addressInfo.city ? response.data.postValues.config.addressInfo.city : '';
      state = response.data.postValues.config.addressInfo.state ? response.data.postValues.config.addressInfo.state : '';
      zip = response.data.postValues.config.addressInfo.zipCode;
      lqAddress = getFullAddress(response.data.postValues.config.addressInfo);
      muni = city;
      dispstr = addressLine1;
      ntasAddrID = addressID;
      getSHA256Val(addressID);
    }
  }
  const addrInfo = {
    addressLine1,
    addressLine2,
    city,
    state,
    lqAddress,
    zip,
    ntasAddrID,
    muni,
    dispstr,
  };
  lqResObj = {
    fiveGQualified,
    lteQualified,
    fiosQualified,
    addrInfo,
    lqAddress,
  };

  // TODO bussiness to be updated
  if (fiosQualified) {
    lqResObj.fiosExtremeHappy = false;
    lqResObj.targetView = LQ_VIEWS.FIOS_QUALIFIED_VIEW;
    lqResObj.fiosQualification = fiosQualification;
    if (
      fiosQualification &&
      fiosQualification.wirelessPlanType &&
      (fiosQualification.wirelessPlanType === 'METERED' || fiosQualification.wirelessPlanType === 'UNLIMITED')
    ) {
      lqResObj.fiosMHEligible = true;
    } else if (
      loopQualApp &&
      !loopQualApp.TWS &&
      !loopQualApp.isFccFlow &&
      !loopQualApp.isACPFlow &&
      loopQualApp.flowType !== Flowtype.MOVERS &&
      fiosQualification &&
      fiosQualification.inService === 'N' &&
      (!fiosQualification.pendingOrder || fiosQualification.pendingOrder === 'N') &&
      (!fiosQualification.smartCartDetails || (fiosQualification.smartCartDetails && !fiosQualification.smartCartDetails.smartCartAvailable))
    ) {
      lqResObj.fiosExtremeHappy = true;
      lqResObj.targetView = LQ_VIEWS.BLANK_LOADER_VIEW;
    }
  } else {
    lqResObj.targetView = LQ_VIEWS.UN_QUALIFIED_VIEW;
  }

  return lqResObj;
};
export const transform5GResponse = (response, cookies, loopQualApp, addressInfo, streetInput, invAdr = false, bulkRes = false, fromMap = null) => {
  let resObj = {};
  const plansvisit = window?.vzwlqwidgetconfig?.sourceInfo === 'plansvisit';
  const isWifiBackupMLP = window?.vzwlqwidgetconfig?.source === 'WIFIBACKUP';
  if (!bulkRes) {
    if (
      response &&
      response.output === null &&
      response.errorMap &&
      response.errorMap['04'] &&
      response.errorMap['04'].indexOf('Captcha Verification Failed') !== -1
    ) {
      return 'CAPTCHA_FAILED';
    }
    if (!response || !response.output) {
      if (response && response.statusMessage) {
        logMetrics('checkNetworkAvailabilityResponse', `${response.statusMessage}|${JSON.stringify(response.errorMap)}`, 'Error');
        if (response.errorMap) {
          loopQualVzTaglqError(JSON.stringify(response.errorMap), 'system failure');
        } else if (response.statusMessage) {
          loopQualVzTaglqError(response.statusMessage, 'system failure');
        }
        if (response && response.statusMessage && response.statusMessage === 'MOVEOUT_ADDRESS_MATCHES_WITH_MOVEIN_ADDRESS') {
          resObj.targetView = LQ_VIEWS.ADDRESS_QUALIFICATION;
          resObj.mvInvAdr = true;
          return resObj;
        }
      }
      if (loopQualApp.lq4) {
        resObj.targetView = LQ_VIEWS.LBO_VIEW;
        resObj.apiFailure = true;
        return resObj;
      }
      return null;
    }
    resObj = response.output;
  } else {
    resObj = response;
  }

  if (response && response.output) {
    setToStorage('LQResponse', JSON.stringify(response));
    const qualificationDetails = getQualificationInfoForWifiBackup(response);
    setToStorage('qualificationResponse', JSON.stringify(qualificationDetails));
    setToStorage('resetWifiExploreToggle', 'Y');
    const cookieResponse = JSON.stringify(response);
    const val = `LQResponse=${cookieResponse};path=/`;
    document.cookie = val;
  } else {
    const res = {};
    res.output = response;
    setToStorage('LQResponse', JSON.stringify(res));
    const qualificationDetails = getQualificationInfoForWifiBackup(res);
    setToStorage('qualificationResponse', JSON.stringify(qualificationDetails));
    setToStorage('resetWifiExploreToggle', 'Y');
    const cookieResponse = JSON.stringify(res);
    const val = `LQResponse=${cookieResponse};path=/`;
    document.cookie = val;
  }
  logMetrics('checkNetworkAvailabilityResponse', `${JSON.stringify(response)}`, 'Info');
  let lqResObj = {};
  const {
    qualified,
    fiosQualified,
    uberPinEligible,
    verifyE911Address,
    installType,
    isExpiredCart,
    isPendingCart,
    qualified4GHome,
    launchType,
    mvStopOrder,
    isFCP,
    buildingDetails,
    fiosResponse,
    addressType,
    uberPinQualificatioIsRequired,
    mucOfferEligible,
    qualifiedCBand,
    cbandSku,
    isRevisitor,
    loginMtn,
    vendorPartnerId,
    fiveGAbandonedPresent,
    lteAbandonedPresent,
    bundleDetails,
    preOrderInServiceDate,
    hashedLocusId,
    hashedNtasAddrID,
    hashedLocationId,
    vendorLocationCode,
    vendorOutletId,
    preOrder5GFlow,
    aqCaseId,
    eventCorrelationId,
    fipsCode,
    ltehomeSku,
    networkBandwidthType,
    streetName,
    streetNumber,
    streetType,
    customerInfo,
    processStepOut,
    planChangeRequired,
    emailAddress,
    phoneNumber,
    moveQualified,
    existingFWA,
    existingMobility,
    oneVzIdCustomer,
    acpInfo,
    existingFios,
    isNYCZip,
    tanglewoodQualified,
    tgwServiceStatus,
    wifiBackupCbandQualified,
    wifiBackupLteQualified,
    wifiBackupBundle,
  } = resObj;
  let addressLine1 = resObj.addressLine1 || '';
  const addressLine2 = resObj.addressLine2 || '';
  let city = resObj.city || '';
  let state = resObj.state || '';
  let zip = resObj.zipCode || '';
  const storeSession = resObj.storeSessionId || '';
  let addressID = '';
  let lqAddress = getFullAddress(resObj);

  let muni = city;
  let dispstr = addressLine1;
  const locusID = resObj.addressID;
  let ntasAddrID = '';
  if (resObj.digitalSessionId) {
    window.sessionStorage.setItem('digitalSessionId', resObj.digitalSessionId);
  }
  if (resObj.subLocationId) {
    getSHA256Val(resObj.subLocationId);
  }
  if (fiosQualified) {
    if (resObj?.fiosResponse?.postValues?.config?.addressInfo) {
      addressID = resObj.fiosResponse.postValues.config.addressInfo.addressid
        ? resObj.fiosResponse.postValues.config.addressInfo.addressid
        : resObj.fiosResponse.postValues.config.addressInfo.addressId
        ? resObj.fiosResponse.postValues.config.addressInfo.addressId
        : '';
    }
    if (!addressID && resObj.addressId) {
      addressID = resObj.addressId;
    }
  }
  if (!qualified && !qualifiedCBand && !qualified4GHome) {
    if (
      addressID &&
      resObj.fiosResponse &&
      resObj.fiosResponse.postValues &&
      resObj.fiosResponse.postValues.config.addressInfo.addressLine1 &&
      resObj.fiosResponse.postValues.config.addressInfo.city &&
      resObj.fiosResponse.postValues.config.addressInfo.city !== '0' &&
      resObj.fiosResponse.postValues.config.addressInfo.state &&
      resObj.fiosResponse.postValues.config.addressInfo.state !== '0' &&
      resObj.fiosResponse.postValues.config.addressInfo.zipCode &&
      resObj.fiosResponse.postValues.config.addressInfo.zipCode !== '0' &&
      fiosQualified
    ) {
      addressLine1 = resObj.fiosResponse.postValues.config.addressInfo.addressLine1
        ? resObj.fiosResponse.postValues.config.addressInfo.addressLine1
        : '';
      city = resObj.fiosResponse.postValues.config.addressInfo.city ? resObj.fiosResponse.postValues.config.addressInfo.city : '';
      state = resObj.fiosResponse.postValues.config.addressInfo.state ? resObj.fiosResponse.postValues.config.addressInfo.state : '';
      zip = resObj.fiosResponse.postValues.config.addressInfo.zipCode;
      lqAddress = getFullAddress(resObj.fiosResponse.postValues.config.addressInfo);
      muni = city;
      dispstr = addressLine1;
      ntasAddrID = addressID;
      getSHA256Val(addressID);
    } else if (
      lqAddress === '' &&
      !addressInfo &&
      streetInput &&
      resObj?.fiosResponse?.postValues?.config?.addressInfo?.addressLine1 &&
      (resObj.fiosResponse.postValues.config.addressInfo.addressid || resObj.fiosResponse.postValues.config.addressInfo.addressId)
    ) {
      lqAddress = streetInput;
      addressID = resObj.fiosResponse.postValues.config.addressInfo.addressid
        ? resObj.fiosResponse.postValues.config.addressInfo.addressid
        : resObj.fiosResponse.postValues.config.addressInfo.addressId
        ? resObj.fiosResponse.postValues.config.addressInfo.addressId
        : '';
      getSHA256Val(addressID);
    }
  }
  const fiveGQualified = qualified;
  const lteQualified = qualified4GHome;
  const hsiQualified = false;
  const fiosQualification = fiosResponse?.qualificationDetails?.data;
  const tgwQualified = tanglewoodQualified;
  const { visitId: visit_id, visitorId: visitor_id } = fiosResponse?.qualification || {};
  const disprawstr = `${`${addressLine1}, ${muni}, ${state}, ${zip}`}`;
  const addrInfo = {
    addressLine1,
    addressLine2,
    city,
    state,
    lqAddress,
    zip,
    ntasAddrID,
    muni,
    dispstr,
    locusID,
    disprawstr,
  };

  if (visit_id && visitor_id) {
    window.localStorage.setItem('visit_id', visit_id);
    window.localStorage.setItem('visitor_id', visitor_id);
    setToStorage('visit_id', visit_id);
    setToStorage('visitor_id', visitor_id);
    setBrowserInfo(visit_id, visitor_id);
    if (cookies) {
      cookies.set('visit_id', visit_id, { path: '/', domain: 'verizon.com' });
    }
    const expirationTime = Date.now() + 86400000;
    window.localStorage.setItem('expirationTime', expirationTime);
    setToStorage('expirationTime', expirationTime);
  }
  if (storeSession) {
    setToStorage('cust_store_pid', storeSession);
  }
  if (existingFWA) {
    setToStorage('existingFwa', 'Y');
    let value = '';
    if (existingMobility) {
      logMetrics('existingFWA mobile_services', 'Y', 'Info');
      setToStorage('mobile_services', 'Y');
      value = 'mobile_services=Y;path=/';
      document.cookie = value;
    } else {
      logMetrics('existingFWA mobile_services', 'N', 'Info');
      setToStorage('mobile_services', 'N');
      value = 'mobile_services=N;path=/';
      document.cookie = value;
    }
  } else {
    logMetrics('No existingFWA', 'Y', 'Info');
  }
  const mobileOnly = window.sessionStorage.getItem('mobilityOnly') ? window.sessionStorage.getItem('mobilityOnly') : '';
  if (!existingFWA && !mobileOnly) {
    let value = '';
    if (existingMobility) {
      logMetrics('mobilityOnly', 'Y', 'Info');
      setToStorage('mobilityOnly', 'Y');
      value = 'mobilityOnly=Y;path=/';
      document.cookie = value;
    } else {
      logMetrics('mobilityOnly', 'N', 'Info');
      setToStorage('mobilityOnly', 'N');
      value = 'mobilityOnly=N;path=/';
      document.cookie = value;
    }
  }

  lqResObj = {
    fiveGQualified,
    lteQualified,
    fiosQualified,
    hsiQualified,
    isPendingCart,
    isExpiredCart,
    isFCP,
    mvStopOrder,
    verifyE911Address,
    uberPinEligible,
    installType,
    hashedLocusId,
    hashedNtasAddrID,
    hashedLocationId,
    launchType,
    buildingDetails,
    lqAddress,
    addressType,
    uberPinQualificatioIsRequired,
    mucOfferEligible,
    qualifiedCBand,
    isRevisitor,
    loginMtn,
    vendorPartnerId,
    fiveGAbandonedPresent,
    lteAbandonedPresent,
    addrInfo,
    bundleDetails,
    preOrderInServiceDate,
    vendorOutletId,
    vendorLocationCode,
    preOrder5GFlow,
    aqCaseId,
    eventCorrelationId,
    fipsCode,
    ltehomeSku,
    networkBandwidthType,
    streetName,
    streetNumber,
    streetType,
    customerInfo,
    processStepOut,
    planChangeRequired,
    cbandSku,
    emailAddress,
    phoneNumber,
    visitor_id,
    visit_id,
    moveQualified,
    oneVzIdCustomer,
    invAdr,
    addressLine2,
    existingFWA,
    existingMobility,
    acpInfo,
    existingFios,
    isNYCZip,
    tgwQualified,
    tgwServiceStatus,
    wifiBackupCbandQualified,
    wifiBackupLteQualified,
    wifiBackupBundle,
  };
  if (fiosQualification && (fiosQualification.isOFSApproaching === 'Y' || fiosQualification.ofsPresaleEligible === 'Y')) {
    lqResObj.fiosPresaleEligible = true;
    if (fiosQualification.ofsECD) {
      const today = new Date();
      const ofsECDDate = new Date(fiosQualification.ofsECD);
      const diffTime = Math.abs(ofsECDDate - today);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      lqResObj.fiosPresaleDays = diffDays;
    }
  }
  const urlSearchParams = new URLSearchParams(window.location.search);
  const suggestedAddress = fiosResponse && fiosResponse.parsedAddress && fiosResponse.parsedAddress.length >= 1 ? fiosResponse.parsedAddress : '';
  lqResObj.parsedEligible = !!(!addressInfo && (streetInput || loopQualApp.isLoggedIn));
  if (
    loopQualApp?.isACPFlow &&
    loopQualApp?.isLoggedIn &&
    (existingFWA || window.sessionStorage.getItem('existingFwa') === 'Y') &&
    window.sessionStorage.getItem('isVfEmail') === 'true' &&
    window.sessionStorage.getItem('isVfExist') === 'false' &&
    window.sessionStorage.getItem('fromAddressQual') !== 'true'
  ) {
    lqResObj.targetView = LQ_VIEWS.VF_QUAL_FWA;
  } else if (
    loopQualApp?.isACPFlow &&
    loopQualApp?.isLoggedIn &&
    (existingFWA || window.sessionStorage.getItem('existingFwa') === 'Y') &&
    loopQualApp?.isVfEc &&
    window.sessionStorage.getItem('isVfExist') === 'false' &&
    window.sessionStorage.getItem('fromAddressQual') !== 'true'
  ) {
    lqResObj.targetView = LQ_VIEWS.VF_QUESTIONAIRE;
  } else if ((fiveGQualified || qualifiedCBand || qualified4GHome) && isPendingCart) {
    lqResObj.targetView = LQ_VIEWS.RESUME_SMART_CART_VIEW;
  } else if (loopQualApp?.isComboEmailflow === true && fiosQualification?.pendingOrder !== 'Y') {
    lqResObj.targetView = LQ_VIEWS.COMBO_EMAIL_SIGNIN_VIEW;
  } else if (fiosQualified) {
    lqResObj.fiosExtremeHappy = false;
    lqResObj.targetView = LQ_VIEWS.FIOS_QUALIFIED_VIEW;
    lqResObj.fiosQualification = fiosQualification;
    if (
      fiosQualification &&
      fiosQualification.wirelessPlanType &&
      (fiosQualification.wirelessPlanType === 'METERED' || fiosQualification.wirelessPlanType === 'UNLIMITED')
    ) {
      lqResObj.fiosMHEligible = true;
      lqResObj.fiosMHEligibleType = fiosQualification.wirelessPlanType;
      if (loopQualApp.sourceInfo && loopQualApp.sourceInfo === 'MVOFeedCard') {
        lqResObj.fiosMHEligibleSourceInfo = true;
      }
    }
    if (
      loopQualApp &&
      !loopQualApp.isLoggedInUserInfo &&
      urlSearchParams.get('comboflow') !== 'fios' &&
      !loopQualApp.TWS &&
      !loopQualApp.isFccFlow &&
      !loopQualApp.isACPFlow &&
      loopQualApp.flowType !== Flowtype.storeLocater &&
      loopQualApp.flowType !== Flowtype.MOVERS &&
      loopQualApp.flowType !== Flowtype.retail &&
      !loopQualApp.isComboMFJT &&
      !loopQualApp.isECComboMFJT &&
      !loopQualApp.newConnection &&
      fiosQualification &&
      fiosQualification.inService === 'N' &&
      (!fiosQualification.pendingOrder || fiosQualification.pendingOrder === 'N') &&
      (!fiosQualification.smartCartDetails || (fiosQualification.smartCartDetails && !fiosQualification.smartCartDetails.smartCartAvailable))
    ) {
      lqResObj.fiosExtremeHappy = true;
      lqResObj.targetView = LQ_VIEWS.BLANK_LOADER_VIEW;
    }
  } else if (tgwQualified && tgwServiceStatus?.toLowerCase().includes('pending') && loopQualApp?.runTimeKey?.allowTGWOrder === 'Y') {
    lqResObj.targetView = LQ_VIEWS.TGW_PENDING_ORDER_VIEW;
  } else if (tgwQualified && tgwServiceStatus?.toLowerCase().includes('effect') && loopQualApp?.runTimeKey?.allowTGWOrder === 'Y') {
    lqResObj.targetView = LQ_VIEWS.LBO_VIEW;
  } else if (
    fiveGQualified ||
    qualifiedCBand ||
    (tgwQualified && allowTGW(tgwServiceStatus, loopQualApp.runTimeKey.allowTGWOrder)) ||
    (isWifiBackupMLP && wifiBackupCbandQualified)
  ) {
    lqResObj.targetView = LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW;
  } else if (qualified4GHome || (isWifiBackupMLP && wifiBackupLteQualified)) {
    lqResObj.targetView = LQ_VIEWS.LTE_HOME_QUALIFIED_VIEW;
  } else {
    if (fromMap === null && suggestedAddress) {
      if (loopQualApp?.lq4 || (loopQualApp?.homeLQ && !loopQualApp.isLoggedIn)) {
        lqResObj.skipMultipleAddress = true;
        if (loopQualApp?.homeLQ) {
          sendDataVzTagErrorMessage('Please select an address from the dropdown', 'lq banner');
        } else if (loopQualApp?.lq4) {
          sendDataVzTagErrorMessage('Please select an address from the dropdown', 'lq sticky bar');
        } else {
          sendDataVzTagErrorMessage('Please select an address from the dropdown', 'lq check avail');
        }
        return lqResObj;
      }

      lqResObj.targetView = LQ_VIEWS.ADDRESS_QUALIFICATION;
      lqResObj.suggestedAddress = suggestedAddress;
      openViewVzTag('lq multiple address');
      return lqResObj;
    }
    if (fromMap === null && lqResObj.parsedEligible && !suggestedAddress && !lqAddress) {
      lqResObj.targetView = LQ_VIEWS.ADDRESS_QUALIFICATION;
      lqResObj.noSuggestedAddress = true;
      openViewVzTag('lq address not found');
      if (response?.output?.returnMessage === 'Address is Invalid') {
        lqResObj.inValidAddress = true;
      }
      return lqResObj;
    }

    lqResObj.targetView = loopQualApp.currentView === LQ_VIEWS.UN_QUALIFIED_VIEW ? LQ_VIEWS.ADDRESS_QUALIFICATION : LQ_VIEWS.UN_QUALIFIED_VIEW;
    if (
      (resObj && resObj.returnMessage === 'Address is Invalid') ||
      (lqResObj.addrInfo &&
        lqResObj.addrInfo.addressLine1 &&
        lqResObj.addrInfo.city === '' &&
        lqResObj.addrInfo.state === '0' &&
        lqResObj.addrInfo.zip === '0')
    ) {
      lqResObj.targetView = LQ_VIEWS.ADDRESS_QUALIFICATION;
      lqResObj.noSuggestedAddress = true;
      lqResObj.inValidAddress = true;
      openViewVzTag('lq address not found');
    }
    lqResObj.qualified = false;
    if (loopQualApp.isLoggedIn && loopQualApp.currentView === '' && lqResObj.targetView !== LQ_VIEWS.ADDRESS_QUALIFICATION) {
      lqResObj.lqAddress = lqAddress;
    }
  }
  if (
    fiveGQualified ||
    qualifiedCBand ||
    fiosQualified ||
    qualified4GHome ||
    (tgwQualified && allowTGW(tgwServiceStatus, loopQualApp.runTimeKey.allowTGWOrder))
  ) {
    setToStorage('LqValid', 'Y');
  }
  if (!fiveGQualified && qualifiedCBand) {
    lqResObj.fiveGQualified = qualifiedCBand; // to maintain Cband as 5gqualified
  }
  if (!fiosQualified) {
    lqResObj.fiosQualified = false;
  }
  try {
    if (visit_id && cookies && cookies.get('_cls_s')) {
      logToKibanna(`GlassBox session _cls_s ${cookies.get('_cls_s')} for visit_id ${visit_id}`, 'log');
    }
  } catch (e) {
    console.log('catch while loggin');
  }
  if (fromMap !== null) {
    lqResObj.mapData = fromMap;
  }
  return lqResObj;
};

const getQualificationInfoForWifiBackup = (res) => {
  const {
    qualified = false,
    fiosQualified = false,
    qualified4GHome = false,
    qualifiedCBand = false,
    bundleDetails = [],
    wifiBackupBundle = [],
    wifiBackupCbandQualified = false,
    wifiBackupLteQualified = false,
    existingFWA = false,
  } = res.output;
  return {
    qualified,
    fiosQualified,
    qualified4GHome,
    qualifiedCBand,
    bundleDetails,
    wifiBackupBundle,
    existingFWA,
    wifiBackupCbandQualified,
    wifiBackupLteQualified,
  };
};

export const getSHA256Val = async (item) => {
  let hashHex = '';
  const locusID = item + CommonLQ.shaKey;
  try {
    const msgUint8 = new TextEncoder().encode(locusID);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    window.sessionStorage.setItem('hashedSubLocationId', hashHex);
    if (window.vzdl?.user) {
      window.vzdl.user.prospect = hashHex;
    }
  } catch (error) {
    console.error('getSHA-256 failed', error);
  }
};
